import { Typography, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MeditationTimerWrapper } from './MeditationTimer.style';
import MeditationCard from './MeditationCard';
import Footer from 'Components/common/Footer';
import MeditationDetailsCard from './MeditationDetailsCard';
import MeditationEditCard from './MedtationEditCard';
import { cloneDeep, isEmpty } from 'lodash';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { useLocation } from 'react-router-dom';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { getWindowDimensions } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/App/Actions';
import { BoxLoader, TitleLoader } from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';

const MeditationTimer = () => {
    const [meditationTimerList, setMeditationTimerList] = useState([]);
    const [selectedMeditationCard, setSelectedMeditationCard] = useState({});
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditingCard, setIsEditingCard] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const containerRef = React.useRef(null);
    const [transitionState, setTransitionState] = useState(false);
    const API = useMemo(() => new Api(), []);
    const [openDeleteConfirmModal, setDeleteConfirmModal] = useState({
        visible: false,
        id: null
    });

    const fetchMeditationTimerList = useCallback(async () => {
        setIsLoading(true);
        // const queryParams = new URLSearchParams(location?.search);
        // const activeMeditationTimer = queryParams && queryParams?.get?.('meditationId');

        let response = await API.get(API_URL.MEDITATION_TIMER);

        if (response) {
            let res = [];

            if (response?.data?.data?.list?.length > 0) {
                res = response?.data?.data?.list?.map((value) => ({
                    ...value,
                    openPopper: false
                }));
            }

            setMeditationTimerList(res);
            // let findActiveTimer = res?.find(
            //     (timer) => timer?.meditationId === parseInt(activeMeditationTimer)
            // );

            // setSelectedMeditationCard(findActiveTimer?.meditationId ? findActiveTimer : res?.[0]);
            setIsLoading(false);
        }
    }, [API]);

    const updateSelectedMeditationCard = (data) => {
        let newData = selectedMeditationCard?.meditationId !== data?.meditationId && data;
        if (!newData) return;
        setSelectedMeditationCard(newData);
        setIsEditingCard(false);
    };

    const updateLayer = useCallback(
        (isPlaying) => {
            setOpen(isPlaying);
            let copyMeditationTimerList = [...meditationTimerList];

            copyMeditationTimerList = copyMeditationTimerList?.map((value) => ({
                ...value,
                openPopper: false
            }));
            setMeditationTimerList(copyMeditationTimerList);
        },
        [meditationTimerList]
    );

    const updateList = (data = {}, shouldRefresh = true) => {
        setSelectedMeditationCard(data || {});
        setIsEditingCard(false);
        shouldRefresh && fetchMeditationTimerList();
    };

    const cloneMeditationTimer = useCallback(
        async (meditationTimerId) => {
            setIsLoading(true);
            let response = await API.post(API_URL.MEDITATION_CLONE, {
                params: { meditationTimerId }
            });
            if (response) {
                dispatch(showToast(response?.data?.message, 'success'));
                fetchMeditationTimerList();
            }
        },
        [API, fetchMeditationTimerList, dispatch]
    );

    const editMeditationTimer = useCallback(
        (meditationId) => {
            let cloneData = meditationTimerList.filter((data) => {
                return data.meditationId === meditationId;
            });
            if (cloneData.length) {
                setSelectedMeditationCard(cloneData[0]);
                setIsEditingCard(true);
            }
        },
        [meditationTimerList]
    );

    const deleteTimerConfirm = (id) =>
        setDeleteConfirmModal({
            visible: true,
            id
        });

    const deleteMeditationTimer = useCallback(
        async (id) => {
            setIsLoading(true);
            let response = await API.delete(API_URL.MEDITATION_TIMER, {
                params: { id }
            });
            if (response) {
                dispatch(showToast(response?.data?.message));
                fetchMeditationTimerList();
            }
            setDeleteConfirmModal({
                visible: false,
                id: null
            });
        },
        [API, fetchMeditationTimerList, dispatch]
    );

    const handlePopper = (meditationIndex) => {
        let copyMeditationTimerList = cloneDeep(meditationTimerList);

        copyMeditationTimerList = copyMeditationTimerList?.map((value, index) => {
            if (meditationIndex === index) {
                value.openPopper = !value.openPopper;
            } else {
                value.openPopper = false;
            }
            return value;
        });
        setMeditationTimerList(copyMeditationTimerList);
    };

    const cardEdit = useCallback((id) => {
        setIsEditingCard(id);
    }, []);

    useEffect(() => {
        if (windowDimensions?.width > BREAKPOINTS_VALUE.TABLET) {
            const queryParams = new URLSearchParams(location?.search);
            const activeMeditationTimer = queryParams && queryParams?.get?.('meditationId');
            let findActiveTimer = meditationTimerList?.find(
                (timer) => timer?.meditationId === parseInt(activeMeditationTimer)
            );
            setSelectedMeditationCard((prev) =>
                findActiveTimer?.meditationId
                    ? findActiveTimer
                    : prev?.meditationId
                    ? prev
                    : meditationTimerList?.[0]
            );
        }
    }, [location?.search, windowDimensions.width, meditationTimerList]);

    useEffect(() => {
        if (!isEmpty(selectedMeditationCard) && transitionState) {
            setTransitionState((prev) => !prev);
        }
    }, [selectedMeditationCard, transitionState]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchMeditationTimerList();
    }, [fetchMeditationTimerList]);

    const needToShowCards =
        (isEmpty(selectedMeditationCard) && windowDimensions?.width <= BREAKPOINTS_VALUE.TABLET) ||
        windowDimensions?.width > BREAKPOINTS_VALUE.TABLET;

    return (
        <>
            <TaoCalligraphyWatermark />
            <MeditationTimerWrapper
                size={windowDimensions}
                isOnlyMeditationCards={isEmpty(selectedMeditationCard)}>
                <Box className="container" ref={containerRef}>
                    {needToShowCards && (
                        <Box className="meditationLeftCards">
                            <Box className="flex f-v-center">
                                <Typography className="title">Meditation Timer</Typography>
                            </Box>
                            <Box className={`meditationList ${open && 'overlay'}`}>
                                <Box className="flex f-wrap " sx={{ gap: '20px' }}>
                                    {/* FOR ADDING ADD NEW TIMER */}
                                    {isLoading &&
                                        [1, 2, 3, 4]?.map((value, index) => (
                                            <Box
                                                key={index}
                                                className="meditation-timer-loader-wrapper">
                                                <BoxLoader
                                                    customStyle={{ borderRadius: '16px 8px' }}
                                                    width={168}
                                                    height={298}
                                                />
                                                <TitleLoader
                                                    width={168}
                                                    height={24}
                                                    fillWidth={140}
                                                    fillHeight={10}
                                                    marginLeft={12}
                                                />
                                            </Box>
                                        ))}
                                    {!isLoading &&
                                        [{ meditationId: 'init1', openPopper: false }]?.map(
                                            (meditation, index) => (
                                                <MeditationCard
                                                    key={index}
                                                    meditation={meditation}
                                                    updateSelectedMeditationCard={
                                                        updateSelectedMeditationCard
                                                    }
                                                    selectedMeditationCard={selectedMeditationCard}
                                                    isHighlightedBackground
                                                    handlePopper={handlePopper}
                                                    index={index}
                                                    openPopper={meditation?.openPopper}
                                                />
                                            )
                                        )}

                                    {!isLoading &&
                                        meditationTimerList?.map((meditation, i) => (
                                            <MeditationCard
                                                key={i}
                                                meditation={meditation}
                                                updateSelectedMeditationCard={
                                                    updateSelectedMeditationCard
                                                }
                                                selectedMeditationCard={selectedMeditationCard}
                                                cloneMeditationTimer={cloneMeditationTimer}
                                                editMeditationTimer={editMeditationTimer}
                                                cardEdit={cardEdit}
                                                deleteMeditationTimer={deleteTimerConfirm}
                                                handlePopper={handlePopper}
                                                index={i}
                                                openPopper={meditation?.openPopper}
                                            />
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {/* Right Side Timer Card */}
                    {isLoading && (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={transitionState}
                                timeout={1000}
                                classNames="fade"
                                addEndListener={(node, done) => {
                                    node.addEventListener('transitionend', done, false);
                                }}>
                                <Collapse
                                    in={true}
                                    orientation="vertical"
                                    classes={{
                                        root: 'collapse-root',
                                        wrapper: 'collapse-wrapper'
                                    }}>
                                    <BoxLoader
                                        customStyle={{ borderRadius: '30px 30px 0 0' }}
                                        isFullWidth
                                        isFullHeight
                                    />
                                </Collapse>
                            </CSSTransition>
                        </SwitchTransition>
                    )}

                    {!isLoading && (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={transitionState}
                                timeout={1000}
                                classNames="fade"
                                addEndListener={(node, done) => {
                                    node.addEventListener('transitionend', done, false);
                                }}>
                                {selectedMeditationCard?.backgroundImage && !isEditingCard ? (
                                    <Collapse
                                        in={true}
                                        orientation="vertical"
                                        classes={{
                                            root: 'collapse-root',
                                            wrapper: 'collapse-wrapper'
                                        }}>
                                        <MeditationDetailsCard
                                            meditation={selectedMeditationCard}
                                            updateLayer={updateLayer}
                                            setSelectedMeditationCard={setSelectedMeditationCard}
                                        />
                                    </Collapse>
                                ) : (
                                    <Collapse
                                        in={true}
                                        orientation="vertical"
                                        classes={{
                                            root: 'collapse-root',
                                            wrapper: 'collapse-wrapper'
                                        }}>
                                        {!isEmpty(selectedMeditationCard) && (
                                            <MeditationEditCard
                                                updateList={updateList}
                                                isEditingCard={isEditingCard}
                                                selectedMeditationCard={selectedMeditationCard}
                                                firstTimer={meditationTimerList[0]}
                                            />
                                        )}
                                    </Collapse>
                                )}
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                </Box>
            </MeditationTimerWrapper>
            {openDeleteConfirmModal?.visible && (
                <DeleteConfirmation
                    title="Are you sure you want to delete this timer?"
                    onClose={() => {
                        setDeleteConfirmModal({
                            visible: false,
                            id: null
                        });
                    }}
                    onConfirm={() => {
                        deleteMeditationTimer(openDeleteConfirmModal?.id);
                    }}
                />
            )}
            <Footer isBottomZero={true} showWhiteBackground={false} topBorderColor="gray" />
        </>
    );
};

export default MeditationTimer;
