// URLS FOR SHOWING LOGIN PAGE HEADER TOP BAR: START
export const URL_LANDING = '/';
export const URL_SIGN_UP = '/signup';
export const URL_LOGIN = '/login';
export const URL_VERIFY_EMAIL = '/verify-email';
export const URL_RESET_PASSWORD = '/reset-password';
export const URL_RESET_PASSWORD_EMAIL_SENT = '/reset-password-email-sent';
export const URL_RESET_NEW_PASSWORD = '/forgot-password';
// URLS FOR SHOWING LOGIN PAGE HEADER TOP BAR: END

// URLS FOR SHOWING QUESTIONNAIRE HEADER TOP BAR: START
export const URL_QUESTIONNAIRE_MAIN = '/questionnaire';
export const URL_QUESTIONNAIRE_MAIN_RESULT = '/questionnaire/result';
// URLS FOR SHOWING QUESTIONNAIRE HEADER TOP BAR: END

// URLS FOR SHOWING NORMAL HEADER TOP BAR: START
export const URL_HOME_PAGE = '/home';
export const URL_NOTIFICATIONS = '/notifications';
export const URL_WELLNESS_CATEGORY = '/category';
export const URL_CALENDAR = '/calendar';
export const URL_FIELD_HEALING = '/field-healing';
export const URL_BODY_ORGANS_DETAILS = '/body-organs-details';
export const URL_FIELD_HEALING_PLAYER = '/field-healing-player';

export const URL_MEDIATION_ROOMS = '/meditation-rooms';
export const URL_MEDITATION_ROOM_DETAILS = '/meditation-room-details';
export const URL_MEDITATION_ROOM_ADD_UPDATE = '/meditation-room-add-update';

export const URL_MEDITATION_ROOM_SESSION = '/meditation-room-session';
export const URL_MEDITATION_ROOM_CREATE_SESSION = '/meditation-room-session/create';
export const URL_MEDITATION_ROOM_EDIT_SESSION = '/meditation-room-session/edit';
export const URL_MEDITATION_ROOM_SESSION_PARTICIPANT_VIEW =
    '/meditation-room-session/participant-view';
export const URL_MEDITATION_ROOM_SESSION_HOST_VIEW = '/meditation-room-session/host-view';
export const URL_MEDITATION_TIMER = '/meditation-timer';
export const URL_PROGRAMS = '/programs';
export const URL_PROGRAMS_SUGGESTIONS = '/programs-suggestions';
export const URL_PROGRAMS_DETAILS = '/program-details';
export const URL_BOOK_A_CONSULTATION = '/book-a-consultation';
export const URL_SEARCH = '/search';

export const URL_CHAT = '/chat';
export const URL_HOW_TO_MEDITATE = '/resources';
export const URL_HOW_TO_MEDITATE_CONTENT = '/resources-content';
export const URL_HOW_TO_MEDITATE_MEDIA_PLAYER = '/resources-media-player';
export const URL_JOURNAL = '/journal';

export const URL_PROFILE = '/profile';
export const URL_DOWNLOAD = '/downloads';
export const URL_FAVORITES = '/favorites';

export const URL_PLAYLIST = '/playlist';
export const URL_PLAY_MEDITATION = '/play-meditation';

// URLS FOR SHOWING NORMAL HEADER TOP BAR: END

// URLS WITHOUT HEADER, FOOTER AND SIDEBAR: START
export const URL_MEDITATION_CONTENT = '/meditation-content';
export const URL_MEDITATION_MEDIA_PLAYER = '/meditation-media-player';
export const URL_MEDITATION_READ = '/meditation-read';
export const URL_CONTENT_SEE_ALL = '/content-see-all';
export const URL_CATEGORY_SEE_ALL = '/category-see-all';
// URLS WITHOUT HEADER, FOOTER AND SIDEBAR: END

export const URL_STATISTICS = '/statistics';
export const URL_WELCOME_PAGE = '/welcome';
export const URL_SHARE_PAGE = '/share';
export const URL_PRIVACY_POLICY = '/privacy-policy';
export const URL_TERMS_CONDITION = '/terms-condition';
export const URL_COMMUNITY_GUIDELINES = '/community-guidelines';
export const URL_POST_ASSESSMENT = '/post-assessment';
export const URL_HISTORY = '/history';
export const URL_PROFILE_VIEW = '/profile-view';
export const URL_SALES_PAGE_ONE = '/experience-more-stories';
export const URL_SALES_PAGE_TWO = '/experience-more-categories';
export const URL_BEGIN_EXPERIENCE = '/begin-experience';
export const URL_EXPERIENCE_MORE_VIDEO = '/experience-more-video';
export const URL_EXPERIENCE_MORE_GUIDED_AUDIO = '/experience-more-guided-audio';
export const URL_EXPERIENCE_MORE_MUSIC = '/experience-more-music';

export const URL_REFERRAL_STORE = '/referral-store';
export const URL_REFERRAL_PRODUCT_DETAILS = '/referral-product-details';
export const URL_ORDER_HISTORY = '/order-history';
export const URL_ORDER_DETAILS = '/order-details';
export const URL_YOUR_CART = '/cart';
export const URL_CHOOSE_PAYMENT_METHOD = '/choose-payment-method';
export const URL_ORDER_SUCCESS = '/order-success';

// SHOW LOGIN PAGE HEADER ONLY
export const SHOW_LOGIN_PAGE_HEADER = [
    '/',
    URL_SIGN_UP,
    URL_LOGIN,
    URL_RESET_PASSWORD,
    URL_RESET_PASSWORD_EMAIL_SENT,
    URL_RESET_NEW_PASSWORD,
    URL_PRIVACY_POLICY,
    URL_TERMS_CONDITION,
    URL_COMMUNITY_GUIDELINES,
    URL_BEGIN_EXPERIENCE,
    URL_SALES_PAGE_ONE,
    URL_SALES_PAGE_TWO,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_EXPERIENCE_MORE_MUSIC
];

// SHOW QUESTIONNAIRE PAGE HEADER ONLY
export const SHOW_QUESTIONNAIRE_PAGE_HEADER = [
    URL_QUESTIONNAIRE_MAIN,
    URL_QUESTIONNAIRE_MAIN_RESULT,
    URL_VERIFY_EMAIL
];

// SHOW NORMAL HEADER ONLY
export const SHOW_NORMAL_PAGE_HEADER = [
    '/',
    URL_HOME_PAGE,
    URL_NOTIFICATIONS,
    URL_WELLNESS_CATEGORY,
    URL_CALENDAR,
    URL_FIELD_HEALING,
    URL_BODY_ORGANS_DETAILS,
    URL_FIELD_HEALING_PLAYER,
    URL_MEDIATION_ROOMS,
    URL_MEDITATION_ROOM_DETAILS,
    URL_MEDITATION_ROOM_SESSION,
    URL_MEDITATION_ROOM_CREATE_SESSION,
    URL_MEDITATION_ROOM_EDIT_SESSION,
    URL_MEDITATION_ROOM_SESSION_PARTICIPANT_VIEW,
    URL_MEDITATION_ROOM_SESSION_HOST_VIEW,
    URL_MEDITATION_TIMER,
    URL_PROGRAMS,
    URL_PROGRAMS_DETAILS,
    URL_BOOK_A_CONSULTATION,
    URL_MEDITATION_ROOM_ADD_UPDATE,
    URL_CHAT,
    URL_HOW_TO_MEDITATE,
    URL_SEARCH,
    URL_PLAYLIST,
    URL_JOURNAL,
    URL_PROFILE,
    URL_PRIVACY_POLICY,
    URL_TERMS_CONDITION,
    URL_COMMUNITY_GUIDELINES,
    URL_FAVORITES,
    URL_STATISTICS,
    URL_HISTORY,
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_POST_ASSESSMENT,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_PLAY_MEDITATION,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_PROFILE_VIEW,
    URL_PROGRAMS_SUGGESTIONS,
    URL_SALES_PAGE_ONE,
    URL_SALES_PAGE_TWO,
    URL_BEGIN_EXPERIENCE,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_EXPERIENCE_MORE_MUSIC,
    URL_REFERRAL_STORE,
    URL_REFERRAL_PRODUCT_DETAILS,
    URL_ORDER_HISTORY,
    URL_ORDER_DETAILS,
    URL_YOUR_CART,
    URL_CHOOSE_PAYMENT_METHOD,
    URL_ORDER_SUCCESS
];

export const HIDE_PAGE_HEADERS = [
    // URL_MEDITATION_CONTENT,
    // URL_MEDITATION_MEDIA_PLAYER,
    // URL_PLAY_MEDITATION,
    // URL_MEDITATION_READ
];

export const API_URL = {
    //PROFILE
    PROFILE_INTEREST: 'user/interests',
    PROFILE_SETTINGS: 'user/settings',
    PROFILE_INFO: 'user/profile',
    PROFILE_PUBLIC: 'user/profile/public',
    PROFILE_PICTURE: 'user/profile/picture',
    REGIONS: 'user/regions',
    COUNTRY: 'user/country',
    USER_GOALS: 'user/goals',
    GOALS_SCREEN_1: 'user/goals/screen1',
    GOALS_SCREEN_2: 'user/goals/screen2',
    LANGUAGES: 'user/languages',
    FEEDBACK: 'user/feedback',
    DELETE_ACCOUNT: 'user/delete-account',
    LEFT_MENU: 'user/left-menu',
    LOGOUT_DEVICES: 'user/logout',
    SPOKEN_LANGUAGES: 'user/spoken-languages',

    //AUTH
    SIGN_UP: 'user/signup',
    LOG_IN: 'user/login',
    VERIFY_EMAIL: 'user/verify-email-token',
    RESEND_OTP: 'user/resend-otp',
    FORGOT_PASSWORD: 'user/forgot-password',
    RESET_PASSWORD: 'user/reset-password',
    CHANGE_PASSWORD: 'user/change-password',
    RESEND_VERIFICATION_LINK: 'user/resend-verification-link',
    USER_SOCIAL_SIGNUP: 'user/social/signup',

    // QUESTIONNAIRE
    QUESTIONNAIRE_LIST: 'user/questionnaire-question',
    SUBMIT_QUESTIONNAIRE: 'user/questionnaire-question-answer',
    QUESTIONNAIRE_RESULT: 'user/questionnaire-result',

    //HEAR ABOUT US

    HEAR_ABOUT_US: 'user/hear-about-us',

    // WELLNESS CATEGORIES
    WELLNESS_CATEGORY: 'user/wellness-category',
    WELLNESS_SUB_CATEGORY: 'user/wellness-category/sub-category',
    WELLNESS_CATEGORY_PROGRAM_LIST: 'user/category-details/programs',
    WELLNESS_CATEGORY_TAGS: 'user/category-details/tags',
    WELLNESS_CATEGORY_CONTENTS: 'user/category-details/contents',

    // MEDITATION TIMER
    MEDITATION_TIMER: 'user/meditation-timer',
    MEDITATION_BG_IMAGES: 'user/meditation-timer/background-image-list',
    MEDITATION_CLONE: 'user/meditation-timer/clone',
    SOUND_LIST: 'user/sound',
    MEDITATION_TIMER_PLAY_DETAILS: 'user/meditation-timer/play-details',
    HOW_TO_MEDITATE: 'user/how-to-meditate',

    // PROGRAMS
    USER_PROGRAM: 'user/program',
    ALL_PROGRAM_LIST: 'user/program/all-program-list',
    IN_PROGRESS_PROGRAM_LIST: 'user/program/in-progress-program-list',
    PROGRAM_CONTENT_DETAILS: 'user/program/content-details',
    PROGRAM_PROGRESS_DETAILS: 'user/program/progress-details',
    PROGRAM_CONTENT_PLAY_TIME: 'user/program/content-play-time',
    PROGRAM_FAVORITE: 'user/program/favourite',
    PROGRAM_HISTORY: 'user/program/history',
    PROGRAM_RATING: 'user/program/rating',
    PROGRAM_LINKED_PROGRAMS: 'user/program/linked-programs',
    // CONTENT
    CONTENT_LIST: 'user/content',
    MOOD_PRE_FEEDBACK: 'user/content/pre-assessment-feedback',
    MOOD_POST_FEEDBACK: 'user/content/post-assessment-feedback',
    CONTENT_LIKE_DISLIKE: 'user/content/like-dislike',
    CONTENT_FAVOURITES: 'user/content/favourites',
    CONTENT_PLAY_TIME: 'user/content/play-time',
    CONTENT_HISTORY: 'user/content/history',

    // HOMEPAGE
    HOMEPAGE_CONTENT: 'user/home-page-content',
    HOMEPAGE_CONTENT_SEE_ALL: 'user/home-page-content/view-all',
    DAILY_WISDOM: 'user/daily-wisdom',

    // DOWNLOADS
    DOWNLOADS: 'user/downloads',

    // FAVORITES
    FAVORITES_CONTENTS: 'user/favorites/contents',
    FAVORITES_PROGRAMS: 'user/favorites/programs',

    // NOTIFICATION
    NOTIFICATION_LIST: 'user/notification/list',
    NOTIFICATION: 'user/notification',

    // GOOGLE API
    GOOGLE_APIs_USER_INFO: 'https://www.googleapis.com/oauth2/v3/userinfo',

    //PLAYLIST
    GET_PLAYLIST: 'user/playlist-list',
    GET_PLAYLIST_CONTENT: 'user/playlist/contents',
    ADD_PLAYLIST: 'user/playlist',
    EDIT_PLAYLIST: 'user/playlist',
    DELETE_PLAYLIST_ITEM: 'user/playlist',

    // CATEGORY
    GET_CATEGORY: 'user/wellness-category',
    CATEGORY_SEE_ALL: 'user/category-details/view-all',
    GET_TAGS: 'user/category-details/tags',
    GET_PLAYLIST_IMAGES: 'user/playlist/content/filter',

    //SEARCH
    SEARCH: 'user/search',
    SEARCH_CONTENT: 'user/search/content',
    SEARCH_PROGRAM: 'user/search/program',

    //Journal
    JOURNAL_LIST: 'user/journal/list',
    JOURNAL_API: 'user/journal',
    JOURNAL_PIN: 'user/journal/pin',
    JOURNAL_SHARE: 'user/journal/share',

    STATISTICS: 'user/stats',

    STATIC_PAGES: 'static-pages',

    REFERRAL_LIST: 'user/referrals',

    // SALES PAGE
    TESTIMONIAL: 'user/sales-page/screen1',
    SALES_PAGE_TWO_DATA: 'user/sales-page/screen2',

    // BEGIN EXPERIENCE
    INITIAL_EXPERIENCE: 'user/content/initial-experience',
    EXPERIENCE_MORE: 'user/content/initial-experience/more',

    // REFERRAL
    REFERRALS: 'user/referrals',
    REFERRAL_HISTORY: 'user/referrals/history',

    REFERRAL_PRODUCT_LIST: 'user/store/product/list',
    REFERRAL_PRODUCT_CATEGORIES_LIST: 'user/product-category/list',

    STORE_PRODUCT: 'user/store/product',
    ADD_PRODUCT_INTO_CART: 'user/store/product/cart',
    CART_LIST: 'user/store/product/cart/list',

    ORDER_LIST: 'user/order/list',
    ORDER: 'user/order',

    MASCOT:'user/mascot '
};

export const DYNAMIC_LINK_GENERATE_ENDPOINT = {
    GENERATE_SHORT_LINK:
        process.env.REACT_APP_DYNAMIC_LINK_GENERATE_ENDPOINT +
        `?key=${process.env.REACT_APP_DYNAMIC_LINK_API_KEY}`
};
