import {
    Button,
    CardContent,
    Container,
    IconButton,
    LinearProgress,
    Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { ProgramsMainWrapper } from './Programs.style';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { Box } from '@mui/system';
import TaoCard from 'Components/common/TaoCard';

import PlaylistButton from 'Assets/images/Common/playlist-icon.svg';

import PlaylistBorders from 'Assets/images/Programs/playlist-border-large.png';
import { ReactComponent as FilledRating } from 'Assets/images/Common/filled-star.svg';
import { ReactComponent as UnFilledRating } from 'Assets/images/Common/outlined-star.svg';
import { ReactComponent as User } from 'Assets/images/Programs/user.svg';
import { Footer } from 'Components/common';

import { useNavigate } from 'react-router-dom';
import { API_URL, URL_HISTORY, URL_PROGRAMS_DETAILS } from 'Helpers/Paths';
import { getWindowDimensions, handleContentRedirection } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import { useCallback } from 'react';
import {
    PROGRAM_TYPE,
    TAO_CARD_TYPE,
    SLIDER_PER_PAGE,
    ACCESS_LEVELS_MODULEs
} from 'Helpers/Constants';
import FavoriteIconAnimation from 'Components/common/FavoriteIconAnimation';
import TaoScrollbar from 'Components/common/Scrollbar';
import TaoSlider from 'Components/common/TaoSlider';
import { SortFilter } from 'Components/common/SortFilter';
import {
    BoxLoader,
    TitleLoader,
    TitleWithProgressBarLoader,
    WellnessCategoryLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import usePermissions from 'Components/common/Permissions';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';

const SORT_BY_API_VALUE = {
    Rating: 'ratings',
    'A-Z': 'A-Z'
};
const SORT_BY_VALUE = {
    RATING: 'Rating',
    A_Z: 'A-Z'
};
const SORT_BY_ORDER = {
    Rating: 'desc',
    'A-Z': 'asc'
};
// const SLIDER_PER_PAGE = 5;

const ProgramsMain = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [categories, setCategories] = useState([]);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.RATING);
    // const [inProgressPagination, setInProgressPagination] = useState({
    //     current_page: 0,
    //     per_page: SLIDER_PER_PAGE,
    //     totalRecords: 0
    // });
    // const [forYouProgramPagination, setForYouProgramPagination] = useState({
    //     current_page: 0,
    //     per_page: SLIDER_PER_PAGE,
    //     totalRecords: 0
    // });
    const [inProgressList, setInProgressList] = useState([]);
    const [forYouProgramList, setForYouProgramList] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [programListLoading, setProgramListLoading] = useState(false);
    const dispatch = useDispatch();

    const [programPagination, setProgramPagination] = useState({
        current_page: 1,
        per_page: SLIDER_PER_PAGE,
        totalRecords: 0,
        search: '',
        sort: {
            field: 'ratings',
            order: 'desc'
        }
    });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [progressAndForYouListLoading, setProgressAndForYouListLoading] = useState(false);
    const API = useMemo(() => new Api(), []);

    const navigate = useNavigate();
    const progressContentSlider = useRef(null);
    const forYouContentSlider = useRef(null);
    const favoritePermission = usePermissions(ACCESS_LEVELS_MODULEs?.ADD_FAVOURITE);
    const historyPermission = usePermissions(ACCESS_LEVELS_MODULEs?.ACCESS_PERSONAL_HISTORY);

    const onFavProgram = async (id, index, type) => {
        const newInprogressList = [...inProgressList];
        const newForYouProgramList = [...forYouProgramList];
        const newPrograms = [...programs];

        if (type === PROGRAM_TYPE.IN_PROGRESS) {
            newInprogressList[index].isFavorite = !newInprogressList[index].isFavorite;

            let forYouHasId = newForYouProgramList.findIndex((item) => item?.id === id);
            if (forYouHasId > -1) {
                newForYouProgramList[forYouHasId].isFavorite =
                    !newForYouProgramList[forYouHasId].isFavorite;
            }

            let newProgramsHasId = newPrograms.findIndex((item) => item?.id === id);

            if (newProgramsHasId > -1) {
                newPrograms[newProgramsHasId].isFavorite =
                    !newPrograms[newProgramsHasId].isFavorite;
            }
        }

        if (type === PROGRAM_TYPE.FOR_YOU) {
            newForYouProgramList[index].isFavorite = !newForYouProgramList[index].isFavorite;

            let newInprogressListHasId = newInprogressList.findIndex((item) => item?.id === id);
            if (newInprogressListHasId > -1) {
                newInprogressList[newInprogressListHasId].isFavorite =
                    !newInprogressList[newInprogressListHasId].isFavorite;
            }

            let newProgramsHasId = newPrograms.findIndex((item) => item?.id === id);
            if (newProgramsHasId > -1) {
                newPrograms[newProgramsHasId].isFavorite =
                    !newPrograms[newProgramsHasId].isFavorite;
            }
        }

        if (!type) {
            newPrograms[index].isFavorite = !newPrograms[index].isFavorite;

            let forYouHasId = newForYouProgramList.findIndex((item) => item?.id === id);

            if (forYouHasId > -1) {
                newForYouProgramList[forYouHasId].isFavorite =
                    !newForYouProgramList[forYouHasId].isFavorite;
            }
            let newInprogressListHasId = newInprogressList.findIndex((item) => item?.id === id);

            if (newInprogressListHasId > -1) {
                newInprogressList[newInprogressListHasId].isFavorite =
                    !newInprogressList[newInprogressListHasId].isFavorite;
            }
        }

        setPrograms(newPrograms);
        setForYouProgramList(newForYouProgramList);
        setInProgressList(newInprogressList);

        await API.post(API_URL.PROGRAM_FAVORITE, {
            params: {
                programId: id
            }
        });
    };

    const getPlayList = useCallback(
        async (programType = '', pagination = null, mutateLoading = false) => {
            if (mutateLoading) setProgressAndForYouListLoading(true);

            if (!pagination)
                pagination = {
                    current_page: 0,
                    per_page: SLIDER_PER_PAGE
                };

            let response = await API.post(API_URL.IN_PROGRESS_PROGRAM_LIST, {
                data: {
                    ...pagination,
                    search: ''
                },
                params: {
                    programType
                }
            });
            if (response) {
                const {
                    data: {
                        data: { inProgressProgramsList, forYouProgramList }
                    }
                } = response;

                inProgressProgramsList &&
                    setInProgressList((prev) => prev.concat(inProgressProgramsList?.list) || []);

                forYouProgramList &&
                    setForYouProgramList((prev) => prev.concat(forYouProgramList?.list) || []);

                setProgressAndForYouListLoading(false);
            }
        },
        [API]
    );

    const fetchCategories = useCallback(async () => {
        setCategoryLoading(true);
        let response = await API.get(API_URL.WELLNESS_CATEGORY);
        if (response) {
            setCategories(response?.data?.data?.list);
            response?.data?.data?.list?.length &&
                setSelectedCategory(response?.data?.data?.list[0]);

            setCategoryLoading(false);
        }
    }, [API]);

    const fetchProgramByCategory = useCallback(async () => {
        if (!selectedCategory) return;
        setProgramListLoading(true);
        let response = await API.post(API_URL.ALL_PROGRAM_LIST, {
            params: { categoryId: selectedCategory?.categoryId },
            data: {
                search: programPagination?.search,
                current_page: programPagination?.current_page - 1,
                per_page: programPagination?.per_page,
                sort: { ...programPagination?.sort }
            }
        });

        if (response) {
            setPrograms((prev) => {
                let arr =
                    programPagination?.current_page === 1
                        ? response?.data?.data?.list
                        : prev.concat(response?.data?.data?.list);
                return [...new Map(arr.map((item) => [item['id'], item])).values()];
            });
            setProgramPagination((prev) => ({
                ...prev,
                totalRecords: response?.data?.data?.totalRecords
            }));
            setProgramListLoading(false);
        }
    }, [
        API,
        programPagination?.current_page,
        programPagination?.per_page,
        programPagination?.search,
        programPagination?.sort,
        selectedCategory
    ]);

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    useEffect(() => {
        fetchProgramByCategory();
    }, [fetchProgramByCategory]);

    useEffect(() => {
        getPlayList('', null, true);
        fetchCategories();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getPlayList, fetchCategories]);

    return (
        <>
            <ProgramsMainWrapper>
                <Box className="container">
                    <Box className="flex f-v-center" style={{ marginBottom: '20px' }}>
                        <Typography className="title">Programs</Typography>
                    </Box>

                    {(inProgressList?.length || progressAndForYouListLoading) && (
                        <React.Fragment>
                            <Box className="slider-content">
                                <Typography color="primary" className="slider-header">
                                    In Progress
                                </Typography>
                                {progressAndForYouListLoading && (
                                    <>
                                        <TaoSlider
                                            ref={progressContentSlider}
                                            cardHeight={120}
                                            gap={10}>
                                            {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                                <React.Fragment key={index}>
                                                    <BoxLoader
                                                        customStyle={{ borderRadius: '16px 8px' }}
                                                        width={290}
                                                        height={167}
                                                    />
                                                    <Box className="title-loader">
                                                        <TitleWithProgressBarLoader
                                                            width={290}
                                                            height={40}
                                                            fillWidth={290}
                                                            fillHeight={10}
                                                        />
                                                    </Box>
                                                </React.Fragment>
                                            ))}
                                        </TaoSlider>
                                    </>
                                )}
                                {!progressAndForYouListLoading && inProgressList?.length > 0 && (
                                    <TaoSlider
                                        slidesToShow={2}
                                        ref={progressContentSlider}
                                        cardHeight={120}
                                        gap={10}>
                                        {inProgressList?.map((value, index) => (
                                            <Box className="card-content" key={index}>
                                                <Box className="playlist-card">
                                                    <CardContent
                                                        classes={{ root: 'card-content-root' }}>
                                                        <Box
                                                            className="playlist-card-image"
                                                            sx={{
                                                                backgroundImage: `url(${value?.thumbnailImage})`
                                                            }}>
                                                            <IconButton
                                                                className="playlist-button"
                                                                onClick={() =>
                                                                    navigate(URL_PROGRAMS_DETAILS, {
                                                                        state: {
                                                                            programId: value?.id
                                                                        }
                                                                    })
                                                                }>
                                                                <img
                                                                    src={PlaylistButton}
                                                                    alt=""
                                                                    className="playlist-icon"
                                                                />
                                                            </IconButton>
                                                            <Box
                                                                className="playlist-borders"
                                                                sx={{
                                                                    backgroundImage: `url(${PlaylistBorders})`
                                                                }}
                                                            />
                                                            <Box
                                                                className="heart-icon hover"
                                                                onClick={() =>
                                                                    onFavProgram(
                                                                        value?.id,
                                                                        index,
                                                                        PROGRAM_TYPE.IN_PROGRESS
                                                                    )
                                                                }>
                                                                {favoritePermission?.permissionLoaded &&
                                                                    favoritePermission?.canAccess && (
                                                                        <FavoriteIconAnimation
                                                                            height={50}
                                                                            width={55}
                                                                            isFavorite={
                                                                                value?.isFavorite
                                                                            }
                                                                            isWhiteIcon
                                                                        />
                                                                    )}
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Box>

                                                <Typography className="media-text elipsis">
                                                    {value.title}
                                                </Typography>

                                                <Box style={{ width: '90%' }}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={
                                                            (value?.completedDays * 100) /
                                                                value?.programTotalDays || 0
                                                        }
                                                        className="linear-progress"
                                                    />
                                                </Box>
                                            </Box>
                                        ))}
                                    </TaoSlider>
                                )}
                                {!progressAndForYouListLoading && !inProgressList?.length && (
                                    <Typography className="flex f-v-center f-h-center no-content in-progress-no-content">
                                        No In Progress Program Found
                                    </Typography>
                                )}
                            </Box>

                            <Box className="flex full-width f-h-center f-v-center">
                                <Button
                                    className="history flex f-h-center f-v-center hover"
                                    onClick={() => {
                                        if (!historyPermission?.permissionLoaded) return;
                                        if (!historyPermission?.canAccess)
                                            return dispatch(showSubscriptionModal());
                                        navigate(URL_HISTORY, {
                                            state: ''
                                        });
                                    }}>
                                    <Typography className="text">Your program history</Typography>
                                    {historyPermission?.permissionLoaded &&
                                        !historyPermission?.canAccess && (
                                            <SubscriptionDarkLock
                                                height={15}
                                                customStyle={{ margin: '0 0 0 5px' }}
                                            />
                                        )}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}

                    <Typography color="primary" className="slider-header">
                        For You
                    </Typography>

                    {progressAndForYouListLoading && (
                        <>
                            <TaoSlider
                                gap={10}
                                slidesToShow={3}
                                ref={forYouContentSlider}
                                cardHeight={80}>
                                {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                    <React.Fragment key={index}>
                                        <Box className="program-card-loader-wrapper" key={index}>
                                            <Box className="program-card-loader">
                                                <BoxLoader
                                                    customStyle={{
                                                        borderRadius: '16px 8px'
                                                    }}
                                                    width={210}
                                                    height={120}
                                                />
                                            </Box>

                                            <Box className="program-card-title-loader-wrapper">
                                                <TitleLoader
                                                    width={210}
                                                    height={15}
                                                    fillWidth={210}
                                                    fillHeight={15}
                                                />
                                                <TitleLoader
                                                    width={210}
                                                    height={15}
                                                    fillWidth={210}
                                                    fillHeight={15}
                                                />
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                ))}
                            </TaoSlider>
                        </>
                    )}
                    {!progressAndForYouListLoading && forYouProgramList?.length > 0 && (
                        <TaoSlider
                            gap={10}
                            slidesToShow={3}
                            ref={forYouContentSlider}
                            cardHeight={80}>
                            {forYouProgramList.map((data, index) => (
                                <Box className="flex" key={index}>
                                    <TaoCard
                                        backgroundImage={data.thumbnailImage}
                                        type={TAO_CARD_TYPE.PLAYLIST}
                                        isFav={data?.isFavorite}
                                        badge={data?.subscription?.badge}
                                        isAccessible={
                                            data?.subscription
                                                ? data?.subscription?.isAccessible
                                                : true
                                        }
                                        isPaidContent={data?.isPaidContent}
                                        isPurchased={data?.isPurchased}
                                        onFavClick={() =>
                                            onFavProgram(data?.id, index, PROGRAM_TYPE.FOR_YOU)
                                        }
                                        onClick={() => {
                                            handleContentRedirection(data, navigateTo);
                                        }}
                                        favoriteIconVisible={true}
                                    />

                                    <SliderDetails
                                        data={data}
                                        descriptionVisible={false}
                                        titleClass={'slide-title'}
                                    />
                                </Box>
                            ))}
                        </TaoSlider>
                    )}
                    {!progressAndForYouListLoading && forYouProgramList?.length === 0 && (
                        <Typography className="flex f-v-center f-h-center no-content">
                            No For You Program Found
                        </Typography>
                    )}

                    <Box className="browse-container">
                        <Typography className="browse">Browse All</Typography>

                        <Box className="browse-box">
                            <Box className="top-filter-container">
                                <Box className="topic-filter-container">
                                    <Box className="text-container">
                                        <Typography variant="h5" className="filter-text">
                                            Filter by Topic
                                        </Typography>
                                        <Typography component="p" className="select-text">
                                            choose one
                                        </Typography>
                                    </Box>
                                    <Box className="category-list">
                                        <TaoScrollbar keyId="category-list" horizontalScroll>
                                            {categoryLoading &&
                                                [1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                                    <Box
                                                        key={index}
                                                        className="wellness-list-loader-wrapper">
                                                        <Box className="loader-circle flex f-h-center">
                                                            <WellnessCategoryLoader
                                                                width={70}
                                                                height={70}
                                                                fillWidth={70}
                                                                fillHeight={70}
                                                                customStyle={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            />
                                                        </Box>
                                                        <TitleLoader
                                                            width={40}
                                                            height={10}
                                                            fillWidth={40}
                                                            fillHeight={10}
                                                            marginLeft={0}
                                                        />
                                                    </Box>
                                                ))}
                                            {!categoryLoading &&
                                                categories.map((category) => (
                                                    <Box
                                                        className="icon-container"
                                                        onClick={() =>
                                                            setSelectedCategory(category)
                                                        }
                                                        key={category?.categoryId}>
                                                        <Container
                                                            className="category-icon"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    category?.categoryId ===
                                                                    selectedCategory?.categoryId
                                                                        ? category.selectedIcon
                                                                        : category?.icon
                                                                })`
                                                            }}
                                                        />
                                                        <Container
                                                            className="text-container"
                                                            disableGutters>
                                                            <Typography className="category-name">
                                                                {category?.categoryName}
                                                            </Typography>
                                                        </Container>
                                                    </Box>
                                                ))}
                                        </TaoScrollbar>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="topic-heading">
                                <Box>
                                    {!programListLoading && !categoryLoading && (
                                        <Typography className="category-name">
                                            <span className="bold-text">
                                                {programPagination?.totalRecords}
                                            </span>
                                            in
                                            <span className="bold-text">
                                                {selectedCategory?.categoryName}
                                            </span>
                                        </Typography>
                                    )}
                                </Box>

                                {!!programs?.length && (
                                    <SortFilter
                                        sortBy={sortBy}
                                        sortObject={SORT_BY_VALUE}
                                        onClick={(item) => {
                                            if (sortBy !== item) {
                                                setProgramPagination((prev) => ({
                                                    ...prev,
                                                    current_page: 1,
                                                    sort: {
                                                        field: SORT_BY_API_VALUE[item],
                                                        order: SORT_BY_ORDER[item]
                                                    }
                                                }));
                                            }
                                            setSortBy(item);
                                        }}
                                    />
                                )}
                            </Box>

                            <Box className="browse-card-list">
                                {(programListLoading || categoryLoading) &&
                                    [1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                        <React.Fragment key={index}>
                                            <Box className="browse-card">
                                                <Box
                                                    className="program-card-loader-wrapper"
                                                    key={index}>
                                                    <Box className="program-card-loader">
                                                        <BoxLoader
                                                            customStyle={{
                                                                borderRadius: '16px 8px'
                                                            }}
                                                            width={210}
                                                            height={120}
                                                        />
                                                    </Box>

                                                    <Box className="program-card-title-loader-wrapper">
                                                        <TitleLoader
                                                            width={210}
                                                            height={15}
                                                            fillWidth={210}
                                                            fillHeight={15}
                                                        />
                                                        <TitleLoader
                                                            width={210}
                                                            height={15}
                                                            fillWidth={210}
                                                            fillHeight={15}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                {!programListLoading &&
                                    programs.map((data, index) => (
                                        <Box className="browse-card" key={index}>
                                            <TaoCard
                                                backgroundImage={data.thumbnailImage}
                                                type={TAO_CARD_TYPE.PLAYLIST}
                                                isFav={data?.isFavorite}
                                                favoriteIconVisible={true}
                                                onFavClick={() => onFavProgram(data?.id, index)}
                                                badge={data?.subscription?.badge}
                                                isAccessible={
                                                    data?.subscription
                                                        ? data?.subscription?.isAccessible
                                                        : true
                                                }
                                                isPaidContent={data?.isPaidContent}
                                                isPurchased={data?.isPurchased}
                                                onClick={() => {
                                                    handleContentRedirection(data, navigateTo);
                                                }}
                                            />

                                            <SliderDetails
                                                data={data}
                                                descriptionVisible={true}
                                                titleClass={'slide-black-title'}
                                                isBrowse={true}
                                            />
                                        </Box>
                                    ))}
                                {!programs?.length && !programListLoading && !categoryLoading && (
                                    <Typography className="no-programs full-width flex f-v-center f-h-center">
                                        No programs found.
                                    </Typography>
                                )}
                            </Box>
                            {programs?.length !== programPagination?.totalRecords && (
                                <Button
                                    variant="outlined"
                                    className="load-more"
                                    onClick={() =>
                                        setProgramPagination((prev) => ({
                                            ...prev,
                                            current_page: prev.current_page + 1
                                        }))
                                    }>
                                    Load more
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>

                {windowDimensions.width < BREAKPOINTS_VALUE.TABLET}
                <Footer isBottomZero topBorderColor="gray" />
            </ProgramsMainWrapper>
        </>
    );
};

const SliderDetails = (props) => {
    const { data, titleClass, isBrowse } = props;

    return (
        <Box className={isBrowse ? 'slider-record-browse' : 'slider-record'}>
            <Typography className={`${titleClass} elipsis`}>{data.title}</Typography>

            <Box className="flex f-v-center">
                {[1, 2, 3, 4, 5].map((record, index) => (
                    <React.Fragment key={index}>
                        {data.averageRatingCount >= record ? (
                            <FilledRating key={index} />
                        ) : (
                            <UnFilledRating key={index} />
                        )}
                    </React.Fragment>
                ))}
                <span className="rating-count">{data?.ratingCount}</span>
                <Box className="flex f-v-center" style={{ marginLeft: '20px' }}>
                    <User />
                    <span className="user-count">{data?.joinedUserCount}</span>
                </Box>
            </Box>
        </Box>
    );
};

export default ProgramsMain;
