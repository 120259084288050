import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ProgramProgressWrapper } from './Programs.style';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';
import { BREAKPOINTS_VALUE, COLORS } from 'Styles/Constants';
// Emoji SVG
import {
    RatingEmoji6,
    RatingEmoji7,
    RatingEmoji8,
    RatingEmoji9,
    RatingEmoji10
} from '../MeditationContent/ContentPhases/RatingEmoji';
import { getWindowDimensions } from 'Helpers';

const MOOD = [
    { type: 1, icon: RatingEmoji6 },
    { type: 2, icon: RatingEmoji7 },
    { type: 3, icon: RatingEmoji8 },
    { type: 4, icon: RatingEmoji9 },
    { type: 5, icon: RatingEmoji10 }
];
const TABs = {
    ACHIEVEMENT: 'ACHIEVEMENT',
    FEELING: 'FEELING'
};

const MOOD_ICON_WIDTH_AND_HEIGHT = 35;
const MOOD_ICON_WIDTH_AND_HEIGHT_MOBILE = 30;
const MOOD_ICON_GAP = 20;

const MOOD_RING_COLOR_CODE = ['#E3DFD7', '#F1D9A5', '#F8D17B', '#FCC344', '#FFB40C'];
const calculateDistance = (number, moodIconDimension) => {
    let left = moodIconDimension * (number - 1);
    if (left) left += MOOD_ICON_GAP * (number - 1);
    return left;
};

const Achievement = ({ programContent }) => {
    return (
        <React.Fragment>
            <Box className="data-container flex mt-20">
                <Box className="col-first-width flex f-v-center f-h-center">
                    <Typography className="title text-highlighted">Day</Typography>
                </Box>
                <Box className="col-second-width flex f-v-center">
                    <Typography className="title text-highlighted">Completion</Typography>
                </Box>
            </Box>
            {programContent.map((item) => (
                <React.Fragment key={item.dayNo}>
                    <Box className="data-container data flex f-v-center">
                        <Box className="col-first-width flex f-column f-v-center f-h-center">
                            <Typography className="title text-highlighted day flex f-v-center -f-h-center">
                                {item.dayNo}
                            </Typography>
                            <Typography className="date flex f-v-center -f-h-center">
                                {moment(item?.date, 'YYYY-MM-DD').format('MMM DD')}
                            </Typography>
                        </Box>
                        <Box className="col-second-width flex f-v-center" key={item.dayNo}>
                            <CircularProgressbarWithChildren
                                value={item?.userMeditations}
                                maxValue={item?.totalMeditations}
                                className="progress-bar"
                                strokeWidth={10}
                                styles={buildStyles({
                                    background: {
                                        fill: 'conic-gradient(from 0deg at 50% 50%, #FFF6E1 0deg, #FFD26D 258.14deg, #FFF6E1 360deg)'
                                    },
                                    pathColor: COLORS.PRIMARY_LIGHT,
                                    trailColor: COLORS.LIGHT_GREY
                                })}>
                                <Typography className="out-of-count">
                                    {item?.userMeditations}/{item?.totalMeditations}
                                </Typography>
                            </CircularProgressbarWithChildren>
                            <Typography
                                className={`achievement ${
                                    item?.userMeditations === item?.totalMeditations && 'completed'
                                }`}>
                                {item?.achievementText}
                            </Typography>
                        </Box>
                    </Box>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

const FindMoodIconFromRating = ({ number, moodIconDimension }) => {
    if (!number) return;
    const mood = MOOD[number - 1];
    return (
        <mood.icon
            className="mood-icon"
            style={{
                left: `${calculateDistance(number, moodIconDimension)}px`,
                position: 'absolute'
            }}
        />
    );
};

const MoodRing = ({ startNumber, endNumber, moodIconDimension }) => {
    if (!startNumber && !endNumber)
        return (
            <Box
                className="mood-ring in-active flex f-v-center f-h-center"
                style={{
                    left: calculateDistance(1, moodIconDimension) - MOOD_ICON_GAP / 2,
                    width:
                        calculateDistance(5, moodIconDimension) + moodIconDimension + MOOD_ICON_GAP,
                    borderRadius: '32px'
                }}>
                <Typography className="in-active-mood-text">We Missed you!</Typography>
            </Box>
        );

    let startingRingFrom = calculateDistance(startNumber, moodIconDimension);
    let endingRingFrom = calculateDistance(endNumber - startNumber + 1, moodIconDimension);

    return (
        <Box
            className="mood-ring flex f-v-center f-h-center"
            style={{
                left: startingRingFrom - MOOD_ICON_GAP / 2,
                width:
                    startNumber === endNumber
                        ? moodIconDimension + MOOD_ICON_GAP
                        : endingRingFrom + moodIconDimension + MOOD_ICON_GAP,
                background: `linear-gradient(90deg, ${
                    MOOD_RING_COLOR_CODE[startNumber - 1]
                } 0.16%, ${MOOD_RING_COLOR_CODE[endNumber - 1]} 100.26%)`,
                borderRadius: `${startNumber === endNumber ? '50%' : '30px'}`
            }}>
            <Box className="arrow" />
        </Box>
    );
};

const Feeling = ({ programContent, moodIconDimension }) => {
    return (
        <React.Fragment>
            <Box className="data-container flex mt-20">
                <Box className="col-first-width flex f-v-center f-h-center">
                    <Typography className="title text-highlighted">Day</Typography>
                </Box>
                <Box className="col-second-width flex">
                    {MOOD.map((mood) => (
                        <React.Fragment key={mood.type}>
                            <mood.icon className="mood-icon" />
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
            {programContent.map((item) => (
                <React.Fragment key={item.dayNo}>
                    <Box className="data-container data flex f-v-center">
                        <Box className="col-first-width flex f-column f-v-center f-h-center">
                            <Typography className="title text-highlighted day flex f-v-center -f-h-center">
                                {item.dayNo}
                            </Typography>
                            <Typography className="date flex f-v-center f-h-center">
                                {moment(item?.date, 'YYYY-MM-DD').format('MMM DD')}
                            </Typography>
                        </Box>
                        <Box className="col-second-width flex f-v-center">
                            <MoodRing
                                startNumber={item?.ratingNumber1}
                                endNumber={item?.ratingNumber2}
                                moodIconDimension={moodIconDimension}
                            />
                            <FindMoodIconFromRating
                                number={item?.ratingNumber1}
                                moodIconDimension={moodIconDimension}
                            />
                            <FindMoodIconFromRating
                                number={item?.ratingNumber2}
                                moodIconDimension={moodIconDimension}
                            />
                        </Box>
                    </Box>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

const ProgramProgress = (props) => {
    const [activeProgressTab, setActiveProgressTab] = useState(TABs.ACHIEVEMENT);
    const [moodIconDimension, setMoodIconDimension] = useState(MOOD_ICON_WIDTH_AND_HEIGHT);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.MOBILE)
            return setMoodIconDimension(MOOD_ICON_WIDTH_AND_HEIGHT_MOBILE);
        setMoodIconDimension(MOOD_ICON_WIDTH_AND_HEIGHT);
    }, [windowDimensions.width]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Fade in={true}>
            <ProgramProgressWrapper {...props}>
                <Box className="progress-tabs-container flex f-v-center f-h-center">
                    {Object.keys(TABs).map((item) => (
                        <Typography
                            className={`tab-text flex f-v-center f-h-center hover ${
                                item === activeProgressTab && 'active-tab'
                            }`}
                            key={item}
                            onClick={() => setActiveProgressTab(item)}>
                            {item}
                        </Typography>
                    ))}
                </Box>
                {activeProgressTab === TABs.ACHIEVEMENT && (
                    <Achievement programContent={props?.programContent} />
                )}
                {activeProgressTab === TABs.FEELING && (
                    <Feeling
                        programContent={props?.programContent}
                        moodIconDimension={moodIconDimension}
                    />
                )}
            </ProgramProgressWrapper>
        </Fade>
    );
};

export default ProgramProgress;

// import { Fade, Typography } from '@mui/material';
// import { Box } from '@mui/system';
// import React from 'react';
// import { ProgramProgressWrapper } from './Programs.style';
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
// import { numberFormat, programProgressDataFormat } from 'Helpers';

// const ProgramProgress = (props) => {
//     return (
//         <Fade in={true}>
//             <ProgramProgressWrapper {...props}>
//                 <Box className="day-progress" style={{ position: 'relative' }}>
//                     {props.swap && props.totalLength - 1 !== props.index ? (
//                         <Box className="curve-image" />
//                     ) : (
//                         ''
//                     )}
//                     {!props.swap && props.totalLength - 1 !== props.index ? (
//                         <Box className="curve-image-left" />
//                     ) : (
//                         ''
//                     )}

//                     <Box className="progress-wrapper">
//                         <CircularProgressbarWithChildren
//                             value={props?.data?.userMeditations}
//                             maxValue={props?.data?.totalMeditations}
//                             styles={buildStyles({
//                                 background: {
//                                     fill: 'conic-gradient(from 0deg at 50% 50%, #FFF6E1 0deg, #FFD26D 258.14deg, #FFF6E1 360deg)'
//                                 }
//                             })}>
//                             <Box className="progress-inner-content">
//                                 <Box className="number">
//                                     <Typography className="number-font">
//                                         {numberFormat(props?.index + 1)}
//                                     </Typography>
//                                     <Typography className="date-font">
//                                         {programProgressDataFormat(props?.data?.date)}
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                         </CircularProgressbarWithChildren>
//                     </Box>

//                     <Box
//                         className="flex f-column f-h-center f-v-center"
//                         style={{ width: '50%', gap: '30px' }}>
//                         <Box className="flex f-column" style={{ gap: '15px' }}>
//                             <Box className="flex f-h-center">
//                                 <Typography className="days-highlight">
//                                     {props?.data?.userMeditations}
//                                 </Typography>

//                                 <Typography className="total-days">
//                                     /{props?.data?.totalMeditations}
//                                 </Typography>
//                             </Box>

//                             <Box>
//                                 <Typography className="text">Meditations</Typography>
//                             </Box>
//                         </Box>

//                         <Box className="flex f-column" style={{ gap: '15px' }}>
//                             <Box className="flex f-h-center">
//                                 <Typography className="days-highlight">
//                                     {props?.data?.minutes}
//                                 </Typography>
//                             </Box>
//                             <Box>
//                                 <Typography className="text">minutes</Typography>
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Box>
//             </ProgramProgressWrapper>
//         </Fade>
//     );
// };

// export default ProgramProgress;
