import React, { useEffect, useState, useCallback } from 'react';
import {
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    FormHelperText,
    Box,
    Container,
    Divider
} from '@mui/material';
import {
    ResetPasswordWrapper,
    LinearProgressWrapper,
    PasswordStrengthWrapper
} from '../ResetPassword.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    API_URL,
    URL_LOGIN,
    URL_RESET_PASSWORD,
    URL_RESET_PASSWORD_EMAIL_SENT
} from 'Helpers/Paths';

// FORM VALIDATION
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getWindowDimensions } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import CODES from 'Helpers/StatusCodes';
import { showToast } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';

import { ReactComponent as CheckIcon } from 'Assets/images/Common/check.svg';
import { ReactComponent as CrossIcon } from 'Assets/images/Common/cross.svg';
import moment from 'moment/moment';

const ResetNewPassword = () => {
    const API = new Api();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [passwordStrengthDetails, setPasswordStrengthDetails] = useState({
        value: 0,
        text: 'Password Strength',
        background: ''
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Please enter password')
                // .min(
                //     10,
                //     'Password must be at least 10 letters and must also include special characters and numbers'
                // )
                // .max(16, 'Password should not exceed more than 16 characters')
                .matches(
                    /(?=[A-Za-z0-9@#$%^&+!=\\/\]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&<>*~.:+`-])(?=.{10,}).*$/g,
                    'Password must contain one upper case, one lowercase, one special character, one digit and must be of 10 characters.'
                ),

            confirm_password: Yup.string()
                .required('Please enter confirm password')
                .oneOf([Yup.ref('password'), null], 'Password Do Not Match')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const passwordStrength = useCallback(() => {
        if (!formik?.values?.password) {
            setPasswordStrengthDetails({
                value: 0,
                text: 'Password Strength'
            });
            return;
        }

        let count = 0;
        let passwordStrengthResponse = {
            10: {
                value: 10,
                text: 'Weak Password',
                background: '#BC1218'
            },
            20: {
                value: 30,
                text: 'Weak Password',
                background: '#BC1218'
            },
            30: {
                value: 50,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            40: {
                value: 80,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            50: {
                value: 100,
                text: 'Strong Password',
                background: '#83D841'
            }
        };

        if (formik?.values?.password) {
            if (formik?.values?.password?.length >= 10) {
                count += 10;
            }
            // ONE UPPERCASE
            if (new RegExp('(?=.*[A-Z])').test(formik?.values?.password)) {
                count += 10;
            }
            // ONE LOWERCASE
            if (new RegExp('(?=.*[a-z].*[a-z].*[a-z])').test(formik?.values?.password)) {
                count += 10;
            }

            // ONE NUMBER
            if (new RegExp('(?=.*[0-9])').test(formik?.values?.password)) {
                count += 10;
            }

            // ONE SPECIAL CHARACTER
            if (new RegExp('(?=.*[#?!@$%^&<>*~.:+`-])').test(formik?.values?.password)) {
                count += 10;
            }
        }

        if (count) {
            setPasswordStrengthDetails(passwordStrengthResponse[count]);
        }
    }, [formik?.values?.password]);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const verificationToken = queryParams.get('id');

            const response = await API.post(API_URL.RESET_PASSWORD, {
                params: {
                    verificationToken,
                    password: values?.password
                }
            });
            if (response.status === CODES.SUCCESS) {
                dispatch(showToast(response?.data?.message));
            }
            setIsLoading(false);
            navigate(URL_LOGIN);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const isTokenExpired = useCallback(() => {
        const data = new URLSearchParams(location?.search)?.get?.('datetime');
        if (!data) return;
        let dateWithAddedMinutes = moment(data).add(5, 'minutes').format('YYYY-MM-DD HH:mm');
        let currentTime = moment().utc().format('YYYY-MM-DD HH:mm');

        setTokenExpired(
            moment(dateWithAddedMinutes, 'YYYY-MM-DD HH:mm')?.isBefore(
                moment(currentTime, 'YYYY-MM-DD HH:mm')
            )
        );
    }, [location?.search]);

    const resendVerificationLink = async () => {
        const queryParams = new URLSearchParams(location.search);
        const verificationToken = queryParams.get('id');
        let response = await API.post(API_URL.RESEND_VERIFICATION_LINK, {
            returnError: true,
            params: {
                verificationToken
            }
        });

        if (response?.status === CODES.NOT_VALID_DATA) {
            navigate(URL_RESET_PASSWORD);
        }
        if (response?.status === CODES.SUCCESS)
            navigate(URL_RESET_PASSWORD_EMAIL_SENT, {
                state: { email: response?.data?.data?.email }
            });
    };

    useEffect(() => {
        isTokenExpired();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isTokenExpired]);

    useEffect(() => {
        passwordStrength();
    }, [formik?.values?.password, passwordStrength]);

    return (
        <ResetPasswordWrapper screen={windowDimensions} component="new_password">
            <div className="container">
                <div className="reset-password-container">
                    {tokenExpired ? (
                        <Box className="expired-token-container">
                            <Typography className="token-expired-text">
                                Your Verification Link is Expired.
                            </Typography>

                            <Container className="links" disableGutters>
                                <Typography
                                    className="link"
                                    onClick={() => resendVerificationLink()}>
                                    Re-send link
                                </Typography>
                                <Divider orientation="vertical" className="divider" />
                                <Typography
                                    className="link"
                                    onClick={() => navigate(URL_RESET_PASSWORD)}>
                                    Start Over
                                </Typography>
                            </Container>
                        </Box>
                    ) : (
                        <React.Fragment>
                            <div className="margin-bottom-spacing flex f-v-center">
                                <div className="arrow-icon">
                                    <ArrowBackIosIcon
                                        onClick={() => navigate(URL_LOGIN)}
                                        style={{ color: '#848484' }}
                                    />
                                </div>
                                <div>
                                    <Typography className="title2">Reset Password</Typography>
                                </div>
                            </div>

                            <div className="description">Enter New Password</div>

                            <form onSubmit={formik.handleSubmit}>
                                <div className="margin-bottom-spacing">
                                    <OutlinedInput
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'white',
                                            marginTop: '10px'
                                        }}
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder="Password"
                                        onChange={formik.handleChange}
                                        key="password"
                                        name="password"
                                        value={formik.values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setPasswordVisible(
                                                            (prevState) => !prevState
                                                        )
                                                    }
                                                    aria-label="toggle password visibility">
                                                    {!passwordVisible ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {formik.errors.password && (
                                        <FormHelperText error={true}>
                                            {formik.errors.password}
                                        </FormHelperText>
                                    )}
                                </div>
                                <Box className="margin-bottom-spacing">
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressWithLabel value={passwordStrengthDetails} />
                                    </Box>
                                </Box>
                                {formik?.values?.password && !formik.errors?.password && (
                                    <div className="margin-bottom-spacing">
                                        <OutlinedInput
                                            style={{
                                                width: '100%',
                                                backgroundColor: 'white',
                                                marginTop: '10px'
                                            }}
                                            type={confirmPasswordVisible ? 'text' : 'password'}
                                            placeholder="Confirm password"
                                            onChange={(e) => {
                                                formik.setFieldTouched('confirm_password');
                                                formik.handleChange(e);
                                            }}
                                            key="confirm_password"
                                            name="confirm_password"
                                            value={formik.values.confirm_password}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setConfirmPasswordVisible(
                                                                (prevState) => !prevState
                                                            )
                                                        }
                                                        aria-label="toggle password visibility">
                                                        {!confirmPasswordVisible ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />

                                        {formik?.touched?.confirm_password &&
                                            (formik.errors.confirm_password &&
                                            formik.values.confirm_password ? (
                                                <FormHelperText error={true} component={'span'}>
                                                    <Box className="confirm-password-container">
                                                        <CrossIcon />
                                                        <Typography className="text danger">
                                                            {formik.errors.confirm_password}
                                                        </Typography>
                                                    </Box>
                                                </FormHelperText>
                                            ) : !formik.errors.confirm_password &&
                                              formik.values.confirm_password ? (
                                                <FormHelperText error={false} component={'span'}>
                                                    <Box className="confirm-password-container">
                                                        <CheckIcon />
                                                        <Typography className="text success">
                                                            Password Match!
                                                        </Typography>
                                                    </Box>
                                                </FormHelperText>
                                            ) : (
                                                <>
                                                    <Box className="confirm-password-container">
                                                        <CrossIcon />
                                                        <Typography className="text danger">
                                                            Password Do Not Match
                                                        </Typography>
                                                    </Box>
                                                </>
                                            ))}
                                        {!formik?.touched?.confirm_password && (
                                            <>
                                                <Box className="confirm-password-container">
                                                    <Typography className="text">
                                                        Password Must Match
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                    </div>
                                )}

                                <div>
                                    <Button
                                        type="submit"
                                        className="button button-color"
                                        disabled={isLoading}
                                        variant="contained">
                                        RESET PASSWORD
                                    </Button>
                                </div>
                            </form>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="image" />
        </ResetPasswordWrapper>
    );
};

const LinearProgressWithLabel = (props) => {
    const { value } = props;
    return (
        <PasswordStrengthWrapper>
            <Box className="progress-bar-wrapper">
                <LinearProgressWrapper
                    sx={{
                        height: 6,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        border: '1px solid rgba(132, 132, 132, 0.4)'
                    }}
                    classes={{ barColorPrimary: 'bg-color' }}
                    variant="determinate"
                    {...value}
                />
            </Box>
            <Box className="password-strength-text-wrapper">
                <Typography className="password-strength-text">{value?.text}</Typography>
            </Box>
        </PasswordStrengthWrapper>
    );
};

export default ResetNewPassword;
