export const HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const MINUTES = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60
];
export const TOTAL_MONTH_DAYS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31
];

export const ROOM_TYPES = {
    TAO_CALLIGRAPHY_FIELD: 'TAO_CALLIGRAPHY_FIELD',
    FEATURED_ROOM: 'FEATURED_ROOM',
    COMMUNITY_ROOM: 'COMMUNITY_ROOM'
};

export const ROOM_STATUS = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    NOT_APPROVED: 'NOT_APPROVED',
    NEW: 'NEW'
};

export const SOCIAL_LOGIN = {
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    APPLE: 'APPLE'
};

export const SIGN_UP_TYPE = {
    NORMAL: 'NORMAL',
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    APPLE: 'APPLE'
};

export const DEVICE_TYPE = {
    WEB: 'WEB',
    ANDROID: 'ANDROID',
    IOS: 'IOS'
};

export const DATA_MANIPULATION_TYPE = {
    ADD: 'ADD',
    EDIT: 'EDIT'
};

export const MEDIA_TYPE = {
    VIDEO: 'Video',
    TEXT: 'TEXT',
    AUDIO: 'Audio',
    MUSIC: 'Music',
    GUIDED_AUDIO: 'Guided_Meditation_Audio'
};

export const LIKE_DISLIKE_TYPE = {
    LIKE: 'like',
    DISLIKE: 'dislike'
};

export const PROGRAM_TYPE = {
    IN_PROGRESS: 'In Progress',
    FOR_YOU: 'For You',
    NEW_RELEASES: 'New Releases'
};

export const PROGRAM_API_TYPE = {
    IN_PROGRESS: 'In Progress',
    FOR_YOU: 'For You',
    NEW_RELEASES: 'New Release'
};

export const TAO_CARD_TYPE = {
    PLAYLIST: 'PLAYLIST',
    CARD: 'CARD',
    VIDEO: 'VIDEO',
    TEXT: 'TEXT',
    PAID_PROGRAM_PLAYLIST: 'PAID_PROGRAM_PLAYLIST',
    PROGRAM: 'PROGRAM',
    PROGRAM_PLAYLIST: 'PROGRAM_PLAYLIST'
};

export const STATISTICS_RANGE_TYPE = {
    ALL: 'All',
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month'
};

export const TYPES_OF_NOTIFICATIONS = {
    DAILY_WISDOM: 'DAILY_WISDOM',
    MEDITATION: 'MEDITATION',
    PROGRAMS: 'PROGRAMS',
    SUBSCRIPTION: 'SUBSCRIPTION'
};

export const NOTIFICATION_TYPE_FOR_API = {
    ALL: 'All',
    PROGRAMS: 'Programs',
    SUBSCRIPTION: 'Subscription',
    MEDITATION: 'Meditation',
    DAILY_WISDOM: 'DailyWisdom'
};

export const UPDATE_NOTIFICATION_COUNT = {
    DAILY_WISDOM: 'dailyWisdomUnreadCount',
    MEDITATION: 'meditationUnreadCount',
    PROGRAMS: 'programUnreadCount',
    SUBSCRIPTION: 'subscriptionUnreadCount'
};

export const PROFILE_TAB_TYPE = {
    VIEW_PROFILE: 'VIEW_PROFILE',
    ACCOUNT_INFO: 'ACCOUNT_INFO',
    REWARDS: 'REWARDS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    GOALS: 'GOALS',
    INTERESTS: 'INTERESTS',
    SETTINGS: 'SETTINGS',
    COMMUNITY: 'COMMUNITY',
    REFERRALS: 'REFERRALS'
};

export const POLICIES_TYPES = {
    PRIVACY_POLICY: 'privacy-policy',
    TERMS_AND_CONDITION: 'terms-and-conditions',
    COMMUNITY_GUIDELINES: 'community-guidelines'
};

export const PROGRAM_HISTORY_TYPE = {
    COMPLETED: 'completed',
    IN_PROGRESS: 'in progress'
};

export const USERs_MENU_ITEMS = {
    PROFILE: 'Profile',
    FAVORITES: 'Favorites',
    JOURNAL: 'Journal',
    PROGRAMS: 'Programs',
    PLAYLIST: 'Playlists',
    MEDITATION_TIMER: 'Timer',
    HISTORY: 'History',
    STATS: 'Stats',
    SETTINGS: 'Settings',
    LOGOUT: 'Logout'
};

export const REDIRECTION_LINKS_ITEMS = {
    GETTING_STARTED_GUIDED: 'Getting Started Guide',
    INTERACTIVE_TUTORIAL: 'Interactive Tutorial',
    SUPPORT_FAQs: 'Support & FAQs',
    ONENESS_HEART: 'OnenessHeart.com'
};

export const SLIDER_PER_PAGE = 20;

export const PAGE = {
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
    COMMUNITY_GUIDELINES: 'COMMUNITY_GUIDELINES'
};

export const PROGRAM = 'program';
export const CONTENT = 'content';
export const PHASES = {
    BUY: 'BUY',
    LISTEN: 'LISTEN',
    PRE_ASSESSMENT: 'PRE-ASSESSMENT'
};

export const ACCESS_LEVELS_MODULEs = {
    CONTENT_LIBRARY: 'CONTENT_LIBRARY',
    MEDITATION_ROOM: 'MEDITATION_ROOM',
    CHAT: 'CHAT',
    JOURNAL: 'JOURNAL',
    CALENDAR: 'CALENDAR',
    MEDITATION_TIMER: 'MEDITATION_TIMER',
    SEARCH: 'SEARCH',
    NOTIFICATION: 'NOTIFICATION',
    BOOK_CONSULTATION: 'BOOK_CONSULTATION',
    LIGHT_TRANSMISSION: 'LIGHT_TRANSMISSION',
    PROFILE: 'PROFILE',
    VIEW_OFFICIAL_MEDITATION_ROOM: 'VIEW_OFFICIAL_MEDITATION_ROOM',
    VIEW_COMMUNITY_MEDITATION_ROOM: 'VIEW_COMMUNITY_MEDITATION_ROOM',
    CREATE_COMMUNITY_MEDITATION_ROOM: 'CREATE_COMMUNITY_MEDITATION_ROOM',
    MEDITATION_ROOM_REQUIRED_APPROVAL: 'MEDITATION_ROOM_REQUIRED_APPROVAL',
    REPORT_ROOM: 'REPORT_ROOM',
    REPORT_SESSION: 'REPORT_SESSION',
    REPORT_USERS: 'REPORT_USERS',
    SEARCH_USERS: 'SEARCH_USERS',
    USE_DOWNLOAD_FUNCTION: 'USE_DOWNLOAD_FUNCTION',
    EDIT_PROFILE: 'EDIT_PROFILE',
    SEND_APP_FEEDBACK: 'SEND_APP_FEEDBACK',
    USE_REFERRAL_CODE: 'USE_REFERRAL_CODE',
    COLLECT_REFERRAL_REWARD: 'COLLECT_REFERRAL_REWARD',
    ACCESS_PERSONAL_HISTORY: 'ACCESS_PERSONAL_HISTORY',
    ADD_FAVOURITE: 'ADD_FAVOURITE',
    CREATE_MEDITATION_TIMER: 'CREATE_MEDITATION_TIMER',
    SEE_PROGRAMS: 'SEE_PROGRAMS',
    CREATE_PLAYLIST: 'CREATE_PLAYLIST',
    SEE_CHALLENGES: 'SEE_CHALLENGES',
    COLLECT_REWARD_HEARTS: 'COLLECT_REWARD_HEARTS',
    SPEND_REWARD_HEARTS: 'SPEND_REWARD_HEARTS',
    SEE_PERSONAL_STATS: 'SEE_PERSONAL_STATS',
    ACCESS_ADMIN_PANEL: 'ACCESS_ADMIN_PANEL',
    VIEW_ALL_CONTENTS: 'VIEW_ALL_CONTENTS',
    VIEW_ALL_MEDITATION_ROOM: 'VIEW_ALL_MEDITATION_ROOM',
    ACCOUNT_INFO: 'ACCOUNT_INFO',
    GOALS: 'GOALS',
    INTERESTS: 'INTERESTS',
    REFERRALS: 'REFERRALS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SETTINGS: 'SETTINGS'
};

export const PROFILE_ACCOUNT_INFO_MESSAGE = {
    UPDATE_BIRTHDAY: 'Please update your birthday to make it visible in your profile.',
    UPDATE_ABOUT: 'Please update your about me to make it visible in your profile.',
    UPDATE_GENDER: 'Please update your gender to make it visible in your profile.',
    UPDATE_INTEREST: 'Please update your interests to make them visible in your profile.'
};

export const PREVIEW_PROFILE_MESSAGE = {
    SAVE_DATA_TO_OTHER_SEE_YOUR_PROFILE:
        'Please save the data you would like others to see in your profile.',
    ENABLE_OPTIONS_TO_OTHER_SEE_YOUR_PROFILE:
        'Please enable the options you would like others to see in your profile.'
};

export const PROFILE_OPTION = {
    BIRTHDAY: 'BIRTHDAY',
    ABOUT_ME: 'ABOUT_ME',
    GENDER: 'GENDER',
    INTEREST: 'INTEREST'
};

export const PHONE_EXP =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const PASSWORD_EXP =
    /(?=[A-Za-z0-9@#$%^&+!=\\/\]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&<>*~.:+`-])(?=.{10,}).*$/g;

export const PLAYLIST_MESSAGES = {
    NONE_PURCHASED:
        "You haven't purchased single content of playlist, please purchase at least one to play",
    CONTENT_NOT_PURCHASED: "You haven't purchased this content, please purchase it to play"
};

export const PAGE_SHARING = {
    SALES_PAGE_TWO: 'sales-page-two',
    HOME: 'home'
};

export const INIT_SPOT_ANIMATION = 1500;
