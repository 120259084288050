import React, { useEffect, useState, useRef } from 'react';
import { VerifyEmailWrapper } from './VerifyEmail.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Backdrop,
    CircularProgress,
    Container,
    Divider,
    TextField,
    Typography
} from '@mui/material';
import { COLORS } from 'Styles/Constants';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_QUESTIONNAIRE_MAIN, URL_SIGN_UP } from 'Helpers/Paths';
import CODES from 'Helpers/StatusCodes';
import { hideTopBar, showToast, showTopBar } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { DEVICE_TYPE } from 'Helpers/Constants';
import { loginUser } from 'Redux/Auth/Actions';
import { detectBrowser, detectOS } from 'Helpers';

const API = new Api();

const VerifyEmail = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const [isInvalidOtp, setIsInvalidOtp] = useState(false);
    const [code, setCode] = useState([]);
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    const inputRef6 = useRef();
    const REFS = [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6];

    const changeFocus = (e, ref, id) => {
        let newCode = [...code];
        newCode[id] = e.target.value;

        if (!e.target.value) return;

        if (ref === inputRef1) inputRef1.current.focus();
        if (ref === inputRef2) inputRef2.current.focus();
        if (ref === inputRef3) inputRef3.current.focus();
        if (ref === inputRef4) inputRef4.current.focus();
        if (ref === inputRef5) inputRef5.current.focus();
        if (ref === inputRef6) inputRef6.current.focus();
        setCode(newCode);
    };

    // MASK EMAIL
    const hideGmailID = (email) => {
        if (!email) return;
        email = email?.split('');
        let finalArr = [];
        let len = email.indexOf('@');
        email.forEach((item, pos) => {
            pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(email[pos]);
        });

        return finalArr.join('');
    };

    const resendOtp = async () => {
        try {
            setIsLoading(true);
            setCode([]);
            REFS.forEach((item) => (item.current.value = ''));

            const response = await API.post(API_URL?.RESEND_OTP, {
                params: {
                    emailId: location?.state?.email
                }
            });
            if (response.status === CODES.SUCCESS) {
                dispatch(showToast(response?.data?.message));
            }
            inputRef1.current.focus();
            setIsInvalidOtp(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const verifyOtp = useCallback(async () => {
        try {
            setIsLoading(true);
            let newCode = code.reduce((a, b) => {
                return a + b;
            });
            if (newCode.length !== REFS?.length) return setIsLoading(false);

            const response = await API.post(API_URL?.VERIFY_EMAIL, {
                data: {
                    email: location?.state?.email,
                    password: location?.state?.password,
                    deviceType: DEVICE_TYPE.WEB,
                    deviceToken: '',
                    otp: newCode,
                    location: '',
                    browserType: detectBrowser(),
                    osType: detectOS(),
                    deviceModel: '',
                    deviceVersion: ''
                },
                skipToast: true
            });

            if (response.status === CODES.SUCCESS) {
                setIsInvalidOtp(false);
                dispatch(loginUser(response?.data?.data));
                navigate(URL_QUESTIONNAIRE_MAIN);
            }

            setIsLoading(false);
        } catch (error) {
            if (error?.response?.status === CODES.VALIDATION_ERROR) {
                setIsInvalidOtp(true);
            }
            if (error?.response?.status === CODES.UNAUTHORIZED) {
                dispatch(
                    showToast(error.response?.data?.message || 'Internal Server Error', 'error')
                );
            }
            setIsLoading(false);
        }
    }, [code, dispatch, location?.state?.email, location?.state?.password, navigate, REFS?.length]);

    useEffect(() => {
        if (code.length === REFS?.length) {
            verifyOtp();
        }
    }, [code, REFS?.length, verifyOtp]);

    const backButton = (e) => {
        if (e.keyCode === 8) {
            let newCode = [...code];

            newCode.pop();
            setCode(newCode);

            if (!newCode?.length) {
                inputRef1.current.value = '';
                inputRef1.current.focus();
            }
            if (newCode?.length === 1) {
                inputRef2.current.value = '';
                inputRef2.current.focus();
            }
            if (newCode?.length === 2) {
                inputRef3.current.value = '';
                inputRef3.current.focus();
            }
            if (newCode?.length === 3) {
                inputRef4.current.value = '';
                inputRef4.current.focus();
            }
            if (newCode?.length === 4) {
                inputRef5.current.value = '';
                inputRef5.current.focus();
            }
            if (newCode?.length === 5) {
                inputRef6.current.value = '';
                inputRef6.current.focus();
            }
        }
    };

    const onPaste = (value) => {
        const arr = value.split('');

        if (!arr?.length) return;

        inputRef1.current.value = arr[0] || '';
        inputRef2.current.value = arr[1] || '';
        inputRef3.current.value = arr[2] || '';
        inputRef4.current.value = arr[3] || '';
        inputRef5.current.value = arr[4] || '';
        inputRef6.current.value = arr[5] || '';

        if (arr?.length < 6) {
            dispatch(showToast('Please enter valid otp.', 'info'));
            return;
        }
        inputRef6.current.focus();
        setCode(arr);
    };

    useEffect(() => {
        inputRef1.current.focus();
        dispatch(hideTopBar());

        return () => dispatch(showTopBar());
    }, [dispatch]);

    return (
        <VerifyEmailWrapper className="theme">
            <Container className="container" disableGutters>
                <Backdrop open={isLoading} sx={{ color: COLORS.PRIMARY }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container className="content" disableGutters>
                    <Container className="arrow-parent" disableGutters>
                        <ArrowBackIosIcon
                            onClick={() => navigate(-1)}
                            style={{ color: COLORS.MEDIUM_GREY, cursor: 'pointer' }}
                        />
                    </Container>
                    <Container className="data" disableGutters>
                        <Typography className="title">Verify Email</Typography>

                        <Typography className="email">
                            Enter the code sent to {hideGmailID(location?.state?.email)}
                        </Typography>

                        <Container disableGutters className="fields">
                            {REFS.map((item, index) => (
                                <TextField
                                    classes={{
                                        root: `root ${isInvalidOtp && 'error'}`
                                    }}
                                    variant="standard"
                                    key={index}
                                    type="tel"
                                    inputProps={{
                                        maxLength: 1
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: `${isInvalidOtp && 'input-root'}`
                                        }
                                    }}
                                    onPaste={(e) => onPaste(e.clipboardData.getData('Text'))}
                                    inputRef={item}
                                    onKeyUp={backButton}
                                    onChange={(e) => changeFocus(e, REFS[index + 1], index)}
                                />
                            ))}
                        </Container>
                        <Typography className="invalid-otp">
                            {isInvalidOtp && 'Invalid code. Please try again.'}
                        </Typography>

                        <Container className="links" disableGutters>
                            <Typography className="link" onClick={() => resendOtp()}>
                                Re-send code
                            </Typography>
                            <Divider orientation="vertical" className="divider" />
                            <Typography className="link" onClick={() => navigate(URL_SIGN_UP)}>
                                Start Over
                            </Typography>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </VerifyEmailWrapper>
    );
};

export default VerifyEmail;
