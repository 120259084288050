import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { ProfileGoalsSectionWrapper } from './Goals.style';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { TIME_MENU } from 'Components/common/MeditationTarget/MeditationTimerValues';
import TaoChip from 'Components/common/TaoChip/TaoChip';
import TaoScrollbar from 'Components/common/Scrollbar';
import GoalSelection from './GoalSelection';
import { ReactComponent as SelectDropDownIcon } from 'Assets/images/Common/down-arrow.svg';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';
import usePermissions from 'Components/common/Permissions';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function ProfileGoals(props) {
    const API = useMemo(() => new Api(), []);

    const [goals, setGoals] = useState([]);
    const [openSelection, setOpenSelection] = useState(false);
    const [dailyMeditationTarget, setDailyMeditationTarget] = useState('');
    const profilePermissions = usePermissions();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.Auth.userInfo);

    const getScreenTwoData = useCallback(async () => {
        props?.setIsLoading(true);
        let response = await API.get(API_URL.GOALS_SCREEN_2);

        if (response) {
            setDailyMeditationTarget(response?.data?.data?.dailyMeditationTarget);
            setGoals(response?.data?.data?.list || []);
            props?.setIsLoading(false);
        }
    }, [API, props]);

    const openGoalSelectionDialog = () => {
        setOpenSelection(true);
    };

    const handleMeditationTarget = async (e) => {
        props.setIsLoading(true);
        setDailyMeditationTarget(e.target.value);

        const data = {
            keywordIds: [],
            deletedKeywordIds: [],
            screen: 1,
            dailyMeditationTarget: e.target.value
        };

        let response = await API.put(API_URL.USER_GOALS, {
            data
        });

        if (response) {
            props.setIsLoading(false);
        }
    };

    const getUpdatedGoalData = (shouldUpdate = null) => {
        if (shouldUpdate) getScreenTwoData();
    };

    useEffect(() => {
        getScreenTwoData();
    }, [getScreenTwoData]);

    return (
        <ProfileGoalsSectionWrapper>
            <TaoScrollbar displayScroll>
                <Box className="goals-container">
                    <Typography className="goal-instruction-text">
                        {userInfo?.firstName}, update your goals here to help us support you with
                        the right content!
                    </Typography>
                    <Typography className="goals-text-style">Wellness goals</Typography>

                    {goals
                        ?.filter((data) => data.isSelected)
                        ?.map((wellnessGoal) => (
                            <React.Fragment key={wellnessGoal.keywordId}>
                                <TaoChip
                                    title={wellnessGoal.name}
                                    id={wellnessGoal.keywordId}
                                    selected={wellnessGoal.isSelected}
                                    isDisabled={true}
                                />
                            </React.Fragment>
                        ))}

                    <Button
                        className="btn-style"
                        onClick={() =>
                            !profilePermissions?.canAccess
                                ? dispatch(showSubscriptionModal())
                                : openGoalSelectionDialog()
                        }
                        endIcon={!profilePermissions?.canAccess && <SubscriptionLightLock />}>
                        UPDATE MY GOALS
                    </Button>
                    <Typography className="goals-text-style">Daily Meditation Target</Typography>

                    <FormControl variant="filled" className="select-time" size="small">
                        <InputLabel>Time</InputLabel>
                        <Select
                            IconComponent={SelectDropDownIcon}
                            variant="filled"
                            className="selector"
                            disableUnderline
                            value={dailyMeditationTarget}
                            onChange={handleMeditationTarget}
                            inputProps={{
                                className: 'filled-select'
                            }}>
                            {TIME_MENU.map((min) => (
                                <MenuItem key={min.id} className="menu-items" value={min.time}>
                                    {min.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {openSelection && (
                    <GoalSelection
                        openSelection={openSelection}
                        setOpenSelection={setOpenSelection}
                        resetLeftMenuHandler={props?.resetLeftMenuHandler}
                        getUpdatedGoalData={getUpdatedGoalData}
                    />
                )}
            </TaoScrollbar>
        </ProfileGoalsSectionWrapper>
    );
}

export default ProfileGoals;
