import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
    // Card,
    // CardContent,
    Typography,
    IconButton,
    Button,
    // Chip, //healing section
    Box,
    Tooltip
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
// import Slider from 'react-slick';  //healing section
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// PHASE 2
// import { ReactComponent as DownArrow } from 'Assets/images/Common/down-arrow.svg'; //healing section
// import { ReactComponent as UpArrow } from 'Assets/images/Notifications/up-arrow.svg'; //healing section
import /* BodySVG, */ /*MaleSVG //healing section */ /*, FemaleSVG //healing section*/ /* PlainBodySVG */ './CategoryBodySVG';
// import { ReactComponent as BodyChannelMale } from 'Assets/images/FieldHealing/body-channel-male.svg'; //healing section
// import { ReactComponent as BodyChannelFemale } from 'Assets/images/FieldHealing/body-channel-female.svg'; //healing section

// import { ReactComponent as FemaleBodyLined } from 'Assets/images/FieldHealing/female-body-lined.svg'; //healing section
// import { ReactComponent as MaleBodyLined } from 'Assets/images/FieldHealing/male-body-lined.svg'; //healing section
// import { ReactComponent as FemaleBodyNaming } from 'Assets/images/FieldHealing/female-body-naming-2.svg'; //healing section
// import { ReactComponent as MaleBodyNaming } from 'Assets/images/FieldHealing/male-body-naming-2.svg'; //healing section
// PHASE 2
import TaoCard from 'Components/common/TaoCard';
// import TaoScrollbar from 'Components/common/Scrollbar';
// import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoSlider from 'Components/common/TaoSlider';

// import SliderNextButton from 'Assets/images/Common/slider-next-button.svg'; PHASE 2
// import PlaylistIcon from 'Assets/images/Common/Playlist_icon.svg'; PHASE 2

// import TaoCalligraphyTeacherIndicator from 'Assets/images/Common/tao-calligraphy-teacher-indicator.svg'; PHASE 2
// import ProfileImage1 from 'Assets/images/Common/profile-image-1.png'; PHASE 2
// import ProfileImage4 from 'Assets/images/Common/profile-image-4.png'; PHASE 2
// import ProfileImage5 from 'Assets/images/Common/profile-image-5.png'; PHASE 2
// import FeaturedSessionImage from 'Assets/images/Common/ms-transformative-art-of-flourishing.png'; PHASE 2
import FilterSettings from 'Assets/images/Common/filter-settings.svg';
import InActiveFilterSettings from 'Assets/images/Common/filter-setting-inactive.svg';

// import CalendarCompletedIcon from 'Assets/images/Common/calendar-completed-icon.svg'; //healing section
// import NextIcon from 'Assets/images/Common/next-arrow.svg';
import Footer from 'Components/common/Footer/Footer';

import VideoIcon from 'Assets/images/Common/video-icon.svg';
import AudioIcon from 'Assets/images/Common/audio-icon.svg';
import MusicIcon from 'Assets/images/Common/music-icon.svg';

import MeditationContentFilter from './Filter';
import {
    WellnessCategoryWrapper,
    TopGirlImageWrapper,
    MainContentWrapper,
    ProgramsSectionWrapper,
    ProgramSectionTitleWrapper,
    // TodaysAndFeaturedSessionWrapper, PHASE 2
    // UpcomingSessionFeaturedSessionWrapper, PHASE 2
    // CalendarContentWrapper, PHASE 2
    // UpcomingSessionListWrapper, PHASE 2
    // FeaturedSessionContentWrapper, PHASE 2
    // FeaturedSessionListWrapper, PHASE 2
    MeditationSessionWrapper
    // FieldHealingSectionWrapper //healing section
} from './Category.style';
// import { BREAKPOINTS_VALUE } from 'Styles/Constants'; //healing section
import {
    // URL_BODY_ORGANS_DETAILS, //healing section
    API_URL,
    URL_PROGRAMS,
    URL_CATEGORY_SEE_ALL
} from 'Helpers/Paths';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

// import { getWindowDimensions } from 'Helpers'; //healing section
import Api from 'Helpers/ApiHandler';
import { CONTENT, MEDIA_TYPE, SLIDER_PER_PAGE } from 'Helpers/Constants';
// import DOMPurify from 'dompurify'; //healing section
import { BoxLoader, TitleLoader } from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import SeeAllTypography from 'Components/common/SeeAll/Typography/SeeAllTypography';
import { useDispatch } from 'react-redux';
import { handleScrollToViewId, showSubscriptionModal } from 'Redux/App/Actions';
import { useSelector } from 'react-redux';
import { handleContentRedirection } from 'Helpers';
import TaoScrollbar from 'Components/common/Scrollbar';
// let body = null; PHASE 2

// PHASE 2
// const myUpcomingSessions = [
//     {
//         isLive: false,
//         sessionImage: ProfileImage5,
//         sessionTime: '7:00 AM',
//         sessionTitle: 'Morning Freshen Up',
//         sessionWith: 'Day 6 of 7 - Day Energy Refill',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: true
//     },
//     {
//         isLive: true,
//         sessionImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: false,
//         sessionImage: ProfileImage4,
//         sessionTime: '6:00 – 7:00 PM',
//         sessionTitle: 'Self-Love Practice Hour',
//         sessionWith: 'with Elizabeth',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     }
// ];

// PHASE 2
// const featuredSessions = [
//     {
//         sessionImage: FeaturedSessionImage,
//         isLive: true,
//         sessionDate: '27',
//         sessionWeekDay: 'SAT'
//     },
//     {
//         sessionImage: FeaturedSessionImage,
//         isLive: false,
//         sessionDate: '27',
//         sessionWeekDay: 'SAT'
//     }
// ];

const WellnessCategory = (props) => {
    //healing section
    // const [healingChips] = useState(BUTTON_LIST);
    // const [selectedChip, setSelectedChip] = useState({
    //     key: 1,
    //     value: 'physical body',
    //     selectionCount: '16'
    // });
    // const [selectedGender, setSelectedGender] = useState('male'); //healing section
    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions()); //healing section
    const scrollIntoViewId = useSelector((state) => state.App.scrollIntoViewId);

    const [wellnessCategoryProgram, setWellnessCategoryProgram] = useState({
        list: [],
        totalRecords: 0,
        currentPage: 0
    });
    const dispatch = useDispatch();
    const [meditationFilterAnchorEl, setMeditationFilterAnchorEl] = useState(null);
    const [userSelectedFilterOptions, setUserSelectedFilterOptions] = useState({
        topicId: null,
        tagId: null
    });

    const videoContentSliderRef = useRef(null);
    const guidedAudioContentSliderRef = useRef(null);
    const musicContentSliderRef = useRef(null);

    const [wellnessContent, setWellnessContent] = useState({
        videoDetail: {
            type: MEDIA_TYPE.VIDEO,
            typeIcon: VideoIcon,
            list: [],
            totalRecords: 0,
            currentPage: 0,
            title: 'Videos',
            ref: videoContentSliderRef
        },
        guidedMeditationAudioDetail: {
            type: MEDIA_TYPE.GUIDED_AUDIO,
            typeIcon: AudioIcon,
            list: [],
            totalRecords: 0,
            currentPage: 0,
            title: 'Guided Audio',
            ref: guidedAudioContentSliderRef
        },
        musicDetail: {
            type: MEDIA_TYPE.MUSIC,
            typeIcon: MusicIcon,
            list: [],
            totalRecords: 0,
            currentPage: 0,
            title: 'Music',
            ref: musicContentSliderRef
        }
    });

    const [selectedFilterTitle, setSelectedFilterTitle] = useState({
        subCategory: '',
        tag: '',
        filterOptionSelectionCount: 0
    });
    const [programLoading, setProgramLoading] = useState(false);
    const [wellnessContentLoading, setWellnessContentLoading] = useState(false);
    // const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { wellnessCategoryDetails } = location?.state;

    const API = useMemo(() => new Api(), []);

    // PHASE 2
    // useEffect(() => {  //healing section
    //     function handleResize() {
    //         setWindowDimensions(getWindowDimensions());
    //     }

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    // useEffect(() => {
    //     function handleClick(e) {
    //         getClickedId(e.target.id);
    //     }
    //     if (selectedChip.key === 1) {
    //         // this will remove listener of previous gender before creating new event listener
    //         body && body.removeEventListener('click', handleClick);

    //         body = document.getElementById('body');

    //         body.addEventListener('click', handleClick);
    //     }

    //     return () => body && body.removeEventListener('click', handleClick);
    // }, [selectedGender, selectedChip]);

    // WELLNESS CATEGORY
    const getWellnessCategoryPrograms = useCallback(
        async (current_page = 0, mutateLoading = false) => {
            if (mutateLoading) setProgramLoading(true);

            setWellnessCategoryProgram((prev) => ({
                currentPage: 0,
                list: [],
                totalRecords: 0
            }));

            let response = await API.post(API_URL.WELLNESS_CATEGORY_PROGRAM_LIST, {
                params: {
                    categoryId: wellnessCategoryDetails?.categoryId
                },
                data: {
                    current_page: 0,
                    per_page: SLIDER_PER_PAGE,
                    search: ''
                }
            });

            if (response?.data?.data?.list?.length > 0)
                setWellnessCategoryProgram((prev) => ({
                    currentPage: prev?.currentPage,
                    list: [...prev?.list, ...response?.data?.data?.list],
                    totalRecords: response?.data?.data?.totalRecords
                }));
            setProgramLoading(false);
        },
        [API, wellnessCategoryDetails?.categoryId]
    );

    // WELLNESS CATEGORY CONTENT
    const getWellnessCategoryContents = useCallback(
        async (
            categoryId = 0,
            tagsId = [],
            contentType = '',
            currentPage = 0,
            perPage = SLIDER_PER_PAGE
        ) => {
            setWellnessContentLoading(true);
            setWellnessContent((prev) => ({
                videoDetail: {
                    ...prev?.videoDetail,
                    type: MEDIA_TYPE.VIDEO,
                    typeIcon: VideoIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Videos'
                },
                guidedMeditationAudioDetail: {
                    ...prev?.guidedMeditationAudioDetail,
                    type: MEDIA_TYPE.GUIDED_AUDIO,
                    typeIcon: AudioIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Guided Audio'
                },
                musicDetail: {
                    ...prev?.musicDetail,
                    type: MEDIA_TYPE.MUSIC,
                    typeIcon: MusicIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Music'
                }
            }));
            let response = await API.post(API_URL.WELLNESS_CATEGORY_CONTENTS, {
                data: {
                    categoryId,
                    tagsId,
                    contentType, // Video/Music/Guided_Meditation_Audio
                    current_page: currentPage,
                    per_page: perPage,
                    search: ''
                }
            });

            if (response?.data?.data) {
                let responseData = response?.data?.data;

                if (responseData?.videoDetail) {
                    setWellnessContent((prev) => ({
                        ...prev,
                        videoDetail: {
                            ...prev?.videoDetail,
                            list: [...prev?.videoDetail?.list, ...responseData?.videoDetail?.list],
                            totalRecords: responseData?.videoDetail?.totalRecords,
                            currentPage: prev?.videoDetail?.currentPage + 1
                        }
                    }));
                }

                if (responseData?.guidedMeditationAudioDetail) {
                    setWellnessContent((prev) => ({
                        ...prev,
                        guidedMeditationAudioDetail: {
                            ...prev?.guidedMeditationAudioDetail,
                            list: [
                                ...prev?.guidedMeditationAudioDetail?.list,
                                ...responseData?.guidedMeditationAudioDetail?.list
                            ],
                            totalRecords: responseData?.guidedMeditationAudioDetail?.totalRecords,
                            currentPage: prev?.guidedMeditationAudioDetail?.currentPage + 1
                        }
                    }));
                }

                if (responseData?.musicDetail) {
                    setWellnessContent((prev) => ({
                        ...prev,
                        musicDetail: {
                            ...prev?.musicDetail,
                            list: [...prev?.musicDetail?.list, ...responseData?.musicDetail?.list],
                            totalRecords: responseData?.musicDetail?.totalRecords,
                            currentPage: prev?.musicDetail?.currentPage + 1
                        }
                    }));
                }
            }
            setWellnessContentLoading(false);
        },
        [API]
    );

    const favoriteContent = async (value, type, index) => {
        let newWellnessContent = [...wellnessContent[type]?.list];
        newWellnessContent[index].isFavorite = !newWellnessContent[index]?.isFavorite;

        setWellnessContent((prev) => ({
            ...prev,
            [type]: {
                ...prev?.[type],
                list: newWellnessContent
            }
        }));

        await API.post(API_URL.CONTENT_FAVOURITES, {
            params: {
                contentId: value?.id
            }
        });
    };

    const favoriteProgram = async (value, index) => {
        let newWellnessCategoryProgram = [...wellnessCategoryProgram?.list];

        newWellnessCategoryProgram[index].isFavorite =
            !newWellnessCategoryProgram[index].isFavorite;

        setWellnessCategoryProgram((prev) => ({
            ...prev,
            list: newWellnessCategoryProgram
        }));

        await API.post(API_URL.PROGRAM_FAVORITE, {
            params: {
                programId: value?.id
            }
        });
    };

    // PHASE 2
    // const getClickedId = (id) => {
    //     switch (id) {
    //         case 'Mouth_Lips_Teeth_Gums':
    //             alert('Mouth');
    //             break;
    //         case 'Neck':
    //             alert('Neck');
    //             break;
    //         case 'Shoulders':
    //             alert('Shoulders');
    //             break;
    //         case 'Elbows':
    //             alert('Elbows');
    //             break;
    //         case 'Wrists':
    //             alert('Wrists');
    //             break;
    //         case 'Hands':
    //             alert('Hands');
    //             break;
    //         case 'Knees':
    //             alert('Knees');
    //             break;
    //         case 'Ankles':
    //             alert('Ankles');
    //             break;
    //         case 'Feet':
    //             alert('Feet');
    //             break;
    //         case 'Hipbones':
    //             alert('Hipbones');
    //             break;
    //         case 'Muscles':
    //             alert('Muscles');
    //             break;
    //         case 'Nose':
    //             alert('Nose');
    //             break;
    //         case 'Ears':
    //             alert('Ears');
    //             break;
    //         case 'Eyes':
    //             alert('Eyes');
    //             break;
    //         case 'Lower_Back':
    //             alert('Lower back');
    //             break;
    //         default:
    //             alert('default');
    //             break;
    //     }
    // };

    // let selectionItemSlider = useRef(null); //healing section
    const wellnessCategoryProgramSliderRef = useRef(null);

    // PHASE 2
    // const onSliderChange = (newIndex) => { //healing section
    //     setSelectedChip(healingChips[newIndex]);
    // };

    // PHASE 2
    // let BODY_SLIDER_SETTINGS = { //healing section
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     centerMode: true,
    //     centerPadding: '40px',
    //     infinite: false,
    //     swipeToSlide: true,
    //     variableWidth: true,
    //     accessibility: true,
    //     focusOnSelect: false,
    //     draggable: true,
    //     afterChange: onSliderChange,
    //     responsive: [
    //         {
    //             breakpoint: BREAKPOINTS_VALUE.DESKTOP,
    //             settings: {
    //                 slidesToShow: 3
    //             }
    //         },
    //         {
    //             breakpoint: BREAKPOINTS_VALUE.LAPTOP,
    //             settings: {
    //                 slidesToShow: 3
    //             }
    //         },
    //         {
    //             breakpoint: BREAKPOINTS_VALUE.TABLET,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             breakpoint: BREAKPOINTS_VALUE.PHABLET,
    //             settings: {
    //                 slidesToShow: 1,
    //                 arrows: false
    //             }
    //         }
    //     ]
    // };

    // PHASE 2
    // const BODY_SUB_CONTENT_SLIDER_SETTINGS = { //healing section
    //     arrows: false,
    //     infinite: false,
    //     vertical: true,
    //     verticalSwiping: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 2,
    //     responsive: [
    //         {
    //             breakpoint: BREAKPOINTS_VALUE.PHABLET,
    //             settings: {
    //                 slidesToShow: 6,
    //                 slidesToScroll: 2
    //             }
    //         }
    //     ]
    // };

    const handleMeditationFilterOptions = (event) => {
        setMeditationFilterAnchorEl(event?.currentTarget);
    };

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    // const getWellnessCategoryDesc = useCallback(async () => {
    //     const response = await API.get(API_URL.WELLNESS_CATEGORY);

    //     if (response) {
    //         console.log(
    //             '🚀 ~ file: Category.jsx ~ line 529 ~ getWellnessCategoryDesc ~ response',
    //             response
    //         );
    //     }
    // }, [API]);

    // useEffect(() => {
    //     getWellnessCategoryDesc();
    // }, [getWellnessCategoryDesc]);

    const open = Boolean(meditationFilterAnchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        // WELLNESS CATEGORY PROGRAM
        if (wellnessCategoryDetails?.categoryId) {
            getWellnessCategoryPrograms(0, true);

            getWellnessCategoryContents(wellnessCategoryDetails?.categoryId);
        }

        return () => {
            setMeditationFilterAnchorEl(null);
            setUserSelectedFilterOptions({ tagId: null, topicId: null });
            setSelectedFilterTitle({ filterOptionSelectionCount: 0, subCategory: '', tag: '' });
            setWellnessContent((prev) => ({
                videoDetail: {
                    ...prev.videoDetail,
                    type: MEDIA_TYPE.VIDEO,
                    typeIcon: VideoIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Videos'
                },
                guidedMeditationAudioDetail: {
                    ...prev.guidedMeditationAudioDetail,
                    type: MEDIA_TYPE.GUIDED_AUDIO,
                    typeIcon: AudioIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Guided Audio'
                },
                musicDetail: {
                    ...prev.musicDetail,
                    type: MEDIA_TYPE.MUSIC,
                    typeIcon: MusicIcon,
                    list: [],
                    totalRecords: 0,
                    currentPage: 0,
                    title: 'Music'
                }
            }));
        };
    }, [
        wellnessCategoryDetails?.categoryId,
        getWellnessCategoryContents,
        getWellnessCategoryPrograms
    ]);

    const executeScroll = useCallback(() => {
        const categoryScrollId = document.getElementById(scrollIntoViewId);

        if (categoryScrollId) {
            categoryScrollId?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        dispatch(handleScrollToViewId(''));
    }, [scrollIntoViewId, dispatch]);

    useEffect(() => {
        if (scrollIntoViewId) executeScroll(scrollIntoViewId);
    }, [scrollIntoViewId, executeScroll]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <WellnessCategoryWrapper>
                <TopGirlImageWrapper>
                    <Box
                        className="girl-image"
                        style={{ backgroundImage: `url(${wellnessCategoryDetails?.heroImage})` }}>
                        {/* <img src={wellnessCategoryDetails?.heroImage} alt="" /> */}
                    </Box>
                    <Box className="category-title">
                        <Typography className="welcome-to-text width-max-content">
                            Welcome to{' '}
                        </Typography>
                        <Typography className="health-text">
                            {wellnessCategoryDetails?.categoryName}
                        </Typography>
                    </Box>
                </TopGirlImageWrapper>

                <MainContentWrapper>
                    <ProgramsSectionWrapper
                        $programAvailable={wellnessCategoryProgram?.list?.length || programLoading}>
                        <ProgramSectionTitleWrapper>
                            <Typography color="primary" className="programs-text">
                                Programs
                            </Typography>
                            <SeeAllTypography redirectionURL={URL_PROGRAMS} />
                        </ProgramSectionTitleWrapper>

                        {programLoading && (
                            <TaoSlider
                                gap={10}
                                ref={wellnessCategoryProgramSliderRef}
                                slidesToShow={2}
                                cardHeight={90}>
                                {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                    <Box key={index} className="program-list-loader-wrapper">
                                        <BoxLoader
                                            customStyle={{ borderRadius: '24px 8px' }}
                                            width={210}
                                            height={120}
                                        />
                                        <TitleLoader
                                            width={210}
                                            height={24}
                                            fillWidth={140}
                                            fillHeight={10}
                                            marginLeft={40}
                                        />
                                    </Box>
                                ))}
                            </TaoSlider>
                        )}

                        {!programLoading && wellnessCategoryProgram?.list?.length > 0 && (
                            <TaoSlider
                                ref={wellnessCategoryProgramSliderRef}
                                slidesToShow={2}
                                gap={10}
                                cardHeight={90}>
                                {wellnessCategoryProgram?.list?.map((value, index) => {
                                    return (
                                        <TaoCard
                                            cardRootClass="tao-card"
                                            key={index}
                                            backgroundImage={value?.thumbnailImage}
                                            mediaTextType="primary"
                                            type={value.type}
                                            title={value.title}
                                            favoriteIconVisible={true}
                                            isFav={value?.isFavorite}
                                            badge={value?.subscription?.badge}
                                            isAccessible={
                                                value?.subscription
                                                    ? value?.subscription?.isAccessible
                                                    : true
                                            }
                                            isPaidContent={value?.isPaidContent}
                                            isPurchased={value?.isPurchased}
                                            isFeatured={value?.isFeatured}
                                            isTextColorDark
                                            textFromStart={true}
                                            onFavClick={() => {
                                                favoriteProgram(value, index);
                                            }}
                                            onClick={() => {
                                                handleContentRedirection(value, navigateTo);
                                            }}
                                        />
                                    );
                                })}
                            </TaoSlider>
                        )}
                    </ProgramsSectionWrapper>

                    <MeditationSessionWrapper>
                        <Box className="meditations-header-section">
                            <Box className="meditation-title">
                                <Typography color="primary" className="meditations-text">
                                    Meditations
                                </Typography>

                                {!wellnessContentLoading && (
                                    <Button
                                        component={'span'}
                                        variant="contained"
                                        className={`filters-button ${
                                            selectedFilterTitle?.tag && 'apply-filter-color'
                                        }`}
                                        onClick={handleMeditationFilterOptions}>
                                        {`Filter ${
                                            (selectedFilterTitle?.filterOptionSelectionCount &&
                                                `(${selectedFilterTitle?.filterOptionSelectionCount})`) ||
                                            ''
                                        }`}
                                        <IconButton>
                                            <img
                                                src={
                                                    selectedFilterTitle?.tag
                                                        ? FilterSettings
                                                        : InActiveFilterSettings
                                                }
                                                alt=""
                                            />
                                        </IconButton>
                                    </Button>
                                )}

                                {open && (
                                    <MeditationContentFilter
                                        id={id}
                                        open={open}
                                        setMeditationFilterAnchorEl={setMeditationFilterAnchorEl}
                                        meditationFilterAnchorEl={meditationFilterAnchorEl}
                                        wellnessCategoryDetails={wellnessCategoryDetails}
                                        setWellnessContent={setWellnessContent}
                                        getWellnessCategoryContents={getWellnessCategoryContents}
                                        setUserSelectedFilterOptions={setUserSelectedFilterOptions}
                                        userSelectedFilterOptions={userSelectedFilterOptions}
                                        setSelectedFilterTitle={setSelectedFilterTitle}
                                    />
                                )}
                            </Box>

                            <Box
                                className={`sub-category-main-container ${
                                    !wellnessCategoryDetails?.subCategoryDetails?.length &&
                                    'hide-section'
                                }`}>
                                <Box className="sub-category-header-wrapper">
                                    <Typography color="primary" className="sub-category-header">
                                        Topic{' '}
                                        <span className="sub-category-header light">
                                            choose one
                                        </span>
                                    </Typography>
                                </Box>

                                <Box className="sub-category-wrapper" sx={{ height: 122 }}>
                                    <TaoScrollbar keyId={'subcategory'} horizontalScroll>
                                        {wellnessCategoryDetails?.subCategoryDetails?.map(
                                            (value, index) => (
                                                <Box
                                                    key={index}
                                                    className="sub-category-image-with-title"
                                                    onClick={() => {
                                                        if (wellnessContentLoading) return;
                                                        let clickOnActivatedSubCategory = false;
                                                        if (
                                                            userSelectedFilterOptions?.topicId ===
                                                            value?.id
                                                        )
                                                            clickOnActivatedSubCategory = true;

                                                        setUserSelectedFilterOptions((prev) => ({
                                                            ...prev,
                                                            tagId: null,
                                                            topicId: clickOnActivatedSubCategory
                                                                ? null
                                                                : value?.id
                                                        }));
                                                        setSelectedFilterTitle((prev) => ({
                                                            ...prev,
                                                            subCategory: clickOnActivatedSubCategory
                                                                ? ''
                                                                : value?.name,
                                                            filterOptionSelectionCount: 0,
                                                            tag: ''
                                                        }));
                                                        getWellnessCategoryContents(
                                                            clickOnActivatedSubCategory
                                                                ? wellnessCategoryDetails?.categoryId
                                                                : value?.id
                                                        );
                                                    }}>
                                                    <img
                                                        alt=""
                                                        src={
                                                            userSelectedFilterOptions?.topicId ===
                                                            value?.id
                                                                ? value?.selectedIcon
                                                                : value?.icon
                                                        }
                                                        className="sub-category-image "
                                                    />
                                                    <Typography
                                                        color="primary"
                                                        className="sub-category-title">
                                                        {value?.name}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </TaoScrollbar>
                                </Box>
                            </Box>

                            <Box className="total-results">
                                <Tooltip
                                    title={`${
                                        wellnessContent?.guidedMeditationAudioDetail?.totalRecords +
                                            wellnessContent?.musicDetail?.totalRecords +
                                            wellnessContent?.videoDetail?.totalRecords ||
                                        'No Results found'
                                    } ${
                                        wellnessCategoryDetails?.categoryName &&
                                        `in ${wellnessCategoryDetails?.categoryName}`
                                    }`}
                                    placement="top">
                                    <Typography
                                        color="primary"
                                        className="meditations-result-text min-width-max-content">
                                        <span className="total-count">
                                            {wellnessContent?.guidedMeditationAudioDetail
                                                ?.totalRecords +
                                                wellnessContent?.musicDetail?.totalRecords +
                                                wellnessContent?.videoDetail?.totalRecords ||
                                                'No Results found'}
                                            {/* No Results Found */}
                                        </span>{' '}
                                        {wellnessCategoryDetails?.categoryName && (
                                            <>
                                                <span className="text">in</span>{' '}
                                                <span className="category-text">
                                                    {wellnessCategoryDetails?.categoryName}
                                                </span>{' '}
                                            </>
                                        )}
                                    </Typography>
                                </Tooltip>
                                {selectedFilterTitle?.subCategory && (
                                    <>
                                        <span> {`>`} </span>
                                        <Tooltip
                                            title={selectedFilterTitle?.subCategory}
                                            placement="top">
                                            <Typography
                                                color="primary"
                                                className="meditations-result-breadcrumb-text min-width-max-content">
                                                {selectedFilterTitle?.subCategory}
                                            </Typography>
                                        </Tooltip>
                                    </>
                                )}
                                {selectedFilterTitle?.tag && (
                                    <>
                                        <span> {`>`} </span>
                                        <Tooltip title={selectedFilterTitle?.tag} placement="top">
                                            <Typography
                                                color="primary"
                                                className="meditations-result-breadcrumb-text">
                                                {selectedFilterTitle?.tag}
                                            </Typography>
                                        </Tooltip>
                                    </>
                                )}
                            </Box>

                            {/* <Box className="clear-filters-button-section">
                                <Button variant="contained" className="clear-filters-button">
                                    CLEAR FILTERS
                                </Button>
                            </Box> */}
                        </Box>

                        {wellnessContentLoading &&
                            Object.keys(wellnessContent).map((content, index) => (
                                <React.Fragment key={index}>
                                    <Box className="meditation-contents" key={index}>
                                        <Box color="primary" className="content-type-container">
                                            <IconButton style={{ height: '30px' }}>
                                                <img
                                                    src={wellnessContent[content].typeIcon}
                                                    alt=""
                                                />
                                            </IconButton>
                                            <Typography className="content-type-text">
                                                {wellnessContent[content].title}
                                            </Typography>
                                        </Box>

                                        <Box className="card-list-container" sx={{ height: 170 }}>
                                            <TaoSlider
                                                gap={10}
                                                ref={wellnessContent[content]?.ref}
                                                slidesToShow={1}
                                                cardHeight={90}>
                                                {[1, 2, 4, 5, 6]?.map((value, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            className="meditation-contents-list-loader-wrapper">
                                                            <BoxLoader
                                                                customStyle={{
                                                                    borderRadius: '24px 8px'
                                                                }}
                                                                width={210}
                                                                height={120}
                                                            />
                                                            <TitleLoader
                                                                width={210}
                                                                height={24}
                                                                fillWidth={140}
                                                                fillHeight={10}
                                                                marginLeft={40}
                                                            />
                                                        </Box>
                                                    );
                                                })}
                                            </TaoSlider>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            ))}

                        {!wellnessContentLoading &&
                            !!Object.keys(wellnessContent).filter(
                                (content) => wellnessContent[content]?.list?.length
                            )?.length &&
                            Object.keys(wellnessContent).map((content, index) => (
                                <React.Fragment key={index}>
                                    <Box
                                        className="meditation-contents"
                                        key={index}
                                        id={wellnessContent[content].title}>
                                        <Box className="flex f-v-center">
                                            <Box color="primary" className="content-type-container">
                                                <IconButton style={{ height: '30px' }}>
                                                    <img
                                                        src={wellnessContent[content].typeIcon}
                                                        alt=""
                                                    />
                                                </IconButton>
                                                <Typography className="content-type-text">
                                                    {wellnessContent[content].title}
                                                </Typography>
                                            </Box>

                                            <SeeAllTypography
                                                redirectionURL={URL_CATEGORY_SEE_ALL}
                                                state={{
                                                    type: wellnessContent[content].type,
                                                    title: wellnessCategoryDetails?.categoryName,
                                                    categoryId: wellnessCategoryDetails?.categoryId,
                                                    subCategories:
                                                        wellnessCategoryDetails?.subCategoryDetails,
                                                    selectedTopicId:
                                                        userSelectedFilterOptions?.topicId
                                                }}
                                                reduxAction={() =>
                                                    dispatch(
                                                        handleScrollToViewId(
                                                            wellnessContent[content].title
                                                        )
                                                    )
                                                }
                                            />
                                        </Box>

                                        <Box className="card-list-container" sx={{ height: 170 }}>
                                            <TaoSlider
                                                gap={10}
                                                ref={wellnessContent[content]?.ref}
                                                slidesToShow={2}
                                                cardHeight={90}>
                                                {wellnessContent[content]?.list?.length > 0 &&
                                                    wellnessContent[content]?.list?.map(
                                                        (value, index) => {
                                                            return (
                                                                <TaoCard
                                                                    key={index}
                                                                    backgroundImage={
                                                                        value?.thumbnailImage
                                                                    }
                                                                    type={value?.type}
                                                                    title={value?.contentName}
                                                                    favoriteIconVisible={true}
                                                                    isFav={value?.isFavorite}
                                                                    badge={
                                                                        value?.subscription?.badge
                                                                    }
                                                                    isAccessible={
                                                                        value?.subscription
                                                                            ? value?.subscription
                                                                                  ?.isAccessible
                                                                            : true
                                                                    }
                                                                    isPaidContent={
                                                                        value?.isPaidContent
                                                                    }
                                                                    isPurchased={value?.isPurchased}
                                                                    isFeatured={value?.isFeatured}
                                                                    textFromStart={true}
                                                                    onFavClick={() => {
                                                                        favoriteContent(
                                                                            value,
                                                                            content,
                                                                            index
                                                                        );
                                                                    }}
                                                                    onClick={() => {
                                                                        handleContentRedirection(
                                                                            {
                                                                                ...value,
                                                                                type: CONTENT
                                                                            },
                                                                            navigateTo
                                                                        );
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                {!wellnessContent[content]?.list?.length && (
                                                    <Box className="placeholder-box-wrapper">
                                                        <Typography className="placeholder-box-text">
                                                            {`No ${wellnessContent[content].title} Found`}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {/* {wellnessContent &&
                                                        wellnessContent[content] &&
                                                        wellnessContent[content]?.totalRecords !==
                                                            wellnessContent[content]?.list
                                                                ?.length && (
                                                            <Box
                                                                className="load-more-button-container flex f-v-center"
                                                                onClick={() =>
                                                                    handleLoadMore(content)
                                                                }>
                                                                <Typography
                                                                    color="primary"
                                                                    className="load-more">
                                                                    {'Load More'}
                                                                </Typography>
                                                                <img
                                                                    src={NextIcon}
                                                                    alt=""
                                                                    className="next-arrow-icon"
                                                                />
                                                            </Box>
                                                        )} */}
                                            </TaoSlider>
                                        </Box>
                                        {/* </Slider> */}
                                    </Box>
                                </React.Fragment>
                            ))}
                    </MeditationSessionWrapper>

                    {/* <FieldHealingSectionWrapper>
                        <Box className="field-healing-image-section">
                            <Box className="field-healing-image-details">
                                <Typography color="primary" className="field-healing-text">
                                    Field Healing
                                </Typography>

                                <div className="slider-content" style={{ marginTop: '10px' }}>
                                    <Slider {...BODY_SLIDER_SETTINGS}>
                                        {healingChips.map((chip) => (
                                            <Chip
                                                sx={{ color: 'text.dark' }}
                                                onClick={() => setSelectedChip(chip)}
                                                key={chip.key}
                                                className={
                                                    'goal-chip ' +
                                                    (chip.key === selectedChip.key
                                                        ? 'selected-goal'
                                                        : 'non-selected-goal')
                                                }
                                                size="medium"
                                                color="primary"
                                                variant={
                                                    chip.key === selectedChip.key
                                                        ? 'filled'
                                                        : 'outlined'
                                                }
                                                label={chip.value}
                                            />
                                        ))}
                                    </Slider>
                                </div>

                                <Box className="body-section">
                                    <Box className="body-section-parent-div">
                                        <Box className="flex f-h-center selection-count">
                                            {selectedChip.selectionCount} selections
                                        </Box>

                                        {selectedChip.key === 1 ? (
                                            <>
                                                {selectedGender === 'male' ? (
                                                    <MaleBodyNaming width={'345'} height={'500'} />
                                                ) : (
                                                    <FemaleBodyNaming
                                                        width={'345'}
                                                        height={'500'}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {selectedChip.key === 5 ? (
                                                    <>
                                                        {selectedGender === 'male' ? (
                                                            <BodyChannelMale
                                                                width={'340'}
                                                                height={'400'}
                                                            />
                                                        ) : (
                                                            <BodyChannelFemale
                                                                width={'340'}
                                                                height={'400'}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {selectedGender === 'male' ? (
                                                            <>
                                                                <MaleBodyLined
                                                                    width={
                                                                        windowDimensions?.width <=
                                                                        BREAKPOINTS_VALUE.PHABLET
                                                                            ? '330'
                                                                            : '310'
                                                                    }
                                                                    height={'400'}
                                                                />
                                                            </>
                                                        ) : (
                                                            <FemaleBodyLined
                                                                width={
                                                                    windowDimensions?.width <=
                                                                    BREAKPOINTS_VALUE.PHABLET
                                                                        ? '330'
                                                                        : '310'
                                                                }
                                                                height={'400'}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {selectedChip.key !== 5 && (
                                            <Box
                                                className={`flex f-h-center ${
                                                    selectedGender === 'male' && 'svg-div'
                                                }`}>
                                                <Box onClick={() => setSelectedGender('male')}>
                                                    <MaleSVG
                                                        isSelected={selectedGender === 'male'}
                                                    />
                                                </Box>
                                                <Box
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => setSelectedGender('female')}>
                                                    <FemaleSVG
                                                        isSelected={selectedGender === 'female'}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    {![1, 5].includes(selectedChip.key) &&
                                        selectedChip?.children?.length && (
                                            <Box className="selection-container f-v-center">
                                                {selectedChip.key === 2 ||
                                                selectedChip.key === 3 ||
                                                selectedChip.key === 4 ? (
                                                    <>
                                                        <Box
                                                            onClick={() =>
                                                                selectionItemSlider.current.slickPrev()
                                                            }>
                                                            <UpArrow />
                                                        </Box>
                                                        <Slider
                                                            className="flex flex-h-center selection-div"
                                                            ref={selectionItemSlider}
                                                            {...BODY_SUB_CONTENT_SLIDER_SETTINGS}>
                                                            {selectedChip.children.map(
                                                                (ele, index) => (
                                                                    // eslint-disable-next-line
                                                                    <a
                                                                        key={index}
                                                                        onClick={() =>
                                                                            ele.redirectTo &&
                                                                            navigate(ele.redirectTo)
                                                                        }
                                                                        className="selection-item">
                                                                        {ele.name}
                                                                    </a>
                                                                )
                                                            )}
                                                        </Slider>
                                                        <Box
                                                            onClick={() =>
                                                                selectionItemSlider.current.slickNext()
                                                            }>
                                                            <DownArrow />
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <Box className="flex flex-h-center selection-div p-t">
                                                        {selectedChip.children.map((ele, index) => (
                                                            <a
                                                                key={index}
                                                                className="selection-item"
                                                                href={
                                                                    props?.history?.location
                                                                        ?.pathname
                                                                }>
                                                                {ele.name}
                                                            </a>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        </Box>
                        <Box className="field-healing-text-section">
                            <Box className="field-healing-text">
                                <Typography
                                    color="primary"
                                    className="need-support-text"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            wellnessCategoryDetails?.contentBoxDetails?.title,
                                            {
                                                ALLOWED_TAGS: ['b', 'a', 'em', 'strong']
                                            }
                                        )
                                    }}>
                                </Typography>

                                <Typography
                                    color="primary"
                                    className="text-details"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            wellnessCategoryDetails?.contentBoxDetails?.description,
                                            {
                                                ALLOWED_TAGS: ['b', 'a', 'em', 'strong']
                                            }
                                        )
                                    }}>
                                </Typography>

                                <Button
                                    component={'span'}
                                    variant="contained"
                                    className="book-consultation-button">
                                    <IconButton>
                                        <img src={CalendarCompletedIcon} alt="" />
                                    </IconButton>
                                    BOOK A CONSULTATION
                                </Button>
                            </Box>
                        </Box>
                    </FieldHealingSectionWrapper> */}
                </MainContentWrapper>

                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </WellnessCategoryWrapper>
        </>
    );
};

export default WellnessCategory;

// PHASE 2
// const BUTTON_LIST = [ //healing section
//     { key: 1, value: 'physical body', selectionCount: '16' },
//     {
//         key: 2,
//         value: 'internal organs',
//         selectionCount: '22',
//         children: [
//             { name: 'Adrenal Glands' },
//             { name: 'Blood' },
//             { name: 'Blood Vessels' },
//             { name: 'Bones' },
//             { name: 'Brain' },
//             { name: 'Gallbladder' },
//             { name: 'Heart', redirectTo: URL_BODY_ORGANS_DETAILS },
//             { name: 'Kidney' },
//             { name: 'Large Intestine' },
//             { name: 'Liver' },
//             { name: 'Lungs' },
//             { name: 'Pancreas' }
//         ]
//     },
//     {
//         key: 3,
//         value: 'emotions',
//         selectionCount: '7',
//         children: [
//             { name: 'Happiness' },
//             { name: 'Healing Anger' },
//             { name: 'Healing Depression & Anxiety' },
//             { name: 'Healing Worry' },
//             { name: 'Healing Sadness' },
//             { name: 'Healing Fear' },
//             { name: 'Healing Addiction' }
//         ]
//     },
//     {
//         key: 4,
//         value: 'relationships',
//         selectionCount: '9',
//         children: [
//             { name: 'True Love' },
//             { name: 'Self Love' },
//             { name: 'Parents' },
//             { name: 'Children' },
//             { name: 'Grandparents' },
//             { name: 'Siblings' },
//             { name: 'Pets' },
//             { name: 'Friends' },
//             { name: 'Colleagues' }
//         ]
//     },
//     { key: 5, value: 'body channels', selectionCount: '3' }
// ];
