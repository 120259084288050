import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TaoScrollbar from 'Components/common/Scrollbar';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ProgramAboutWrapper } from './Programs.style';
import DOMPurify from 'dompurify';

const ProgramAbout = ({ data, ...props }) => {
    const [highestCount, setHighestCount] = useState(1);

    useEffect(() => {
        let number = 0;
        (props?.experiences || data?.reviewsList).forEach((ele) => {
            if (number < ele.count) number = parseInt(ele.count);
        });
        setHighestCount(number);
    }, [data?.reviewsList, props?.experiences]);

    return (
        <>
            <Fade in={true}>
                <ProgramAboutWrapper>
                    {!props.isOnlyProgressContent && (
                        <>
                            <Typography className="title">About the Program</Typography>

                            <Typography
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(data?.description, {
                                        FORBID_TAGS: ['p']
                                    })
                                }}
                            />
                        </>
                    )}
                    <React.Fragment>
                        <Typography className="other-experience">
                            What People Experienced
                        </Typography>
                        {props?.experiences?.length || data?.reviewsList?.length ? (
                            <Box
                                className="flex f-column"
                                height={props?.listHeight || 140}
                                sx={{ margin: '5px 0' }}>
                                <TaoScrollbar>
                                    {(props?.experiences || data?.reviewsList).map(
                                        (record, index) => (
                                            <Box className="flex full-width f-v-center" key={index}>
                                                <Box className="experience-text-container">
                                                    <Typography className="experience-text elipsis">
                                                        {record.name || record.tagValue}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    style={{ width: '50%' }}
                                                    className="flex f-v-center">
                                                    <Box
                                                        style={{
                                                            width:
                                                                (record.count / highestCount) *
                                                                    100 +
                                                                '%'
                                                        }}
                                                        className="bar"
                                                    />
                                                    <Box style={{ width: '20%' }}>
                                                        <Typography className="count">
                                                            {record.count}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    )}
                                </TaoScrollbar>
                            </Box>
                        ) : (
                            <Typography className="no-reviewed flex f-v-center f-h-center">
                                No reviews yet.
                            </Typography>
                        )}
                    </React.Fragment>
                </ProgramAboutWrapper>
            </Fade>
        </>
    );
};

export default ProgramAbout;
