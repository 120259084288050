import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Lottie from 'lottie-react';
import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import CheersDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotAppearsAndCheersUser.json';

import RatingFirstAnimation from 'Assets/AnimationFiles/Rating/MascotRating01.json';
import RatingSecondAnimation from 'Assets/AnimationFiles/Rating/MascotRating02.json';
import RatingThirdAnimation from 'Assets/AnimationFiles/Rating/MascotRating03.json';
import RatingFourAnimation from 'Assets/AnimationFiles/Rating/MascotRating04.json';
import RatingFiveAnimation from 'Assets/AnimationFiles/Rating/MascotRating05.json';
import { Box, Button, Chip, Input, Tooltip, Typography } from '@mui/material';
import { ReactComponent as PencilIcon } from 'Assets/images/Common/pencil.svg';
import { PostAssessmentWrapper } from './PostAssessment.style';
import {
    RatingEmoji6,
    RatingEmoji7,
    RatingEmoji8,
    RatingEmoji9,
    RatingEmoji10
} from '../../Pages/MeditationContent/ContentPhases/RatingEmoji';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { useDispatch, useSelector } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { useState } from 'react';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_JOURNAL, URL_PROGRAMS_SUGGESTIONS } from 'Helpers/Paths';
import TaoLoader from '../TaoBackdropLoader';
import TaoScrollbar from '../Scrollbar';
import {
    StartImage1,
    StartImage2,
    StartImage3,
    StartImage4,
    StartImage5
} from '../StartImages/StarImages.js';
import { TaoButton } from '../Button.style';
import usePermissions from '../Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { SubscriptionDarkLock } from '../SubscriptionLock';
import { showSubscriptionModal } from 'Redux/App/Actions';

const TABs = {
    MASCOT: 1,
    CHIP: 2
};
const MOOD = [
    { type: 1, icon: RatingEmoji6, animationFile: RatingFirstAnimation },
    { type: 2, icon: RatingEmoji7, animationFile: RatingSecondAnimation },
    { type: 3, icon: RatingEmoji8, animationFile: RatingThirdAnimation },
    { type: 4, icon: RatingEmoji9, animationFile: RatingFourAnimation },
    { type: 5, icon: RatingEmoji10, animationFile: RatingFiveAnimation }
];

const STAR = [
    { type: 1, icon: StartImage1, animationFile: RatingFirstAnimation },
    { type: 2, icon: StartImage2, animationFile: RatingSecondAnimation },
    { type: 3, icon: StartImage3, animationFile: RatingThirdAnimation },
    { type: 4, icon: StartImage4, animationFile: RatingFourAnimation },
    { type: 5, icon: StartImage5, animationFile: RatingFiveAnimation }
];

const PostAssessment = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.Auth.userInfo);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const API = useMemo(() => new Api(), []);
    const [contentDetails, setContentDetails] = useState({});
    const [chips, setChips] = useState([]);
    const [wordTypeValue, setWordTypeValue] = useState('');
    const [selectedRating, setSelectedRating] = useState(0);
    const [journalTitle, setJournalTitle] = useState('');
    const [animationFile, setAnimationFile] = useState(CheersDollAnimation);
    const [loop, setLoop] = useState(false);
    const [activeTab, setActiveTab] = useState(TABs.MASCOT);
    const navigate = useNavigate();
    const journalPermission = usePermissions(ACCESS_LEVELS_MODULEs.JOURNAL);

    const canAccessJournal = journalPermission?.permissionLoaded && journalPermission?.canAccess;
    const assessmentForProgram = location?.state?.type === 'PROGRAM_ASSESSMENT';

    const handleSubmit = async (redirectToJournal = false) => {
        let response = API.post(
            assessmentForProgram ? API_URL.PROGRAM_RATING : API_URL.MOOD_POST_FEEDBACK,
            {
                data: {
                    id: location?.state?.contentId,
                    ratingNumber: selectedRating,
                    tagIds: chips?.filter((chip) => chip?.isSelected).map((item) => item?.id),
                    feedbackTag: wordTypeValue || '',
                    isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
                }
            }
        );

        if (response) {
            redirectToJournal
                ? navigate(URL_JOURNAL, { state: { journalTitle: journalTitle } })
                : assessmentForProgram
                ? navigate(URL_PROGRAMS_SUGGESTIONS, {
                      state: {
                          contentId: location?.state?.contentId,
                          isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
                      }
                  })
                : navigate(-2);
        }
    };

    const fetchContentById = useCallback(async () => {
        if (!location?.state?.contentId) return;
        setIsLoading(true);
        let response = await API.get(API_URL.CONTENT_LIST, {
            params: {
                contentId: location?.state?.contentId
            }
        });
        if (response) {
            setJournalTitle(response?.data?.data?.title);
            setChips(
                response?.data?.data?.feedbackTagList.map((item) => ({
                    ...item,
                    isSelected: false
                }))
            );
            setContentDetails(response?.data?.data);
            setIsLoading(false);
        }
    }, [API, location?.state?.contentId]);

    const fetchProgramDetails = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.PROGRAM_LINKED_PROGRAMS, {
            params: {
                programId: location?.state?.contentId,
                isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
            }
        });
        if (response) {
            setChips(
                response?.data?.data?.feedbackTagList.map((item) => ({
                    ...item,
                    isSelected: false
                }))
            );

            setIsLoading(false);
        }
    }, [API, location?.state?.contentId, location?.state?.isRedirectedFromHistory]);

    useEffect(() => {
        if (location?.state?.type === 'PROGRAM_ASSESSMENT') {
            fetchProgramDetails();
        } else {
            fetchContentById();
        }
    }, [fetchContentById, fetchProgramDetails, location?.state?.type]);

    useEffect(() => {
        dispatch(hideSideBar());
        return () => {
            dispatch(showSideBar());
        };
    }, [dispatch]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={isLoading} />
            <PostAssessmentWrapper>
                <Box className="content-container">
                    <Box className="image-container">
                        <Box className="text-container">
                            <Typography className="header-title full-width elipsis">
                                You did it, {userInfo?.firstName}!
                            </Typography>

                            <Box className={`mascot ${activeTab !== TABs.MASCOT && 'hide'}`}>
                                <Lottie
                                    loop={loop}
                                    autoplay
                                    onComplete={() => {
                                        setAnimationFile(StandingDollAnimation);
                                        setLoop(true);
                                    }}
                                    animationData={animationFile}
                                    className="lottie-animation"
                                />
                            </Box>
                        </Box>

                        <Box className="experience-details-container">
                            <Box className="gold-shadow" />
                            <Box
                                className={`content ${
                                    activeTab === TABs.MASCOT && 'apply-padding'
                                }`}>
                                <Box className={`init-tab ${activeTab !== TABs.MASCOT && 'hide'}`}>
                                    <Box className="flex f-column f-v-center content-description">
                                        {location?.state?.type === 'PROGRAM_ASSESSMENT' ? (
                                            <>
                                                <Typography className="program-description">
                                                    You’ve completed
                                                </Typography>
                                                <Tooltip title={location?.state?.title}>
                                                    <Typography className="program-name">
                                                        {location?.state?.title
                                                            ? location?.state?.title
                                                            : ''}
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Typography className="level-description">
                                                {contentDetails?.ratingDetails?.ratingQuestion}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className="mood-container">
                                        {location?.state?.type === 'PROGRAM_ASSESSMENT' ? (
                                            <>
                                                <Box style={{ margin: '0 auto' }}>
                                                    {STAR.map((item) => (
                                                        <item.icon
                                                            key={item?.type}
                                                            onClick={() => {
                                                                setSelectedRating(item?.type);
                                                                setAnimationFile(
                                                                    item?.animationFile
                                                                );
                                                            }}
                                                            fill={selectedRating >= item?.type}
                                                            className="rating-margin hover"
                                                        />
                                                    ))}
                                                </Box>
                                                <Box className="flex f-h-center">
                                                    <Typography className="program-pain-text">
                                                        PLEASE RATE THIS PROGRAM
                                                    </Typography>
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Box style={{ margin: '0 auto' }}>
                                                    {MOOD.map((item) => (
                                                        <item.icon
                                                            key={item?.type}
                                                            onClick={() => {
                                                                setSelectedRating(item?.type);
                                                                setAnimationFile(
                                                                    item?.animationFile
                                                                );
                                                            }}
                                                            fill={selectedRating === item?.type}
                                                            className={`rating-margin  ${
                                                                selectedRating &&
                                                                selectedRating !== item?.type &&
                                                                'down-opacity'
                                                            } hover`}
                                                        />
                                                    ))}
                                                </Box>
                                                <Box className="flex f-h-space-between">
                                                    <Typography className="pain-text">
                                                        {contentDetails?.ratingDetails?.ratingWorst}
                                                    </Typography>
                                                    <Typography className="pain-text">
                                                        {contentDetails?.ratingDetails?.ratingBest}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                    </Box>

                                    <TaoButton
                                        disabled={!selectedRating}
                                        className="continue-btn"
                                        onClick={() => setActiveTab(TABs.CHIP)}>
                                        Continue
                                    </TaoButton>
                                </Box>

                                <Box
                                    className={`assessment-content ${
                                        activeTab !== TABs.CHIP && 'hide'
                                    }`}>
                                    <Typography className="exp-title">
                                        How was your experience?
                                    </Typography>
                                    <Box className="chips-box">
                                        <TaoScrollbar>
                                            {chips?.map((chip, index) => (
                                                <Chip
                                                    onClick={() => {
                                                        const newChips = [...chips];
                                                        newChips[index].isSelected =
                                                            !newChips[index].isSelected;

                                                        setChips(newChips);
                                                    }}
                                                    key={chip.id}
                                                    className={
                                                        'goal-chip ' +
                                                        (chip?.isSelected
                                                            ? 'selected-goal'
                                                            : 'non-selected-goal')
                                                    }
                                                    size="medium"
                                                    color="primary"
                                                    variant={
                                                        chip.isSelected ? 'filled' : 'outlined'
                                                    }
                                                    label={chip.name}
                                                />
                                            ))}
                                        </TaoScrollbar>
                                    </Box>
                                    <Input
                                        onChange={(e) => setWordTypeValue(e.target.value)}
                                        disableUnderline
                                        classes={{ input: 'input' }}
                                        className="mobile-filter-input"
                                        variant="filled"
                                        placeholder="Or type in words"
                                    />
                                    <Box className="assessment-btn-container">
                                        <Button
                                            className="save-close-btn"
                                            onClick={() => handleSubmit()}>
                                            <Typography className="btn-text">{' CLOSE'}</Typography>
                                        </Button>

                                        <Button
                                            className="save-journal-btn"
                                            startIcon={<PencilIcon />}
                                            endIcon={!canAccessJournal && <SubscriptionDarkLock />}
                                            onClick={() => {
                                                if (canAccessJournal) return handleSubmit(true);
                                                dispatch(showSubscriptionModal());
                                            }}>
                                            <Typography className="btn-text">
                                                {'Open Journal'}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box className="bottom-section">
                                    <Box className="flex f-v-center">
                                        <Box
                                            className={`dot  ${
                                                activeTab === TABs.MASCOT && 'active'
                                            }`}
                                        />
                                        <Box
                                            className={`dot  ${
                                                activeTab === TABs.CHIP && 'active'
                                            }`}
                                        />
                                    </Box>
                                    <Typography
                                        className="skip-text hover"
                                        onClick={() => {
                                            if (assessmentForProgram) return handleSubmit();
                                            navigate(-2);
                                        }}>
                                        Skip For Now
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PostAssessmentWrapper>
        </>
    );
};

export default PostAssessment;
