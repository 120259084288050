import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Button, Radio, RadioGroup } from '@mui/material';
import { updateUserInfo } from 'Redux/Auth/Actions';

import Questionnaire_result_cloud1 from 'Assets/images/Questionnaire/questionnaire-result-cloud-1.png';
import Questionnaire_result_cloud2 from 'Assets/images/Questionnaire/questionnaire-result-cloud-2.png';
import Questionnaire_result_cloud3 from 'Assets/images/Questionnaire/questionnaire-result-cloud-3.png';
import {
    CustomPaperComponent,
    CustomRadioCheckedIcon,
    CustomRadioIcon,
    CustomTextField,
    HearAboutUsContentWrapper,
    HearAboutUsDialog,
    QuestionnaireResultWrapper
} from './QuestionnaireResult.style';
import TaoCard from 'Components/common/TaoCard';
import { TaoButton } from 'Components/common/Button.style';
import Footer from 'Components/common/Footer/Footer';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { API_URL, URL_HOME_PAGE, URL_PROFILE, URL_HOW_TO_MEDITATE_CONTENT } from 'Helpers/Paths';

import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { ReactComponent as Earth } from 'Assets/images/Common/earth.svg';
import CancelIcon from 'Assets/images/Common/cancel-icon.svg';
import CalligraphyLogo from 'Assets/Logo/calligraphy-watermark.svg';
import TaoLoader from 'Components/common/TaoBackdropLoader';

import Api from 'Helpers/ApiHandler';
import { useCallback } from 'react';
import { PROFILE_TAB_TYPE, SLIDER_PER_PAGE, TAO_CARD_TYPE } from 'Helpers/Constants';
import Lottie from 'lottie-react';
import DollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotLevitatingOpensEyesAndDescends.json';
import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import TaoSlider from 'Components/common/TaoSlider';
import { useRef } from 'react';
import { getWindowDimensions, handleContentRedirection } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import {
    hideTopBarWithTransition,
    showSubscriptionModal,
    showToast,
    showTopBarWithTransition
} from 'Redux/App/Actions';
import TaoCardLoader from 'Components/common/TaoCard/TaoCardLoader';
import CODES from 'Helpers/StatusCodes';

const CustomRadioButton = (props) => {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent'
                }
            }}
            classes={{ root: 'custom-radio' }}
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
};

const HearAboutUs = ({
    handleClose,
    handleSelectionChange,
    selectedOption,
    hearAboutUsText,
    handleTextChange,
    hearAboutUsList,
    handleContinue
}) => {
    return (
        <HearAboutUsContentWrapper>
            <img src={CalligraphyLogo} alt="logo" className="calligraphy-logo" />

            <Box className="text-box">How Did You Hear About Us?</Box>
            <Box className="content-wrapper">
                <Box className="radio-button-wrapper">
                    <RadioGroup
                        className="radio-button-group"
                        value={selectedOption}
                        onChange={handleSelectionChange}>
                        {hearAboutUsList.map((option) => (
                            <Box key={option.keywordId} className="option">
                                <Box className="flex f-v-center">
                                    <CustomRadioButton value={option.keywordId} />
                                    <Typography className="radio-label">{option.name}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </RadioGroup>
                </Box>
                <CustomTextField
                    placeholder="Type here"
                    variant="outlined"
                    className="other-text-field"
                    value={hearAboutUsText}
                    onChange={handleTextChange}
                    InputProps={{
                        classes: { root: 'outlined-root', input: 'outlined-input' }
                    }}
                    disabled={
                        selectedOption !==
                        hearAboutUsList?.[hearAboutUsList.length - 1]?.keywordId.toString()
                    }
                />
            </Box>
            <Button className="continue-btn" onClick={handleContinue}>
                CONTINUE
            </Button>
        </HearAboutUsContentWrapper>
    );
};

const SliderUI = ({ items, boldText, text, sliderRef, isLoading, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    return (
        <Box className="container">
            <Typography className="sub-title">
                <span className="bold">{boldText} </span>
                {text}
            </Typography>
            {isLoading && (
                <TaoSlider
                    ref={sliderRef}
                    cardHeight={80}
                    gap={10}
                    slidesToShow={2}
                    prevArrowPercentage="-12%">
                    {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                        <TaoCardLoader key={index} />
                    ))}
                </TaoSlider>
            )}
            {!isLoading && (
                <TaoSlider
                    ref={sliderRef}
                    cardHeight={80}
                    gap={10}
                    slidesToShow={2}
                    prevArrowPercentage="-12%">
                    {items?.map((value, index) => (
                        <TaoCard
                            key={index}
                            backgroundImage={value?.thumbnailImage}
                            mediaTextType="primary"
                            type={
                                value.type?.toUpperCase() === TAO_CARD_TYPE.PROGRAM
                                    ? TAO_CARD_TYPE.PROGRAM
                                    : value?.contentType
                            }
                            title={value.title}
                            textFromStart
                            isFav={value?.isFavorite}
                            isPaidContent={value?.isPaidContent}
                            isPurchased={value?.isPurchased}
                            badge={value?.subscription?.badge}
                            isAccessible={
                                value?.subscription ? value?.subscription?.isAccessible : true
                            }
                            isFeatured={value?.isFeatured}
                            onClick={() => {
                                if (props?.redirectDirectlyToContent) {
                                    return navigate(URL_HOW_TO_MEDITATE_CONTENT, {
                                        state: {
                                            contentId: value?.contentId
                                        }
                                    });
                                }
                                handleContentRedirection(value, navigateTo);
                            }}
                        />
                    ))}
                </TaoSlider>
            )}
        </Box>
    );
};

const CompleteYourProfile = ({ items, boldText, text, sliderRef, ...props }) => {
    const navigate = useNavigate();

    return (
        <>
            <Box className="container">
                <Typography className="sub-title">
                    <span className="bold">{boldText} </span>
                    {text}
                </Typography>
                <Box className="earth-wrapper flex f-column f-v-center">
                    <Box className="earth-image-wrapper">
                        <Box className="earth-image">
                            <Earth />
                        </Box>
                    </Box>
                    <Box className="redirection-btn-wrapper">
                        <Button
                            className="redirection-btn"
                            onClick={() => {
                                navigate(URL_PROFILE, {
                                    state: { type: PROFILE_TAB_TYPE.VIEW_PROFILE }
                                });
                            }}>
                            GO TO MY PROFILE
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const QuestionnaireResult = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const [meditations, setMeditations] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [howToMeditate, setHowToMeditate] = useState([]);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHearAboutUsLoading, setIsHearAboutUsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [hearAboutUsText, setHearAboutUsText] = useState('');
    const sliderContentRef = useRef(null);
    const sliderProgramRef = useRef(null);
    const sliderMeditationRef = useRef(null);
    const API = useMemo(() => new Api(), []);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [hearAboutUsList, setHearAboutUsList] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectionChange = (event) => {
        if (hearAboutUsText) {
            setHearAboutUsText('');
        }

        setSelectedOption(event.target.value);
    };

    const handleTextChange = (event) => {
        setHearAboutUsText(event.target.value);
    };

    const handleContinue = async () => {
        if (selectedOption) {
            if (
                Number(selectedOption) ===
                    hearAboutUsList?.[hearAboutUsList.length - 1]?.keywordId &&
                !hearAboutUsText
            ) {
                dispatch(
                    showToast(
                        'Please provide other option from where did you hear about us.',
                        'error'
                    )
                );
                return;
            }

            setIsHearAboutUsLoading(true);

            let response = await API.post(API_URL.HEAR_ABOUT_US, {
                data: {
                    keywordId: Number(selectedOption),
                    text: hearAboutUsText
                }
            });

            setIsHearAboutUsLoading(false);

            if (response.status === CODES.CREATED) {
                dispatch(
                    updateUserInfo({
                        ...userDetails,
                        isHearAboutUsSubmitted: true
                    })
                );
            }
            handleClose();
        } else {
            dispatch(showToast('Please select option from where did you hear about us.', 'error'));
        }
    };

    const fetchHearAboutUsData = useCallback(async () => {
        setIsHearAboutUsLoading(true);

        let response = await API.get(API_URL.HEAR_ABOUT_US);

        if (response?.data?.data?.length) {
            setHearAboutUsList(response.data.data);
        }

        setIsHearAboutUsLoading(false);

        setOpen(true);
    }, [API]);

    const fetchQueResultData = useCallback(async () => {
        setIsLoading(true);
        const quePagination = {
            current_page: 0,
            per_page: SLIDER_PER_PAGE
        };
        let response = await API.post(API_URL.QUESTIONNAIRE_RESULT, {
            data: {
                ...quePagination,
                search: '',
                type: ''
            }
        });

        setMeditations(response?.data?.data?.meditations?.list);
        setPrograms(response?.data?.data?.programs?.list);
        setHowToMeditate(response?.data?.data?.howToMeditate?.list);
        setIsLoading(false);
    }, [API]);

    useEffect(() => {
        fetchQueResultData();

        dispatch(hideSideBar());

        return () => {
            dispatch(showSideBar());
        };
    }, [dispatch, fetchQueResultData]);

    useEffect(() => {
        if (!userDetails.isHearAboutUsSubmitted) {
            fetchHearAboutUsData();
        }
    }, [dispatch, fetchHearAboutUsData, userDetails.isHearAboutUsSubmitted]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET)
            dispatch(hideTopBarWithTransition());
        else dispatch(showTopBarWithTransition());

        return () => {
            dispatch(showTopBarWithTransition());
        };
    }, [dispatch, windowDimensions.width]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <TaoLoader isLoading={isHearAboutUsLoading} />
            <TaoCalligraphyWatermark />
            <QuestionnaireResultWrapper>
                <Box
                    className="cloud cloud1"
                    style={{ backgroundImage: `url(${Questionnaire_result_cloud1})` }}
                />
                <Box
                    className="cloud cloud2"
                    style={{ backgroundImage: `url(${Questionnaire_result_cloud2})` }}
                />
                <Box
                    className="cloud cloud3"
                    style={{ backgroundImage: `url(${Questionnaire_result_cloud3})` }}
                />
                <Box className="result-page-container">
                    <Box className="result-header-container page-item">
                        <Lottie
                            loop={loop}
                            autoplay
                            onComplete={() => {
                                setIsCompletedAnimation(true);
                                setLoop(true);
                            }}
                            animationData={
                                isCompletedAnimation ? StandingDollAnimation : DollAnimation
                            }
                            className="lottie-animation"
                        />
                        <Typography className="header-text">
                            {userDetails?.firstName}, ready to get started?
                        </Typography>
                    </Box>

                    <SliderUI
                        boldText="Practice free meditations"
                        text="and release what holds you back."
                        items={meditations}
                        sliderRef={sliderContentRef}
                        isLoading={isLoading}
                    />
                    <SliderUI
                        boldText="Join a program"
                        text="to focus and reach your goals faster."
                        items={programs}
                        sliderRef={sliderProgramRef}
                        isLoading={isLoading}
                    />
                    <SliderUI
                        boldText="Learn"
                        text="how transformative field meditations work."
                        items={howToMeditate}
                        sliderRef={sliderMeditationRef}
                        isLoading={isLoading}
                        redirectDirectlyToContent
                    />

                    {false && (
                        <CompleteYourProfile
                            boldText="Complete your profile"
                            text="to find live sessions in your region."
                        />
                    )}

                    <Box className="free-trial-btn-container">
                        <Box className="free-trial-btn">
                            <TaoButton
                                onClick={() => {
                                    navigate(URL_HOME_PAGE);
                                }}
                                variant="filled">
                                <Typography className="free-trial-text">Continue</Typography>
                            </TaoButton>
                        </Box>
                    </Box>
                </Box>
                <Footer topBorderColor="gray" isBottomZero />
            </QuestionnaireResultWrapper>
            <HearAboutUsDialog
                // PaperComponent={CustomPaperComponent}
                classes={{ paper: 'paper' }}
                open={open}
                sx={{ zIndex: 1000 }}>
                <HearAboutUs
                    handleClose={handleClose}
                    selectedOption={selectedOption}
                    handleSelectionChange={handleSelectionChange}
                    hearAboutUsText={hearAboutUsText}
                    handleTextChange={handleTextChange}
                    hearAboutUsList={hearAboutUsList}
                    handleContinue={handleContinue}
                />
            </HearAboutUsDialog>
        </>
    );
};

export default QuestionnaireResult;
