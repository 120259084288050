import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, DialogTitle, DialogContent, Typography, Button } from '@mui/material';
import { showToast } from 'Redux/App/Actions';
import TaoChip from 'Components/common/TaoChip/TaoChip';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import { DialogWrapper } from './Goals.style';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { cloneDeep } from 'lodash';
import TaoLoader from 'Components/common/TaoBackdropLoader';

const SCREEN_TYPE = {
    SCREEN_ONE: 'screenOne',
    SCREEN_TWO: 'screenTwo'
};

const BUTTON = {
    screenOne: {
        title: 'Next',
        button: 'next-btn'
    },
    screenTwo: {
        title: 'Save',
        button: 'save-btn'
    }
};

const GoalSelection = ({
    openSelection,
    setOpenSelection,
    resetLeftMenuHandler,
    getUpdatedGoalData
}) => {
    const [screenType, setScreenType] = useState(SCREEN_TYPE?.SCREEN_ONE);
    const [goals, setGoals] = useState({
        screenOne: [],
        prevScreenOne: [],
        screenTwo: [],
        prevScreenTwo: []
    });
    const [isLoading, setIsLoading] = useState(false);

    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();

    const getScreenOneData = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.GOALS_SCREEN_1);

        if (response && response?.data?.data?.list) {
            setGoals((prev) => ({
                ...prev,
                screenOne: response?.data?.data?.list || [],
                prevScreenOne: response?.data?.data?.list || []
            }));
        }
        setIsLoading(false);
    }, [API]);

    const getScreenTwoData = async () => {
        setIsLoading(true);

        let response = await API.get(API_URL.GOALS_SCREEN_2);

        if (response) {
            setGoals((prev) => ({
                ...prev,
                screenTwo: response?.data?.data?.list || [],
                prevScreenTwo: response?.data?.data?.list || []
            }));
        }
        setIsLoading(false);
    };

    const selectChip = (chipId, index = 0) => {
        if (!chipId) return;

        if (screenType === SCREEN_TYPE?.SCREEN_ONE) {
            let copyScreenOneGoals = cloneDeep(goals?.screenOne);
            copyScreenOneGoals[index].isSelected = !copyScreenOneGoals[index].isSelected;

            setGoals((prev) => ({
                ...prev,
                screenOne: copyScreenOneGoals || [],
                prevScreenOne: prev?.prevScreenOne || []
            }));
        }

        if (screenType === SCREEN_TYPE?.SCREEN_TWO) {
            let copyScreenTwoGoals = cloneDeep(goals?.screenTwo);
            copyScreenTwoGoals[index].isSelected = !copyScreenTwoGoals[index].isSelected;

            setGoals((prev) => ({
                ...prev,
                screenTwo: copyScreenTwoGoals || [],
                prevScreenTwo: prev?.prevScreenTwo || []
            }));
        }
    };

    const submitScreenSelection = async (
        screensChips,
        screenNo,
        nextScreen = null,
        currentScreen = null,
        callback
    ) => {
        setIsLoading(true);

        let keywordIds = [];
        let deletedKeywordIds = [];
        let checkEmptyKeyword = [];
        let copyPrevScreen = screensChips;

        if (copyPrevScreen) {
            goals?.[currentScreen]?.forEach((goal, index) => {
                if (goal.isSelected) checkEmptyKeyword.push(goal.keywordId);

                if (goal.isSelected && !copyPrevScreen?.[index]?.isSelected) {
                    keywordIds.push(goal?.keywordId);
                }

                if (!goal?.isSelected && copyPrevScreen?.[index]?.isSelected) {
                    deletedKeywordIds.push(goal?.keywordId);
                }
            });

            if (!checkEmptyKeyword.length) {
                dispatch(showToast('Please select at least one goal', 'info'));
                setIsLoading(false);
                return;
            }

            let data = {
                keywordIds: keywordIds,
                deletedKeywordIds: deletedKeywordIds,
                screen: screenNo,
                dailyMeditationTarget: 0
            };

            if (!data?.keywordIds?.length && !data?.deletedKeywordIds?.length) {
                setIsLoading(false);
                nextScreen && setScreenType(nextScreen);
                callback && callback();
                if (nextScreen === SCREEN_TYPE.SCREEN_ONE) {
                    setOpenSelection(false);
                }
                return;
            }

            let response = await API.put(API_URL.USER_GOALS, {
                data
            });

            if (response) {
                setIsLoading(false);
                nextScreen && setScreenType(nextScreen);
                callback && callback();
                if (nextScreen === SCREEN_TYPE.SCREEN_ONE) {
                    setOpenSelection(false);
                    resetLeftMenuHandler();
                    getUpdatedGoalData(true);
                    dispatch(showToast('Successfully updated your goals', 'success'));
                }
            }
        }
    };

    const handleNextButton = async () => {
        submitScreenSelection(
            cloneDeep(goals?.prevScreenOne),
            1,
            SCREEN_TYPE?.SCREEN_TWO,
            screenType,
            getScreenTwoData
        );
    };

    const handleSaveButton = async () => {
        submitScreenSelection(
            cloneDeep(goals?.prevScreenTwo),
            2,
            SCREEN_TYPE?.SCREEN_ONE,
            screenType
        );
    };

    useEffect(() => {
        getScreenOneData();
    }, [getScreenOneData]);

    return (
        <>
            <DialogWrapper
                classes={{ paper: 'dialog-wrapper' }}
                open={openSelection}
                onClose={() => {
                    setScreenType(SCREEN_TYPE?.SCREEN_ONE);
                    setOpenSelection(false);
                }}
                $screenType={screenType}>
                <>
                    <TaoLoader isLoading={isLoading} />
                    <DialogTitle className="flex f-v-center f-h-space-between">
                        <Typography className="dialog-title">What Brings You Here?</Typography>
                        <CloseIcon
                            className="hover"
                            onClick={() => {
                                setScreenType(SCREEN_TYPE?.SCREEN_ONE);
                                setOpenSelection(false);
                            }}
                        />
                    </DialogTitle>
                    <DialogContent className="flex f-wrap">
                        {goals?.[screenType]?.map((data, index) => (
                            <TaoChip
                                index={index}
                                title={data.name}
                                key={data.keywordId}
                                id={data.keywordId}
                                selected={data.isSelected}
                                isDisabled={false}
                                selectChip={selectChip}
                            />
                        ))}
                    </DialogContent>
                    <Box className="flex f-h-center">
                        <Button
                            className={`dialog-btn ${BUTTON?.[screenType]?.button}`}
                            onClick={() =>
                                screenType === SCREEN_TYPE?.SCREEN_ONE
                                    ? handleNextButton()
                                    : handleSaveButton()
                            }>
                            <Typography className="btn-text">{`${BUTTON?.[screenType]?.title}`}</Typography>
                        </Button>
                    </Box>
                </>
            </DialogWrapper>
        </>
    );
};

export default GoalSelection;
