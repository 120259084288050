import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Slider, Box, Typography, Button, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import {
    handleTopBarInteractionDetail,
    hideTopBar,
    hideTopBarInteractionButton,
    showTopBar
} from 'Redux/App/Actions';
import {
    MeditationMediaPlayerWrapper,
    PlaybackSpeedPopOverWrapper,
    PopOverWrapper
} from './MeditationMediaPlayer.style';
import { ReactComponent as PreviousSVG } from 'Assets/images/MeditationContent/video-backward.svg';
import { ReactComponent as ForwardSVG } from 'Assets/images/MeditationContent/video-forward.svg';
import { ReactComponent as PauseSVG } from 'Assets/images/MeditationContent/player-pause.svg';
import { ReactComponent as PlaySVG } from 'Assets/images/MeditationContent/player-play.svg';
import { convertHrsMinToSeconds, getTotalTimeInMins, getWindowDimensions } from 'Helpers';
import { ReactComponent as Subtitle } from 'Assets/images/MeditationContent/player-caption.svg';
import { ReactComponent as SettingIcon } from 'Assets/images/MeditationContent/settings.svg';
import { ReactComponent as SelectedSubTitle } from 'Assets/images/FieldHealing/subtitle-selected.svg';
import { ReactComponent as FullScreenSVG } from 'Assets/images/MeditationContent/player-fullscreen.svg';
import { ReactComponent as FullSound } from 'Assets/images/MeditationContent/player-sound.svg';
import { ReactComponent as MuteSound } from 'Assets/images/FieldHealing/mute-sound.svg';
import { ReactComponent as DecreaseSound } from 'Assets/images/FieldHealing/decrease-sound.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import RepeatSVG from '../RepeatSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import TaoScrollbar from 'Components/common/Scrollbar';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_POST_ASSESSMENT } from 'Helpers/Paths';
import { MEDIA_TYPE } from 'Helpers/Constants';
import { NotificationStyleWrapper } from 'Components/Pages/MeditationTimer/MeditationTimer.style';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import playerjs from 'player.js';
import Marquee from 'react-fast-marquee';
import WebVTT from 'node-webvtt'; // Library to parse WebVTT files
import ContentLoader from 'react-content-loader';

const SPEED_ADJUSTMENT_VALUEs = [
    { title: '0.5', value: 0.5 },
    { title: '0.75', value: 0.75 },
    { title: '1.0', value: 1 },
    { title: '1.5', value: 1.5 },
    { title: '2', value: 2 }
];
const SPEED_FORWARD = 'SPEED_FORWARD';
const SPEED_BACKWARD = 'SPEED_BACKWARD';

let SEEK_SECONDS = 10;
// const EN_AUTOGENERATED = ['en-x-autogen', 'en-x-autogenerated'];

const MeditationMediaPlayer = ({
    propsContentId,
    playOver,
    isInitialExperience = false,
    setTextData = null,
    apiUrl = '',
    contentParamsType = null,
    setContentPlayingOnFullScreen = null,
    setDetails = null,
    ...props
}) => {
    const playerRef = useRef(null);
    const iframeRef = useRef(null);
    const [mediaType, setMediaType] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [mediaPlay, setMediaPlay] = useState(false);
    const [duration, setDuration] = useState('00:00');
    const [elapsedTime, setElapsedTime] = useState('00:00');
    const [chaptersTime, setChaptersTime] = useState([]);
    const [mediaLengthInSeconds, setMediaLengthInSeconds] = useState(0);
    const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);
    const [trackLang, setTrackLang] = useState('');
    const [trackFile, setTrackFile] = useState(null);
    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState('');
    const [tracks, setTracks] = useState([]);
    const [selectedStep, setSelectedStep] = useState(0);
    const [isMediaFullScreen, setIsMediaFullScreen] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [link, setLink] = useState('');
    const [controlsVisible, setControlsVisible] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [contentDetails, setContentDetails] = useState({});
    const API = useMemo(() => new Api(), []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [playbackAnchorEl, setPlaybackAnchorEl] = useState(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const elapsedTimeRef = useRef();
    const [scrollChapterLeft, setChapterScrollLeft] = useState(0);
    const playedSeconds = useRef(0);
    const [soundControlOpen, setSoundControlOpen] = useState(false);
    const [volume, setVolume] = useState(100);
    const [mutedSoundValue, setMutedSoundValue] = useState(100);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const [showZenModePopup, setZenModePopup] = useState(true);
    const marqueeContent = useRef(null);
    const [videoEnded, setVideoEnded] = useState(false);
    const marqueeDiv = useRef(null);
    const [bunnyPlayerReady, setBunnyPlayerReady] = useState(false);
    const [loop, setLoop] = useState(
        marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1
    );

    const Loader = ({ isLoading, extraStyle, boxClass, boxStyle, ...props }) => {
        return (
            <Box
                className={boxClass}
                sx={{ position: 'absolute', zIndex: isLoading ? 10 : 0, ...boxStyle }}>
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    backgroundColor="#dfdfdf"
                    foregroundColor="#ecebeb"
                    preserveAspectRatio="none"
                    style={{ ...extraStyle }}
                    {...props}>
                    <rect
                        x="0"
                        y="0"
                        style={{ borderRadius: '8px' }}
                        rx="18"
                        ry="0"
                        width="100%"
                        height="100%"
                    />
                </ContentLoader>
            </Box>
        );
    };

    const toggleFullScreen = () => {
        if (
            (document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                /* Firefox */
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (document.msRequestFullscreen) {
                /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                /* Chrome, Safari and Opera */
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    };

    const onSelectStep = useCallback(
        (itemDetails) => {
            if (!itemDetails?.chapterTimeStamp) return;
            let seconds = convertHrsMinToSeconds(itemDetails?.chapterTimeStamp);
            if (seconds > mediaLengthInSeconds) return;
            setElapsedTime(getTotalTimeInMins(seconds));
            setElapsedTimeInSeconds(seconds);
            if (mediaType === MEDIA_TYPE.VIDEO) playerRef.current.setCurrentTime(seconds);
            else playerRef.current.currentTime = seconds;
            setSelectedStep(itemDetails?.chapterId);
        },
        [mediaLengthInSeconds, mediaType]
    );

    const showSnackbar = useCallback(() => {
        if (!userDetails?.isSilenceDuringMeditation) return;

        const notificationData = (
            <NotificationStyleWrapper className="flex f-v-center">
                <Box className="logo" />
                <Box className="flex f-column">
                    <Typography className="text primary">Zen Mode: ON</Typography>
                    <Typography className="text secondary">
                        We’ll turn off our notifications
                    </Typography>
                </Box>
                <Button
                    className="btn"
                    onClick={() => {
                        setZenModePopup(false);
                        closeSnackbar();
                    }}>
                    DON’T SHOW AGAIN
                </Button>
                <IconButton
                    className="close-icon"
                    onClick={() => {
                        closeSnackbar();
                    }}>
                    <CloseIcon />
                </IconButton>
            </NotificationStyleWrapper>
        );
        showZenModePopup && enqueueSnackbar(notificationData, { autoHideDuration: 5000 });
    }, [closeSnackbar, userDetails?.isSilenceDuringMeditation, enqueueSnackbar, showZenModePopup]);

    const getBunnyNetCurrentTime = async () => {
        const response = await new Promise((resolve, reject) => {
            playerRef?.current?.getCurrentTime?.((value) => resolve(value));
        });
        return response;
    };

    const fetchContentById = useCallback(async () => {
        if (
            !location?.state?.contentId &&
            !propsContentId &&
            !isInitialExperience &&
            !location?.state?.isRedirectedFromInitialExperience
        )
            return;

        setMediaType(null);
        setElapsedTime('00:00');
        setElapsedTimeInSeconds(0);
        setIsLoading(true);

        let response = location?.state?.isRedirectedFromInitialExperience
            ? { data: { data: location?.state } }
            : await API.get(isInitialExperience ? apiUrl : API_URL.CONTENT_LIST, {
                  params: {
                      contentId: location?.state?.contentId || propsContentId,
                      type: contentParamsType
                  }
              });
        if (response) {
            setContentDetails(response?.data?.data);
            if (setDetails) setDetails(response?.data?.data);
            dispatch(handleTopBarInteractionDetail(response?.data?.data || {}));
            setChaptersTime(response?.data?.data?.chapters);
            const {
                data: {
                    data: { type }
                }
            } = response;
            if (setTextData) {
                setTextData?.({ description: response?.data?.data?.description });
            }
            if (
                type === MEDIA_TYPE.GUIDED_AUDIO ||
                type === MEDIA_TYPE.AUDIO ||
                type === MEDIA_TYPE.MUSIC
            ) {
                setMediaType(MEDIA_TYPE.AUDIO);
                setLink(response?.data?.data?.contentFile);
                setTracks(response?.data?.data?.subtitleWithLanguages);
            }

            if (type === MEDIA_TYPE.VIDEO) {
                const iframe = document.getElementById('bunny-player');
                iframe.src = response?.data?.data?.contentFileForIFrame;

                const player = new playerjs.Player('bunny-player');

                playerRef.current = player;
                player.on('ready', () => {
                    setElapsedTimeInSeconds(0);
                    setBunnyPlayerReady(true);
                    player.on('play', () => {
                        setIsLoading(false);
                        setMediaPlay(true);
                        setMediaType(MEDIA_TYPE.VIDEO);
                        setLink(response?.data?.data?.contentFile);
                        player.getDuration((duration) => {
                            setDuration(getTotalTimeInMins(duration));
                            setMediaLengthInSeconds(parseInt(duration));
                        });
                    });
                    player.on('pause', () => {
                        setMediaPlay(false);
                    });
                    player.on('ended', () => {
                        setVideoEnded(true);
                    });
                });
            }
            setIsLoading(false);
        }
    }, [
        contentParamsType,
        API,
        // location?.state?.contentId,
        // location?.state?.isRedirectedFromInitialExperience,
        propsContentId,
        isInitialExperience,
        dispatch,
        apiUrl,
        setTextData,
        setDetails,
        location?.state
    ]);

    const selectTextTracks = async (item) => {
        setAnchorEl(null);
        if (trackLang && item?.languageName === trackLang) {
            setTrackLang('');
            setTrackFile(null);
            setSubtitles([]);
            setCurrentSubtitle('');
            return;
        }
        setTrackLang(item?.languageName);
        setTrackFile(item?.subTitleFile);

        fetch(item?.subTitleFile)
            .then((response) => response.text())
            .then((data) => {
                const parsedVTT = WebVTT?.parse(data);
                setSubtitles(parsedVTT.cues);
            });
    };

    const previous = async () => {
        let currentTime =
            mediaType === MEDIA_TYPE.AUDIO
                ? playerRef.current.currentTime
                : await getBunnyNetCurrentTime();

        if (mediaType === MEDIA_TYPE.AUDIO) {
            playerRef.current.currentTime = currentTime - SEEK_SECONDS;
        } else {
            currentTime > SEEK_SECONDS &&
                playerRef.current.setCurrentTime(currentTime - SEEK_SECONDS);
        }
        !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
        playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    };

    const forward = async () => {
        let currentTime =
            mediaType === MEDIA_TYPE.AUDIO
                ? playerRef.current.currentTime
                : await getBunnyNetCurrentTime();

        if (mediaType === MEDIA_TYPE.AUDIO) {
            playerRef.current.currentTime = currentTime + SEEK_SECONDS;
        } else {
            playerRef.current.setCurrentTime(currentTime + SEEK_SECONDS);
        }
        !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
        playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    };

    const updatePlayTime = useCallback(
        async (time) => {
            if (isInitialExperience || location?.state?.isRedirectedFromInitialExperience) return;

            if (!location?.state?.programContentId) {
                await API.post(API_URL.CONTENT_PLAY_TIME, {
                    params: {
                        contentId: location?.state?.contentId || propsContentId,
                        playTime: getTotalTimeInMins(time, true)
                    }
                });
                return;
            }
            await API.post(API_URL.PROGRAM_CONTENT_PLAY_TIME, {
                params: {
                    programContentId: location?.state?.programContentId,
                    playTime: getTotalTimeInMins(time, true)
                }
            });
        },
        [
            API,
            location?.state?.programContentId,
            location?.state?.isRedirectedFromInitialExperience,
            location?.state?.contentId,
            propsContentId,
            isInitialExperience
        ]
    );

    const unMuteSoundWithPreviousEnable = async () => {
        setMutedSoundValue(volume);
        setVolume(0);
        if (mediaType !== MEDIA_TYPE.AUDIO) playerRef.current.setVolume(0);
        else playerRef.current.volume = 0;
    };

    const escFunction = useCallback(() => {
        setContentPlayingOnFullScreen?.(!isMediaFullScreen);
        setIsMediaFullScreen(!isMediaFullScreen);
    }, [isMediaFullScreen, setContentPlayingOnFullScreen]);

    const adjustSpeedControl = async (type) => {
        let currentSpeedIndex = SPEED_ADJUSTMENT_VALUEs.findIndex(
            (item) => item?.value === playbackSpeed
        );

        if (type === SPEED_BACKWARD && currentSpeedIndex) {
            setPlaybackSpeed(SPEED_ADJUSTMENT_VALUEs[currentSpeedIndex - 1]?.value);
            return;
        }
        if (type === SPEED_FORWARD && currentSpeedIndex < SPEED_ADJUSTMENT_VALUEs?.length - 1) {
            setPlaybackSpeed(SPEED_ADJUSTMENT_VALUEs[currentSpeedIndex + 1]?.value);
            return;
        }
    };

    const handleTimeUpdate = () => {
        if (playerRef.current && subtitles?.length && trackFile) {
            const currentTime = playerRef.current.currentTime;
            const currentCue = subtitles.find(
                (cue) => currentTime >= cue?.start && currentTime <= cue?.end
            );
            setCurrentSubtitle(currentCue ? currentCue.text : '');
        }
    };

    const redirectionAfterMediaEnd = useCallback(() => {
        if (repeat || isInitialExperience) return;
        if (propsContentId) {
            elapsedTimeRef.current = 0;
            setElapsedTimeInSeconds(0);
            setVideoEnded(false);
            if (isMediaFullScreen) toggleFullScreen();
            if (playOver) {
                playOver();
                setBunnyPlayerReady(false);
            }
            return;
        }
        setMediaPlay(false);
        if (
            (contentDetails?.isPostAssessmentCompleted && contentDetails?.isAssessmentDone) ||
            contentDetails?.isInstructional
        )
            return navigate(-1);
        navigate(URL_POST_ASSESSMENT, {
            state: {
                contentId: location?.state?.contentId || location?.state?.programContentId
            }
        });
    }, [
        contentDetails?.isAssessmentDone,
        contentDetails?.isInstructional,
        contentDetails?.isPostAssessmentCompleted,
        location?.state?.contentId,
        location?.state?.programContentId,
        navigate,
        playOver,
        propsContentId,
        repeat,
        isInitialExperience,
        isMediaFullScreen
    ]);

    useEffect(() => {
        if (mediaPlay) showSnackbar();
    }, [mediaPlay, showSnackbar]);

    useEffect(() => {
        elapsedTimeRef.current = elapsedTimeInSeconds;

        chaptersTime?.length &&
            chaptersTime?.forEach((item) => {
                let seconds = convertHrsMinToSeconds(item?.chapterTimeStamp);
                if (parseInt(seconds) <= parseInt(elapsedTimeInSeconds)) {
                    setSelectedStep(item?.chapterId);
                }
            });

        let myElement = document.getElementById('chapter-selected-scroll');
        if (myElement) {
            let menus = document.getElementsByClassName('step');
            for (let i = 0; i < menus.length; i++) {
                if (menus[i].classList.contains('selected-step')) {
                    setChapterScrollLeft(menus[i].offsetLeft);
                }
            }
        }

        if (mediaType === MEDIA_TYPE.VIDEO) return;

        if (
            parseInt(mediaLengthInSeconds) > 0 &&
            parseInt(elapsedTimeRef.current) === parseInt(mediaLengthInSeconds) &&
            repeat
        ) {
            let introExists = contentDetails?.chapters?.findIndex(
                (item) => item?.chapterType === 'INTRO'
            );
            if (introExists > -1) onSelectStep(contentDetails?.chapters[1]);
            else onSelectStep(contentDetails.chapters[0]);
        }
        if (
            parseInt(mediaLengthInSeconds) > 0 &&
            parseInt(elapsedTimeRef.current) === parseInt(mediaLengthInSeconds) &&
            !repeat
        ) {
            redirectionAfterMediaEnd();
        }
    }, [
        elapsedTimeInSeconds,
        mediaLengthInSeconds,
        chaptersTime,
        repeat,
        contentDetails?.chapters,
        onSelectStep,
        redirectionAfterMediaEnd,
        mediaType
    ]);

    useEffect(() => {
        const setSpeed = async () => {
            playerRef.current.playbackRate = playbackSpeed;
        };
        if (playerRef?.current) setSpeed();
    }, [playbackSpeed, mediaType, propsContentId]);

    useEffect(() => {
        fetchContentById();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fetchContentById]);

    useEffect(() => {
        dispatch(hideSideBar());
        return () => {
            dispatch(showSideBar());
            dispatch(handleTopBarInteractionDetail({}));
            dispatch(hideTopBarInteractionButton(false));
            updatePlayTime(playedSeconds.current);
        };
    }, [updatePlayTime, dispatch]);

    useEffect(() => {
        var interval = 0;
        function timer() {
            interval = setInterval(async () => {
                if (!mediaPlay) return;
                let currentTime =
                    mediaType === MEDIA_TYPE.AUDIO
                        ? playerRef?.current?.currentTime
                        : await getBunnyNetCurrentTime();

                if (!currentTime) return;
                let time = getTotalTimeInMins(currentTime);
                setElapsedTimeInSeconds(parseInt(currentTime));
                time && setElapsedTime(time);
                if (mediaPlay) {
                    let playedTime = playbackSpeed;
                    if (playedTime < 1) playedTime = playbackSpeed * 2;
                    if (playedTime > 1) playedTime = 1 / playbackSpeed;
                    playedSeconds.current = playedSeconds.current + playedTime;
                }
            }, 1000);
        }
        if (playerRef.current) timer();
        return () => clearInterval(interval);
    }, [mediaPlay, mediaType, playbackSpeed]);

    // HIDE CONTROLS ON FULL SCREEN FOR VIDEO
    useEffect(() => {
        let timer = null;
        if (!mediaType) return;

        const handleMouseMove = () => {
            if (!mediaPlay) return setControlsVisible(true);
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                setControlsVisible(false);
                setSoundControlOpen(false);
                setPlaybackAnchorEl(null);
            }, 3000);
            setControlsVisible(true);
        };

        window.addEventListener('mousemove', handleMouseMove, true);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove, true);
            if (timer) clearTimeout(timer);
        };
    }, [mediaType, mediaPlay]);

    useEffect(() => {
        if (isMediaFullScreen) dispatch(hideTopBar());
        else dispatch(showTopBar());
    }, [isMediaFullScreen, dispatch]);

    useEffect(() => {
        setLoop(marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1);
    }, [marqueeContent.current?.clientWidth, marqueeDiv.current?.clientWidth]);

    useEffect(() => {
        document.addEventListener('webkitfullscreenchange', escFunction, false);
        document.addEventListener('mozfullscreenchange', escFunction, false);
        document.addEventListener('fullscreenchange', escFunction, false);
        document.addEventListener('MSFullscreenChange', escFunction, false);
        return () => {
            document.removeEventListener('webkitfullscreenchange', escFunction, false);
            document.removeEventListener('mozfullscreenchange', escFunction, false);
            document.removeEventListener('fullscreenchange', escFunction, false);
            document.removeEventListener('MSFullscreenChange', escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (videoEnded) redirectionAfterMediaEnd();
    }, [videoEnded, redirectionAfterMediaEnd]);

    const open = Boolean(anchorEl);
    const id = open ? 'caption-popover' : undefined;

    const playbackOpen = Boolean(playbackAnchorEl);
    const playbackId = playbackOpen ? 'playback-popover' : undefined;

    return (
        <MeditationMediaPlayerWrapper
            screen={windowDimensions}
            $isLoading={isLoading}
            $isMediaFullScreen={isMediaFullScreen}
            $isInitialExperience={isInitialExperience}
            sx={{
                height:
                    propsContentId || isInitialExperience
                        ? isMediaFullScreen
                            ? '100vh'
                            : '100%'
                        : '100vh',
                minHeight: !isInitialExperience && '80vh'
            }}>
            {!isInitialExperience && (
                <Box
                    className={`image-background ${
                        document.fullscreenElement && 'full-screen-mode'
                    }`}
                    style={{
                        backgroundImage:
                            !document.fullscreenElement &&
                            `url(${contentDetails?.backgroundImageWeb})`
                    }}
                />
            )}
            {!isInitialExperience && (
                <Box
                    className={`white-background ${
                        document.fullscreenElement && 'full-screen-mode'
                    }`}
                />
            )}
            <Box
                className={`video-player-content ${
                    document.fullscreenElement && 'full-screen-mode'
                }`}
                sx={{ padding: propsContentId || isInitialExperience ? '60px 0 0' : '100px 0' }}>
                {isInitialExperience && !document.fullscreenElement && (
                    <Box className="full-width" ref={marqueeDiv}>
                        {isLoading ? (
                            <Box sx={{ height: '50px' }}>
                                <Loader
                                    isLoading={isLoading}
                                    extraStyle={{ borderRadius: '12px' }}
                                    boxClass={'full-width loader'}
                                    boxStyle={{ height: '40px' }}
                                />
                            </Box>
                        ) : (
                            <Marquee gradient={false} speed={60} delay={2} loop={loop}>
                                <Typography className="title" ref={marqueeContent}>
                                    {contentDetails?.title}
                                </Typography>
                            </Marquee>
                        )}
                    </Box>
                )}
                <div
                    className={`full-width ${document.fullscreenElement && 'full-height'}`}
                    style={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0px 9px 18px 0px #00000026'
                    }}>
                    <Box className="player flex f-h-center full-width">
                        <Box
                            className="player-width"
                            position={document?.fullscreenElement ? 'initial' : 'relative'}>
                            {mediaType === MEDIA_TYPE.AUDIO ? (
                                <Box className="audio-player-content">
                                    <Box
                                        className={`audio-image-container ${
                                            document?.fullscreenElement && 'remove-border'
                                        }`}>
                                        <Box
                                            sx={{
                                                backgroundImage: `url(${contentDetails?.contentImage})`
                                            }}
                                            className={`audio-image ${
                                                document?.fullscreenElement && 'full-audio-height'
                                            } full-width`}
                                        />

                                        <div style={{ display: 'none' }}>
                                            <audio
                                                ref={playerRef}
                                                loop={repeat}
                                                autoPlay
                                                onTimeUpdate={handleTimeUpdate}
                                                onDurationChange={() => {
                                                    let audioPromise = playerRef?.current?.play();

                                                    if (audioPromise !== undefined) {
                                                        audioPromise
                                                            .then((_) => {
                                                                setMediaPlay(true);
                                                            })
                                                            .catch((err) => {
                                                                setMediaPlay(false);
                                                            });
                                                    }

                                                    if (playerRef?.current) {
                                                        if (playerRef?.current?.muted)
                                                            playerRef.current.muted = false;
                                                        setDuration(
                                                            getTotalTimeInMins(
                                                                playerRef.current.duration
                                                            )
                                                        );
                                                        setMediaLengthInSeconds(
                                                            parseInt(playerRef.current.duration)
                                                        );
                                                    }
                                                }}
                                                controls>
                                                <source src={link} type="audio/mp3" />
                                            </audio>
                                        </div>
                                        <div className="subtitle">{currentSubtitle}</div>
                                        <Box
                                            className={`middle-action-icon ${
                                                !controlsVisible && 'invisible'
                                            }`}>
                                            {!mediaPlay ? (
                                                <PlaySVG
                                                    className="player-middle-play-pause-icon hover"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        playerRef?.current?.play();
                                                        setMediaPlay((prevState) => !prevState);
                                                    }}
                                                />
                                            ) : (
                                                <PauseSVG
                                                    className="player-middle-play-pause-icon hover"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        playerRef?.current?.pause();
                                                        setMediaPlay((prevState) => !prevState);
                                                        updatePlayTime(playedSeconds.current);
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className="audio-controls">
                                        <Typography className="audio-image-text elipsis">
                                            {contentDetails?.imageCaption}
                                        </Typography>
                                        {controlsVisible && <Box className="bottom-shadow" />}
                                        <Slider
                                            value={elapsedTimeInSeconds}
                                            max={mediaLengthInSeconds}
                                            classes={{
                                                root: `slider-root ${
                                                    !controlsVisible && 'invisible'
                                                }`,
                                                track: 'slider-track',
                                                rail: 'slider-rail',
                                                thumb: 'slider-thumb'
                                            }}
                                            aria-label="Default"
                                            valueLabelDisplay="off"
                                            onChange={async (e, value) => {
                                                if (mediaType === MEDIA_TYPE.AUDIO) {
                                                    playerRef.current.currentTime = value;
                                                } else {
                                                    playerRef?.current &&
                                                        playerRef.current.setCurrentTime(value);
                                                }
                                                setElapsedTimeInSeconds(value);
                                            }}
                                        />

                                        <Box
                                            className={`left-icons flex f-v-center ${
                                                !controlsVisible && 'invisible'
                                            }`}>
                                            {!mediaPlay ? (
                                                <PlaySVG
                                                    className="play-pause-icon hover"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        playerRef?.current?.play();
                                                        setMediaPlay((prevState) => !prevState);
                                                    }}
                                                />
                                            ) : (
                                                <PauseSVG
                                                    className="play-pause-icon hover"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        playerRef?.current?.pause();
                                                        setMediaPlay((prevState) => !prevState);
                                                        updatePlayTime(playedSeconds.current);
                                                    }}
                                                />
                                            )}

                                            <Box className="flex f-v-center" gap={0.5}>
                                                <PreviousSVG
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        previous();
                                                    }}
                                                    className="music-backward"
                                                />
                                                <ForwardSVG
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        forward();
                                                    }}
                                                    className="music-forward"
                                                />
                                            </Box>
                                            <Box className="flex">
                                                <Typography className="time-span">
                                                    {elapsedTime}
                                                </Typography>
                                                <Typography className="time-span separator">
                                                    /
                                                </Typography>
                                                <Typography className="time-span">
                                                    {duration}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            className={`right-end ${
                                                !controlsVisible && 'invisible'
                                            }`}>
                                            <Box className="flex f-v-center" style={{ gap: '5px' }}>
                                                {volume > 50 && (
                                                    <FullSound
                                                        className="sound-control-icon hover"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (soundControlOpen)
                                                                unMuteSoundWithPreviousEnable();
                                                        }}
                                                    />
                                                )}
                                                {volume <= 50 && volume >= 1 && (
                                                    <DecreaseSound
                                                        className="sound-control-icon hover"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (soundControlOpen)
                                                                unMuteSoundWithPreviousEnable();
                                                        }}
                                                    />
                                                )}
                                                {volume < 1 && (
                                                    <MuteSound
                                                        className="sound-control-icon hover"
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            if (!soundControlOpen) return;
                                                            setVolume(mutedSoundValue);
                                                            playerRef.current.volume =
                                                                mutedSoundValue / 100;
                                                        }}
                                                    />
                                                )}
                                                <Slider
                                                    value={volume}
                                                    max={100}
                                                    min={0}
                                                    orientation="horizontal"
                                                    classes={{
                                                        root: 'volume-slider-root',
                                                        track: 'volume-slider-track',
                                                        rail: 'volume-slider-rail',
                                                        thumb: 'volume-slider-thumb'
                                                    }}
                                                    aria-label="Default"
                                                    valueLabelDisplay="off"
                                                    onChange={async (e, value) => {
                                                        e.stopPropagation();
                                                        setVolume(value);
                                                        playerRef.current.volume = value / 100;
                                                    }}
                                                />
                                            </Box>
                                            <React.Fragment>
                                                {!!tracks?.length &&
                                                    (!trackLang ? (
                                                        <Subtitle
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                if (tracks?.length === 1) {
                                                                    return selectTextTracks(
                                                                        tracks?.[0]
                                                                    );
                                                                }
                                                                setAnchorEl(event.currentTarget);
                                                            }}
                                                            className="hover"
                                                            aria-describedby={id}
                                                        />
                                                    ) : (
                                                        <SelectedSubTitle
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                if (tracks?.length === 1) {
                                                                    return selectTextTracks(
                                                                        tracks?.[0]
                                                                    );
                                                                }
                                                                setAnchorEl(event.currentTarget);
                                                            }}
                                                            className="hover"
                                                            aria-describedby={id}
                                                        />
                                                    ))}
                                                <PopOverWrapper
                                                    id={id}
                                                    open={open}
                                                    // container={
                                                    //     document?.fullscreenElement ? ref.current : null
                                                    // }
                                                    anchorEl={anchorEl}
                                                    onClose={() => setAnchorEl(null)}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                                    }}>
                                                    {tracks.map((item, index) => (
                                                        <Typography
                                                            onClick={() => selectTextTracks(item)}
                                                            key={index}
                                                            className={`captions-text-root ${
                                                                item.languageName === trackLang &&
                                                                'selected'
                                                            } hover`}>
                                                            {item?.languageName}
                                                        </Typography>
                                                    ))}
                                                </PopOverWrapper>
                                            </React.Fragment>
                                            <SettingIcon
                                                className={`setting-icon ${
                                                    playbackId && 'active'
                                                } hover`}
                                                aria-describedby={playbackId}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setPlaybackAnchorEl(event.currentTarget);
                                                }}
                                            />
                                            <PlaybackSpeedPopOverWrapper
                                                id={playbackId}
                                                open={playbackOpen}
                                                classes={{
                                                    paper: 'root'
                                                }}
                                                anchorEl={playbackAnchorEl}
                                                onClose={(e) => {
                                                    e.stopPropagation();
                                                    setPlaybackAnchorEl(null);
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}>
                                                <Box className="playback-box">
                                                    <Box className="flex">
                                                        <Typography className="adjust-speed-title">
                                                            Adjust Speed
                                                        </Typography>
                                                        <CloseIcon
                                                            className="hover"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setPlaybackAnchorEl(null);
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className="speed-values">
                                                        {SPEED_ADJUSTMENT_VALUEs.map((speed) => (
                                                            <Button
                                                                key={speed?.value}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setPlaybackSpeed(speed?.value);
                                                                }}
                                                                className={`speed-btn ${
                                                                    speed?.value ===
                                                                        playbackSpeed &&
                                                                    'active-speed'
                                                                }`}>
                                                                {speed?.title}
                                                            </Button>
                                                        ))}
                                                    </Box>
                                                    <Box className="flex" margin="8px 0 0">
                                                        <Button
                                                            className="flex f-v-center hover"
                                                            disabled={
                                                                SPEED_ADJUSTMENT_VALUEs[0].value ===
                                                                playbackSpeed
                                                            }
                                                            style={{
                                                                opacity:
                                                                    SPEED_ADJUSTMENT_VALUEs[0]
                                                                        .value === playbackSpeed
                                                                        ? 0.5
                                                                        : 1
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                adjustSpeedControl(SPEED_BACKWARD);
                                                            }}>
                                                            <Box className="arrow previous" />
                                                            <Typography className="slower">
                                                                Slower
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                adjustSpeedControl(SPEED_FORWARD);
                                                            }}
                                                            disabled={
                                                                SPEED_ADJUSTMENT_VALUEs[
                                                                    SPEED_ADJUSTMENT_VALUEs?.length -
                                                                        1
                                                                ].value === playbackSpeed
                                                            }
                                                            className="flex f-v-center hover"
                                                            style={{
                                                                margin: '0 0 0 auto',
                                                                opacity:
                                                                    SPEED_ADJUSTMENT_VALUEs[
                                                                        SPEED_ADJUSTMENT_VALUEs?.length -
                                                                            1
                                                                    ].value === playbackSpeed
                                                                        ? 0.5
                                                                        : 1
                                                            }}>
                                                            <Typography className="faster">
                                                                Faster
                                                            </Typography>
                                                            <Box className="arrow" />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </PlaybackSpeedPopOverWrapper>
                                            <FullScreenSVG
                                                className="fullscreen-icon hover"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleFullScreen();
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    {!bunnyPlayerReady && (
                                        <Loader
                                            isLoading={isLoading}
                                            extraStyle={{ borderRadius: '8px' }}
                                            boxClass={'full-width full-height loader'}
                                        />
                                    )}
                                    <Box className="bunny-video">
                                        <iframe
                                            ref={iframeRef}
                                            title="Video Player"
                                            id="bunny-player"
                                            loading="lazy"
                                            height="700"
                                            width="700"
                                            style={{
                                                border: 'none',
                                                position: 'absolute',
                                                top: 0,
                                                height: '100%',
                                                width: '100%'
                                            }}
                                            allow={`autoplay&loop=${repeat}`}
                                            allowFullScreen
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </div>

                {!isLoading && !document.fullscreenElement && (
                    <Box className="playerDetailsWrapper">
                        <Box className="bottom-analytics">
                            {!isInitialExperience && (
                                <React.Fragment>
                                    <Box className="titleWrapper">
                                        <Box className="full-width" ref={marqueeDiv}>
                                            <Marquee
                                                gradient={false}
                                                speed={60}
                                                delay={2}
                                                loop={loop}>
                                                <Typography className="title" ref={marqueeContent}>
                                                    {contentDetails?.title}
                                                </Typography>
                                            </Marquee>
                                        </Box>
                                    </Box>
                                    <Box className="flex f-h-center f-v-center full-width chapters-repeat-container">
                                        <Box
                                            onClick={() => {
                                                if (
                                                    props?.canRepeatPlaylist &&
                                                    playOver &&
                                                    !props?.playlistRepeat &&
                                                    !repeat
                                                ) {
                                                    props?.setPlaylistRepeat(true);
                                                    return;
                                                }
                                                if (
                                                    props?.canRepeatPlaylist &&
                                                    playOver &&
                                                    props?.playlistRepeat
                                                ) {
                                                    if (mediaType === MEDIA_TYPE.VIDEO)
                                                        playerRef.current.setLoop(
                                                            repeat ? false : true
                                                        );
                                                    props?.setPlaylistRepeat(false);
                                                    setRepeat((prevState) => !prevState);
                                                    return;
                                                }

                                                if (mediaType === MEDIA_TYPE.VIDEO)
                                                    playerRef.current.setLoop(
                                                        repeat ? false : true
                                                    );
                                                setRepeat((prevState) => !prevState);
                                            }}
                                            className="hover"
                                            height={24}
                                            width={24}
                                            style={{ cursor: 'pointer', margin: '0 5px' }}>
                                            <RepeatSVG
                                                fill={
                                                    repeat || props?.playlistRepeat
                                                        ? '#B08C3D'
                                                        : false
                                                }
                                                playOver={playOver}
                                                playlistRepeat={props?.playlistRepeat}
                                            />
                                        </Box>

                                        <TaoScrollbar
                                            keyId="chapters"
                                            horizontalScroll
                                            scrollLeft={scrollChapterLeft}>
                                            <Box
                                                className="flex full-width "
                                                id="chapter-selected-scroll"
                                                height={30}>
                                                {contentDetails?.chapters?.map((item, index) => (
                                                    <Typography
                                                        key={item?.chapterId || index}
                                                        onClick={() => onSelectStep(item)}
                                                        className={`step ${
                                                            selectedStep === item?.chapterId &&
                                                            'selected-step'
                                                        }`}>
                                                        {item?.chapterName}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </TaoScrollbar>
                                    </Box>
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </MeditationMediaPlayerWrapper>
    );
};

export default MeditationMediaPlayer;
