import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';

import wavesAnimation from 'Assets/AnimationFiles/wavy-lines-animation.json';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import TaoCard from 'Components/common/TaoCard';
import {
    PROGRAM_API_TYPE,
    TAO_CARD_TYPE,
    SLIDER_PER_PAGE,
    PROGRAM,
    CONTENT
} from 'Helpers/Constants';
import { HomePageWrapper } from './HomePage.style';
import {
    API_URL,
    URL_CONTENT_SEE_ALL,
    URL_HOW_TO_MEDITATE,
    URL_HOW_TO_MEDITATE_CONTENT
} from 'Helpers/Paths';
import { useDispatch } from 'react-redux';

import { handleContentRedirection, uniqueWithTypeAndId } from 'Helpers';

import TaoSlider from 'Components/common/TaoSlider';
import Api from 'Helpers/ApiHandler';
import moment from 'moment';
import { WaveAnimation } from './WaveAnimation/WaveAnimation';
import { ActivityAndWisdom } from './ActivityAndWisdom/ActivityAndWisdom';
import { WellnessLibrary } from './WellnessLibrary/WellnessLibrary';
import SeeAllTypography from 'Components/common/SeeAll/Typography/SeeAllTypography';
import { showSubscriptionModal } from 'Redux/App/Actions';
import TaoCardLoader from 'Components/common/TaoCard/TaoCardLoader';
import WebsiteTour from './WebsiteTour/WebsiteTour';
import { updateUserInfo } from 'Redux/Auth/Actions';

const HomePage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirectionUrl = sessionStorage.getItem('redirectionUrl');

    const [wellnessCategoryList, setWellnessCategoryList] = useState([]);
    const [dailyActivityData, setDailyActivityData] = useState({});
    const [homePageContentList, setHomePageContentList] = useState({
        forYou: {
            list: [],
            totalRecords: 0,
            currentPage: 0
        },
        newRelease: {
            list: [],
            totalRecords: 0,
            currentPage: 0
        },
        howToMeditate: {
            list: [],
            totalRecords: 0,
            currentPage: 0
        }
    });
    const [loading, setLoading] = useState(false);
    const [isDailyWisdomLoading, setIsDailyWisdomLoading] = useState(false);
    const [wellnessCategoryLoading, setWellnessCategoryLoading] = useState(false);
    const [contentId, setContentId] = useState(null);
    const [programId, setProgramId] = useState(-1);

    const API = useMemo(() => new Api(), []);
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const scrollIntoViewId = useSelector((state) => state.App.scrollIntoViewId);

    const forYouContentSlider = useRef(null);
    const newReleasesContentSlider = useRef(null);
    const howToMeditateRef = useRef(null);
    let wavesLottieRef = useRef();
    let wavesLottieMobileRef = useRef();
    const [enableTour, setTourEnable] = useState(!userDetails?.isMascotTutorialCompleted);

    const homePageContent = useCallback(
        async (type = '', current_page = 0) => {
            if (!type || type === '') setLoading(true);
            let response = await API.post(API_URL.HOMEPAGE_CONTENT, {
                params: {
                    type //For You/New Release/empty string
                },
                data: {
                    current_page,
                    per_page: SLIDER_PER_PAGE,
                    search: [
                        {
                            field: '',
                            keyword: ''
                        }
                    ],
                    sort: {
                        field: '',
                        order: ''
                    }
                }
            });

            if (response?.data?.data) {
                if (response?.data?.data?.forYou) {
                    setHomePageContentList((prev) => ({
                        ...prev,
                        forYou: {
                            list: uniqueWithTypeAndId(
                                [...prev?.forYou?.list, ...response?.data?.data?.forYou?.list],
                                ['id', 'type']
                            ),
                            currentPage: prev?.forYou?.currentPage + 1,
                            totalRecords: response?.data?.data?.forYou?.totalRecords
                        }
                    }));
                }

                if (response?.data?.data?.newRelease) {
                    setHomePageContentList((prev) => ({
                        ...prev,
                        newRelease: {
                            list: uniqueWithTypeAndId(
                                [
                                    ...prev?.newRelease?.list,
                                    ...response?.data?.data?.newRelease?.list
                                ],
                                ['id', 'type']
                            ),
                            currentPage: prev?.newRelease?.currentPage + 1,
                            totalRecords: response?.data?.data?.newRelease?.totalRecords
                        }
                    }));
                }

                if (response?.data?.data?.howToMeditate) {
                    setHomePageContentList((prev) => ({
                        ...prev,
                        howToMeditate: {
                            list: response?.data?.data?.howToMeditate?.list,
                            currentPage: prev.howToMeditate.currentPage,
                            totalRecords: response?.data?.data?.howToMeditate?.totalRecords
                        }
                    }));
                }
            }

            setLoading(false);
        },
        [API]
    );

    const wellnessCategory = useCallback(async () => {
        setWellnessCategoryLoading(true);
        let response = await API.get(API_URL.WELLNESS_CATEGORY, {
            params: {
                isFromHomePage: true
            }
        });

        if (response?.data?.data?.list?.length > 0) {
            setWellnessCategoryList(response?.data?.data?.list);
        }

        const { contentId, programId } = response?.data?.data;

        if (contentId) {
            setContentId(contentId);
        }

        if (programId) {
            setProgramId(programId);
        }

        setWellnessCategoryLoading(false);
    }, [API]);

    const handleMascotTour = async () => {
        dispatch(
            updateUserInfo({
                ...userDetails,
                isMascotTutorialCompleted: true
            })
        );
        await API.put(API_URL.MASCOT);
    };

    const onFavoriteProgram = async (data, index, type, secondaryType) => {
        let newList = [...homePageContentList[type].list];
        newList[index].isFavorite = !newList[index].isFavorite;

        let secondaryList = [...homePageContentList[secondaryType].list];
        let findSecondaryMedia = secondaryList.findIndex((item) => item.id === data.id);

        if (findSecondaryMedia > -1)
            secondaryList[findSecondaryMedia].isFavorite =
                !secondaryList[findSecondaryMedia].isFavorite;

        setHomePageContentList((prev) => ({
            ...prev,
            [type]: {
                ...prev?.[type],
                list: newList
            },
            [secondaryType]: {
                ...prev?.[secondaryType],
                list: secondaryList
            }
        }));

        if (data?.type === PROGRAM) {
            await API.post(API_URL.PROGRAM_FAVORITE, {
                params: {
                    programId: data?.id
                }
            });
            return;
        }

        if (data?.type === CONTENT) {
            await API.post(API_URL.CONTENT_FAVOURITES, {
                params: {
                    contentId: data?.id
                }
            });
            return;
        }
    };

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    const fetchDailyActivity = useCallback(async () => {
        setIsDailyWisdomLoading(true);

        let response = await API.get(API_URL.DAILY_WISDOM, {
            params: { date: moment().format('YYYY-MM-DD') }
        });

        setIsDailyWisdomLoading(false);

        if (response) setDailyActivityData(response?.data?.data);
    }, [API]);

    useEffect(() => {
        if (wavesLottieRef && wavesLottieRef.current) {
            wavesLottieRef.current.setSpeed(0.5);
        }

        if (wavesLottieMobileRef && wavesLottieMobileRef.current) {
            wavesLottieMobileRef.current.setSpeed(0.5);
        }
    }, []);

    useEffect(() => {
        // WELLNESS CATEGORY LIST
        wellnessCategory();

        // HOME PAGE CONTENT
        homePageContent();

        // DAILY ACTIVITY
        fetchDailyActivity();
    }, [wellnessCategory, homePageContent, fetchDailyActivity]);

    if (redirectionUrl) {
        sessionStorage.clear();
        return <Navigate to={redirectionUrl} />;
    }

    return (
        <>
            <TaoCalligraphyWatermark />
            {/* <TaoLoader isLoading={loading} /> */}
            {enableTour && !loading && (
                <WebsiteTour
                    contentId={contentId}
                    programId={programId}
                    isLoading={loading}
                    setTourEnable={setTourEnable}
                    handleMascotTour={handleMascotTour}
                    userDetails={userDetails}
                />
            )}

            <HomePageWrapper>
                <Box className="full-width-section">
                    <Box className="top-bg-image" />
                    <Box className="home-page-content">
                        <Box className="slider-content">
                            <Box className="flex f-h-space-between f-v-center">
                                <Typography
                                    color="primary"
                                    className="slider-header"
                                    id="tour-home-name">
                                    {`For ${userDetails?.firstName}`}
                                </Typography>
                                {(!!homePageContentList?.forYou?.list?.length || loading) && (
                                    <SeeAllTypography
                                        redirectionURL={URL_CONTENT_SEE_ALL}
                                        state={{
                                            seeAllListType: PROGRAM_API_TYPE.FOR_YOU
                                        }}
                                    />
                                )}
                            </Box>

                            {loading && (
                                <>
                                    <TaoSlider ref={forYouContentSlider} cardHeight={80} gap={10}>
                                        {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                            <TaoCardLoader key={index} />
                                        ))}
                                    </TaoSlider>
                                </>
                            )}
                            {!loading && homePageContentList?.forYou?.list?.length > 0 && (
                                <TaoSlider ref={forYouContentSlider} cardHeight={80} gap={10}>
                                    {homePageContentList?.forYou?.list?.map((value, index) => (
                                        <TaoCard
                                            key={index}
                                            backgroundImage={value?.thumbnailImage}
                                            mediaTextType="primary"
                                            type={
                                                value.type === PROGRAM
                                                    ? TAO_CARD_TYPE.PROGRAM
                                                    : value?.contentType
                                            }
                                            title={value.title}
                                            favoriteIconVisible={true}
                                            isFav={value?.isFavorite}
                                            badge={value?.subscription?.badge}
                                            isAccessible={
                                                value?.subscription
                                                    ? value?.subscription?.isAccessible
                                                    : true
                                            }
                                            isPaidContent={value?.isPaidContent}
                                            isPurchased={value?.isPurchased}
                                            isTextColorDark={true}
                                            isFeatured={value?.isFeatured}
                                            textFromStart={true}
                                            onFavClick={() => {
                                                onFavoriteProgram(
                                                    value,
                                                    index,
                                                    'forYou',
                                                    'newRelease'
                                                );
                                            }}
                                            onClick={() =>
                                                handleContentRedirection(value, navigateTo)
                                            }
                                        />
                                    ))}
                                </TaoSlider>
                            )}
                            {!loading && !homePageContentList?.forYou?.list?.length && (
                                <Typography className="flex f-v-center f-h-center no-content">
                                    No For You Data Found
                                </Typography>
                            )}
                        </Box>

                        <Box className="slider-content">
                            <Box className="flex f-h-space-between f-v-center">
                                <Typography color="primary" className="slider-header">
                                    New Releases
                                </Typography>
                                {(!!homePageContentList?.newRelease?.list?.length || loading) && (
                                    <SeeAllTypography
                                        redirectionURL={URL_CONTENT_SEE_ALL}
                                        state={{
                                            seeAllListType: PROGRAM_API_TYPE.NEW_RELEASES
                                        }}
                                    />
                                )}
                            </Box>

                            {loading && (
                                <>
                                    <TaoSlider
                                        ref={newReleasesContentSlider}
                                        cardHeight={80}
                                        gap={10}>
                                        {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                            <TaoCardLoader key={index} />
                                        ))}
                                    </TaoSlider>
                                </>
                            )}

                            {!loading && homePageContentList?.newRelease?.list?.length > 0 && (
                                <TaoSlider gap={10} ref={newReleasesContentSlider} cardHeight={80}>
                                    {homePageContentList?.newRelease?.list?.map((value, index) => (
                                        <TaoCard
                                            cardRootClass="tao-card"
                                            key={index}
                                            backgroundImage={value?.thumbnailImage}
                                            mediaTextType="primary"
                                            type={
                                                value.type === PROGRAM
                                                    ? TAO_CARD_TYPE.PROGRAM
                                                    : value?.contentType
                                            }
                                            title={value.title}
                                            isTextColorDark={true}
                                            favoriteIconVisible={true}
                                            isFav={value?.isFavorite}
                                            badge={value?.subscription?.badge}
                                            isAccessible={
                                                value?.subscription
                                                    ? value?.subscription?.isAccessible
                                                    : true
                                            }
                                            isPaidContent={value?.isPaidContent}
                                            isPurchased={value?.isPurchased}
                                            isFeatured={value?.isFeatured}
                                            textFromStart={true}
                                            onFavClick={() => {
                                                onFavoriteProgram(
                                                    value,
                                                    index,
                                                    'newRelease',
                                                    'forYou'
                                                );
                                            }}
                                            onClick={() =>
                                                handleContentRedirection(value, navigateTo)
                                            }
                                        />
                                    ))}
                                </TaoSlider>
                            )}
                            {!loading && !homePageContentList?.newRelease?.list?.length && (
                                <Typography className="flex f-v-center f-h-center no-content">
                                    No New Releases Found
                                </Typography>
                            )}
                        </Box>
                        <WellnessLibrary
                            wellnessCategoryList={wellnessCategoryList}
                            wellnessCategoryLoading={wellnessCategoryLoading}
                        />

                        <Box className="slider-content how-to-meditate">
                            <Box className="flex f-h-space-between f-v-center">
                                <Typography color="primary" className="slider-header">
                                    How-To Guides
                                </Typography>

                                <SeeAllTypography onClick={() => navigate(URL_HOW_TO_MEDITATE)} />
                            </Box>

                            <Box className="meditate-section">
                                {loading && (
                                    <>
                                        <TaoSlider
                                            ref={howToMeditateRef}
                                            cardHeight={80}
                                            gap={10}
                                            showArrows>
                                            {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                                <TaoCardLoader key={index} />
                                            ))}
                                        </TaoSlider>
                                    </>
                                )}

                                {!loading &&
                                    homePageContentList?.howToMeditate?.list?.length > 0 && (
                                        <TaoSlider
                                            gap={10}
                                            ref={howToMeditateRef}
                                            cardHeight={80}
                                            slidesToShow={2}>
                                            {homePageContentList?.howToMeditate?.list?.map(
                                                (value, index) => (
                                                    <TaoCard
                                                        cardRootClass="tao-card"
                                                        key={index}
                                                        backgroundImage={value?.thumbnailImage}
                                                        mediaTextType="primary"
                                                        type={
                                                            value.type === PROGRAM
                                                                ? TAO_CARD_TYPE.PROGRAM
                                                                : value?.contentType
                                                        }
                                                        title={value.title}
                                                        isTextColorDark={true}
                                                        favoriteIconVisible={false}
                                                        isFav={value?.isFavorite}
                                                        badge={value?.subscription?.badge}
                                                        isAccessible={
                                                            value?.subscription
                                                                ? value?.subscription?.isAccessible
                                                                : true
                                                        }
                                                        isPaidContent={value?.isPaidContent}
                                                        isPurchased={value?.isPurchased}
                                                        isFeatured={value?.isFeatured}
                                                        onClick={() =>
                                                            navigate(URL_HOW_TO_MEDITATE_CONTENT, {
                                                                state: {
                                                                    contentId: value?.contentId
                                                                }
                                                            })
                                                        }
                                                    />
                                                )
                                            )}
                                        </TaoSlider>
                                    )}
                                {!loading && !homePageContentList?.howToMeditate?.list?.length && (
                                    <Typography className="flex f-v-center f-h-center no-content">
                                        No Meditation Content Found
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <WaveAnimation refComp={wavesLottieRef} animation={wavesAnimation} />
                    </Box>
                </Box>

                <ActivityAndWisdom
                    dailyActivityData={dailyActivityData}
                    wavesLottieMobileRef={wavesLottieMobileRef}
                    wavesAnimation={wavesAnimation}
                    scrollIntoViewId={scrollIntoViewId}
                    isDailyWisdomLoading={isDailyWisdomLoading}
                />
            </HomePageWrapper>
        </>
    );
};

export default HomePage;
