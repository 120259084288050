import styled from 'styled-components';
import MeditationTimerBackground from 'Assets/images/MeditationTimer/meditation-timer-background.webp';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { SnackbarContent } from 'notistack';
import NotificationLogo from 'Assets/images/Notifications/notification-logo.svg';
import { Popover } from '@mui/material';

export const MeditationTimerWrapper = styled.div`
    background-image: url(${MeditationTimerBackground});
    height: calc(100vh - 50px);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: ${(props) => (props.isOnlyMeditationCards ? 'center' : 'flex-end')};

    ${responsive.TABLET`
        height: 100%;
    `}

    ${responsive.PHABLET`
        background-image: unset;
        height: 100%;
        align-items: unset;
    `}

    /* CSSTransition classes */
    .fade-enter {
        opacity: 0;
        transform: translateX(-100%);
    }

    .fade-enter-active {
        opacity: 1;
        transform: translateX(0%);
    }

    .fade-exit {
        opacity: 1;
        transform: translateX(0%);
    }

    .fade-exit-active {
        opacity: 0;
        transform: translateX(100%);
    }

    .fade-enter-active,
    .fade-exit-active {
        transition: opacity 500ms, transform 500ms;
    }

    .container {
        height: 90%;
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: ${(props) => (props.isOnlyMeditationCards ? 'center' : '')};
        padding: 70px 0 0;

        ${responsive.TABLET`
            width: 95%;
            align-self: flex-end;
            padding: 0;
        `}

        ${responsive.PHABLET`
            height: 100%;
            width: 100%;
            align-items: center;
            
        `}
    }

    .meditationLeftCards {
        height: 100%;
        padding: 0 5% 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 0.5s ease-in;
        width: 40%;

        ${responsive.LAPTOP`
            padding: 0 3% 0 0;
        `}

        ${responsive.TABLET`
            width: 100%;
            height: 100%;
        `}

        ${responsive.PHABLET`
            padding: 50px 20px 0;
        `}
    }

    .collapse-root {
        display: flex;
        height: 100%;
        align-items: end;
        width: 60%;

        ${responsive.TABLET`
            width: ${(props) => (props?.isOnlyMeditationCards ? '0' : '80%')};
            margin: 0 auto;
        `}

        ${responsive.PHABLET`
            width: ${(props) => (props?.isOnlyMeditationCards ? '0' : '100%')};
        `}
    }

    .collapse-wrapper {
        height: 100%;
    }

    .overlay {
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0.1;
        position: relative;

        ${responsive.TABLET`
            opacity: 1;
            pointer-events: auto;
        `}
    }

    .complete {
        height: 95%;
        width: 95%;
        border-radius: 50%;
        background-color: ${COLORS.PRIMARY_DARK};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .done-fonts {
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.6;
    }

    .pointer {
        cursor: pointer;
    }

    .meditationList {
        width: 100%;
        height: 100%;
        gap: 20px 20px;
        overflow: auto;
        padding: 10px 0;

        ${responsive.PHABLET`
            max-height: calc(${(props) => props.size.height - 170}px);
        `}

        .meditation-timer-loader-wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    .meditationCard {
        height: 300px;
        width: 165px;
        border: 3px solid #e8e8e8;
        border-radius: 12px;
        cursor: pointer;
        position: relative;

        &.meditationCardBorder {
            border: 3px solid ${COLORS.PRIMARY};
        }

        .timer-lock {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background: rgba(101, 101, 101, 0.7);
            padding: 5px;
            border-radius: 50%;
        }
    }

    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY};
        font-weight: 700;
        color: ${COLORS.PRIMARY_DARK};
        width: max-content;

        ${responsive.DESKTOP`
            font-size: 32px;
        `}

        ${responsive.LAPTOP`
            font-size: 30px;
        `}
    }

    .text {
        font-size: 14px;
        font-family: ${FONTS.PRIMARY};
        color: ${COLORS.GREY_TEXT_COLOR};
    }
`;

export const PopoverMeditationTimerWrapper = styled(Popover)`
    .popover-paper {
        border-radius: 12px 0 12px 12px;
    }
`;

export const MeditationCardWrapper = styled.div`
    .description {
        max-width: fit-content;
        font-size: 16px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        color: ${COLORS.PRIMARY_DARK};
        margin: 5px 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const MeditationCardContainer = styled.div`
    height: 298px;
    width: 168px;
    border: 3px solid #e8e8e8;
    border-radius: 12px;
    background-color: ${(props) => (props.isHighlightedBackground ? 'rgba(71, 69, 69, 0.35)' : '')};
    background-image: ${(props) => props.meditation?.image && `url(${props.meditation.image})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .dots {
        display: flex;
        justify-content: flex-end;
        padding: 7px 0;
    }

    .time {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    }

    .timer-fonts {
        font-size: 42px;
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        color: ${COLORS.PRIMARY};
    }

    .timer-m {
        font-size: 24px;
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        color: ${COLORS.PRIMARY};
    }
`;

export const MeditationDetailsCardWrapper = styled.div`
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) => (!props.isBottomBorderRequired ? '30px 30px 0px 0px' : '30px')};
    margin-bottom: -10px;
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    background-color: #fff;

    .time-picker-wrapper {
        .time-picker-label {
            font-family: ${FONTS.PRIMARY};
            font-size: 14px;
            color: ${COLORS.MEDIUM_GREY};
        }

        .text-field {
            font-family: ${FONTS.PRIMARY};
            margin: 10px 0 0;
            font-size: 20px;
            line-height: 29px;
            color: ${COLORS.MEDIUM_GREY};
            width: 160px;
            height: 60px;
            border: 1px solid rgba(132, 132, 132, 0.4);
            border-radius: 8px;

            &:hover,
            &:active {
                border: 1px solid ${COLORS.PRIMARY_DARK};
            }

            .time-picker-outline {
                border: none;
            }
        }
    }

    ${responsive.PHABLET`
        min-width:100%;
        margin-bottom: 0;
        border-radius: 0;
    `}

    .container {
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 30px;
        overflow: auto;

        ${responsive.PHABLET`
            padding: 100px 30px;
        `}
    }

    .timer {
        font-size: 50px;
        font-family: ${FONTS.PRIMARY};
        font-weight: 600;
        color: ${COLORS.PRIMARY_DARK};
    }

    .player-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .details-screen-arrow {
        display: none;

        ${responsive.TABLET`
            display: block;
        `}
    }

    .name {
        font-size: 24px;
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        color: ${COLORS.PRIMARY_DARK};
        text-align: center;
    }

    .circular-progress-bar-container {
        height: 160px;
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
    }

    /* Edit detail screen classes */

    .container-edit {
        min-width: max-content;
        gap: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px 0;
        position: relative;

        ${responsive.PHABLET`
            padding: 100px 20px 90px;
        `}

        .arrow-icon {
            transform: rotate(270deg);
            margin: 0 10px 0 0;
            display: none;

            ${responsive.TABLET`
                display: block;
            `}
        }
    }

    .timer-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* height: 700px; */
        height: 100%;
    }

    .title {
        font-family: ${FONTS.PRIMARY};
        font-size: 30px;
        font-weight: 700;
        color: ${COLORS.PRIMARY_DARK};
        margin: 0 0 0 15px;
    }

    .meditation-time {
        font-family: ${FONTS.PRIMARY};
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: ${COLORS.MEDIUM_GREY};
    }

    .time {
        font-family: ${FONTS.PRIMARY};
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        color: ${COLORS.PRIMARY_DARK};
    }

    .time-min {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        margin: '0 20px 0 0';

        /* Gold */
        color: ${COLORS.PRIMARY_DARK};
        padding-left: 5px;
    }

    .slider {
        padding-bottom: 20px;
        .MuiSlider-thumb {
            height: 30px;
            width: 30px;
            display: ${(props) => props.isSlideFull && 'none'};
            /* background-color: #fff; */
        }

        .MuiSlider-rail {
            background-color: ${COLORS.MEDIUM_GREY};
        }
    }

    .round-circle {
        border-radius: 50%;
        height: 22px;
        width: 24px;
        background-color: ${(props) => (props.isSlideFull ? COLORS.PRIMARY_DARK : '#E8E8E8')};
        margin-left: 4px;
    }

    .track-time {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${COLORS.MEDIUM_GREY};
    }

    .sounds-container {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px 0;
        justify-content: space-between;
    }

    .sound-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: ${COLORS.MEDIUM_GREY};
        padding: 0 12px;
    }

    .line {
        height: 1px;
        width: 100%;
        background-color: ${COLORS.MEDIUM_GREY};
    }

    .vertical-line {
        height: auto;
        width: 1px;
        background-color: ${COLORS.MEDIUM_GREY};
        margin: 0 5px;
    }

    .selector {
        background-color: #fff;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;
        display: flex;
        align-items: center;

        .selected {
            background-color: transparent;
        }
        &:hover {
            background-color: #fff;
        }

        .down-arrow-icon {
            position: absolute;
            cursor: pointer;
            right: 17px;
        }

        &.options {
            width: 100%;
            background-color: #fff;
        }
        .menuPaper {
            max-height: 400px;
        }
    }

    .drop-down-padding {
        padding: 0 20px;
    }

    .form-margin {
        margin-bottom: 15px;
    }

    .background-image-container {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

    .background-image-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: ${COLORS.MEDIUM_GREY};
        padding-left: 10px;
        margin: 15px 0;
    }

    .image-padding {
        flex-wrap: wrap;
        gap: 20px;
        ${responsive.PHABLET`
            gap: 10px;
        `}

        .image-design {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 115px;
            height: 200px;
            filter: drop-shadow(0px 9px 18px rgba(0, 0, 0, 0.15));
            border-radius: 10px;
            cursor: pointer;

            ${responsive.PHABLET`
                width: 100px;
            `}

            &.selected-image {
                border: 2px solid ${COLORS.PRIMARY_DARK};
            }
        }
    }

    .timer-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .remind-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${COLORS.MEDIUM_GREY};
    }

    .time-set {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: ${COLORS.DARK_GREY};
    }

    .remind-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .remind-button-cancel-font {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: ${COLORS.MEDIUM_GREY};
        text-transform: capitalize;
    }

    .remind-button-set-font {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: ${COLORS.MEDIUM_GREY};
        text-transform: capitalize;
    }

    .cancel-button {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        background: #fff;
        color: #31290e;
        margin: 15px 0 0;

        &:hover {
            background: inherit;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        }
    }

    .edit-arrow {
        display: none;

        ${responsive.PHABLET`
            display: flex;
            padding-right: 20px;
        `}
    }
`;

export const NotificationStyleWrapper = styled(SnackbarContent)`
    height: auto;
    width: 100%;
    padding: 20px;
    margin: 0;
    background-color: #fff;
    border-radius: 12px;

    .logo {
        background-image: url(${NotificationLogo});
        background-position: center;
        background-size: cover;
        height: 60px;
        width: 60px;
    }
    .text {
        margin: 0 20px 0 10px;
        &.primary {
            color: #7d5416;
        }
        &.secondary {
            color: ${COLORS.PRIMARY_WARM};
        }
    }

    .btn {
        font-size: 10px;
        color: ${COLORS.TEXT_DARK};
        background-color: #e8e8e8;
        border-radius: 30px;
        width: 110px;

        ${responsive.PHABLET`
            width: max-content;
            margin: 0 0 0 auto;
        `}
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        width: 35px;
    }
`;
