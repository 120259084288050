import React, { useEffect, useState } from 'react';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import ContentSeeAllWrapper from './ContentSeeAll.style';
import { Box, Chip, Typography } from '@mui/material';
import SeeAllList from '../SeeAllList/SeeAllList';
import { useMemo } from 'react';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PROFILE } from 'Helpers/Paths';
import { PROFILE_TAB_TYPE, PROGRAM_API_TYPE, SLIDER_PER_PAGE } from 'Helpers/Constants';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleContentRedirection, uniqueWithTypeAndId } from 'Helpers';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';

const CHIPS_TYPE = [
    { id: 1, title: 'meditations', apiKey: 'meditations' },
    { id: 2, title: 'programs', apiKey: 'programs' }
];

const PROGRAM = 'program';
const CONTENT = 'content';

const SEE_ALL_TITLE_TYPE = {
    [PROGRAM_API_TYPE.FOR_YOU]: {
        title: 'For You'
    },
    [PROGRAM_API_TYPE.NEW_RELEASES]: {
        title: 'New Releases'
    }
};

const ContentSeeAll = () => {
    const [activeTab, setActiveTab] = useState(CHIPS_TYPE[0]);
    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalRecords: 0
    });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const API = useMemo(() => new Api(), []);

    const fetchItems = useCallback(async () => {
        setLoading(true);
        let response = await API.post(API_URL.HOMEPAGE_CONTENT_SEE_ALL, {
            params: {
                listType: location?.state?.seeAllListType || PROGRAM_API_TYPE?.FOR_YOU,
                type: activeTab?.apiKey
            },
            data: {
                current_page: pagination?.currentPage,
                per_page: SLIDER_PER_PAGE,
                search: [],
                sort: {}
            }
        });

        setItems((prev) =>
            uniqueWithTypeAndId(
                [...prev, ...response?.data?.data?.[activeTab?.apiKey]?.list],
                ['id', 'type']
            )
        );
        setPagination((prev) => ({
            ...prev,
            totalRecords: response?.data?.data?.[activeTab?.apiKey]?.totalRecords
        }));
        setLoading(false);
    }, [API, pagination?.currentPage, activeTab?.apiKey, location?.state?.seeAllListType]);

    const changeTab = (chip = {}) => {
        if (loading) return;
        setItems([]);
        setPagination({
            currentPage: 0,
            totalRecords: 0
        });
        setActiveTab(chip);
    };

    const favoriteContent = async (value, index) => {
        let contents = [...items];
        contents[index].isFavorite = !contents[index].isFavorite;
        setItems(contents);

        if (value?.type === PROGRAM) {
            await API.post(API_URL.PROGRAM_FAVORITE, {
                params: {
                    programId: value?.id
                }
            });
            return;
        }

        if (value?.type === CONTENT) {
            await API.post(API_URL.CONTENT_FAVOURITES, {
                params: {
                    contentId: value?.id
                }
            });
            return;
        }
    };

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    const loadMoreItems = () => {
        setPagination((prev) => ({
            ...prev,
            currentPage: prev.currentPage + 1
        }));
    };

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <ContentSeeAllWrapper>
                <Box className="container">
                    <Box className="flex f-v-center">
                        <Typography className="title">
                            {
                                SEE_ALL_TITLE_TYPE[
                                    location?.state?.seeAllListType || PROGRAM_API_TYPE.FOR_YOU
                                ]?.title
                            }
                        </Typography>
                        {location?.state?.seeAllListType === PROGRAM_API_TYPE.FOR_YOU && (
                            <Typography
                                className="update-text"
                                onClick={() =>
                                    navigate(URL_PROFILE, {
                                        state: { type: PROFILE_TAB_TYPE.GOALS }
                                    })
                                }>
                                Update
                                <span className="indicator">›</span>
                            </Typography>
                        )}
                    </Box>
                    <Box className="chips">
                        {CHIPS_TYPE.map((chip) => (
                            <Chip
                                key={chip?.id}
                                onClick={() => changeTab(chip)}
                                label={chip?.title}
                                classes={{
                                    root: `chip-root hover ${
                                        chip?.id === activeTab?.id && 'active'
                                    }`
                                }}
                            />
                        ))}
                    </Box>

                    <SeeAllList
                        items={items}
                        totalRecords={pagination?.totalRecords}
                        favoriteContent={favoriteContent}
                        handleContentRedirection={(value) =>
                            handleContentRedirection(value, navigateTo)
                        }
                        loadMoreItems={loadMoreItems}
                        isLoading={loading}
                    />
                </Box>
            </ContentSeeAllWrapper>
        </React.Fragment>
    );
};

export default ContentSeeAll;
