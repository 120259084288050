import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// WATERMARK
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

// MATERIAL UI
import { Typography, Box, /* IconButton */ Button } from '@mui/material';

// WRAPPER
import { FavoritesWrapper, ContentCountDetailsWrapper, NoContentWrapper } from './Favorites.style';

// CONSTANT
import { CONTENT, MEDIA_TYPE, SLIDER_PER_PAGE } from 'Helpers/Constants';

// COMMON
import TaoSlider from 'Components/common/TaoSlider';
import TaoCard from 'Components/common/TaoCard';

import { ReactComponent as FilledRating } from 'Assets/images/Common/filled-star.svg';
import { ReactComponent as UnFilledRating } from 'Assets/images/Common/outlined-star.svg';
import { ReactComponent as User } from 'Assets/images/Programs/user.svg';

import TaoMeditationCard from 'Components/common/TaoMeditationCard/TaoMeditationCard';

// API
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PROGRAMS, URL_HOME_PAGE } from 'Helpers/Paths';

import Footer from 'Components/common/Footer/Footer';
import { BoxLoader, TitleLoader } from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { handleContentRedirection } from 'Helpers';
import { useDispatch } from 'react-redux';
import { SortFilter } from 'Components/common/SortFilter';

const SORT_BY_VALUE = {
    LATEST: 'Latest',
    A_Z: 'A-Z'
};

const SORT_BY_API_VALUE = {
    Latest: 'latest',
    'A-Z': 'A-Z'
};

const SORT_BY_API_ORDER = {
    Latest: 'desc',
    'A-Z': 'asc'
};

// const LIVE_CONTENT = 'LIVE_CONTENT'; PHASE 2
const Favorites = () => {
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.LATEST);
    const [meditationContent, setMeditationContent] = useState({
        currentPage: 0,
        totalRecords: 0,
        list: [],
        search: '',
        sort: {
            field: SORT_BY_API_VALUE.Latest,
            order: SORT_BY_API_ORDER.Latest
        }
    });
    const [favoriteProgramContent, setFavoriteProgramContent] = useState({
        currentPage: 0,
        totalRecords: 0,
        list: []
    });
    const dispatch = useDispatch();

    const API = useMemo(() => new Api(), []);
    const meditationSliderRef = useRef(null);
    const programSliderRef = useRef();
    const [favoriteContentLoading, setFavoriteContentLoading] = useState(false);
    const [favoriteProgramLoading, setFavoriteProgramLoading] = useState(false);
    const navigate = useNavigate();

    const handleMeditationContentFilter = (option) => {
        if (sortBy !== option) {
            setMeditationContent((prev) => ({
                ...prev,
                currentPage: 0,
                list: [],
                sort: {
                    field: SORT_BY_API_VALUE[option],
                    order: SORT_BY_API_ORDER[option]
                }
            }));

            getFavoriteContents(
                0,
                {
                    field: SORT_BY_API_VALUE[option],
                    order: SORT_BY_API_ORDER[option]
                },
                true
            );
        }
    };

    const getFavoriteContents = useCallback(
        async (
            currentPage = 0,
            sort = { field: SORT_BY_API_VALUE.Latest, order: SORT_BY_API_ORDER.Latest },
            mutateLoading = false
        ) => {
            if (mutateLoading) setFavoriteContentLoading(true);
            let response = await API.post(API_URL.FAVORITES_CONTENTS, {
                data: {
                    current_page: currentPage,
                    per_page: SLIDER_PER_PAGE,
                    search: '',
                    sort: sort
                }
            });
            if (response?.data?.data?.list) {
                if (response?.data?.data) {
                    response.data.data.list = response?.data?.data?.list?.map((value) => {
                        if (value?.currencies?.length > 0) {
                            value.currencyDetails = value?.currencies?.[0];
                        }
                        return value;
                    });
                }

                setMeditationContent((prev) => ({
                    ...prev,
                    currentPage: prev?.currentPage + 1,
                    list: [...prev?.list, ...response?.data?.data?.list],
                    totalRecords: response?.data?.data?.totalRecords
                }));
            }
            // setLoading(false);
            setFavoriteContentLoading(false);
        },
        [API]
    );

    const getFavoritePrograms = useCallback(
        async (currentPage = 0, search = '', mutateLoading = false) => {
            if (mutateLoading) setFavoriteProgramLoading(true);
            let response = await API.post(API_URL.FAVORITES_PROGRAMS, {
                data: {
                    current_page: currentPage,
                    per_page: SLIDER_PER_PAGE,
                    search: ''
                }
            });
            if (response?.data?.data) {
                setFavoriteProgramContent((prev) => ({
                    ...prev,
                    currentPage: prev?.currentPage + 1,
                    list: [...prev?.list, ...response?.data?.data?.list],
                    totalRecords: response?.data?.data?.totalRecords
                }));
            }

            setFavoriteProgramLoading(false);
        },
        [API]
    );

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    const handleFavoriteMeditationContent = async (data, index) => {
        let copyMeditationContentList = [...meditationContent?.list];
        if (copyMeditationContentList && copyMeditationContentList[index]) {
            copyMeditationContentList?.splice(index, 1);

            setMeditationContent((prev) => ({
                ...prev,
                list: copyMeditationContentList,
                totalRecords: copyMeditationContentList?.length || 0
            }));

            await API.post(API_URL.CONTENT_FAVOURITES, {
                params: {
                    contentId: data?.id
                }
            });
        }
    };

    const handleFavoriteProgram = async (data, index) => {
        let copyFavoriteProgramContent = [...favoriteProgramContent?.list];

        if (copyFavoriteProgramContent && copyFavoriteProgramContent[index]) {
            copyFavoriteProgramContent?.splice(index, 1);

            setFavoriteProgramContent((prev) => ({
                ...prev,
                list: copyFavoriteProgramContent,
                totalRecords: copyFavoriteProgramContent?.length || 0
            }));

            await API.post(API_URL.PROGRAM_FAVORITE, {
                params: {
                    programId: data?.id
                }
            });
            return;
        }
    };

    useEffect(() => {
        // locationByIpAddress();
        getFavoriteContents(
            0,
            {
                field: SORT_BY_API_VALUE.Latest,
                order: SORT_BY_API_ORDER.Latest
            },
            true
        );
        getFavoritePrograms(0, '', true);
    }, [getFavoriteContents, getFavoritePrograms]);

    return (
        <>
            <TaoCalligraphyWatermark />
            {/* <TaoLoader isLoading={loading} /> */}
            <FavoritesWrapper>
                <Box className="full-width-section">
                    <Box className="favorites-content">
                        <Box className="header">
                            <Typography color="primary" className="header-text">
                                {`Favorites`}
                            </Typography>
                        </Box>

                        <Box className="meditation-content-slider-container">
                            <Box className="meditation-header">
                                <Typography color="primary" className="meditation-header-text">
                                    {`Meditations`}
                                </Typography>

                                <SortFilter
                                    sortBy={sortBy}
                                    disabled={favoriteContentLoading}
                                    sortObject={SORT_BY_VALUE}
                                    onClick={(item) => {
                                        handleMeditationContentFilter(item);
                                        setSortBy(item);
                                    }}
                                />
                            </Box>

                            {favoriteContentLoading && (
                                <>
                                    <TaoSlider gap={10} ref={meditationSliderRef} cardHeight={240}>
                                        {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <Box
                                                    key={index}
                                                    className="favorite-content-loader-wrapper">
                                                    <BoxLoader
                                                        customStyle={{ borderRadius: '16px 8px' }}
                                                        width={165}
                                                        height={240}
                                                    />
                                                </Box>
                                            </React.Fragment>
                                        ))}
                                    </TaoSlider>
                                </>
                            )}
                            {!favoriteContentLoading && meditationContent?.list?.length > 0 && (
                                <TaoSlider gap={10} ref={meditationSliderRef} cardHeight={240}>
                                    {meditationContent?.list?.map((value, index) => {
                                        return (
                                            <TaoMeditationCard
                                                key={value?.id}
                                                contentImage={value?.thumbnailImage}
                                                contentName={value?.contentName}
                                                description={value?.description}
                                                type={value?.type}
                                                contentDuration={value?.contentDuration}
                                                isDangerouslySetInnerHTML={true}
                                                favoriteIconVisible={true}
                                                isFav={value?.isFavorite}
                                                currencyDetails={value?.currencyDetails}
                                                heartDetails={value?.heartDetails}
                                                badge={value?.subscription?.badge}
                                                isAccessible={
                                                    value?.subscription
                                                        ? value?.subscription?.isAccessible
                                                        : true
                                                }
                                                isPaidContent={value?.isPaidContent}
                                                isPurchased={value?.isPurchased}
                                                onCardClick={() => {
                                                    if (
                                                        MEDIA_TYPE.MUSIC === value?.type ||
                                                        MEDIA_TYPE.AUDIO === value?.type ||
                                                        MEDIA_TYPE.GUIDED_AUDIO === value?.type ||
                                                        MEDIA_TYPE.VIDEO === value?.type
                                                    ) {
                                                        handleContentRedirection(
                                                            { ...value, type: CONTENT },
                                                            navigateTo
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                                onFavClick={() => {
                                                    handleFavoriteMeditationContent(value, index);
                                                }}
                                            />
                                        );
                                    })}
                                </TaoSlider>
                            )}
                            {!favoriteContentLoading && !meditationContent?.list?.length && (
                                <NoContentWrapper>
                                    <Typography className="no-content-text">
                                        You haven’t added any favorite meditations yet.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className="recommendations-btn"
                                        onClick={() => {
                                            navigateTo(URL_HOME_PAGE, {}, true);
                                        }}>
                                        SEE OUR RECOMMENDATIONS
                                    </Button>
                                </NoContentWrapper>
                            )}
                        </Box>

                        <Box className="program-content-slider-container">
                            <Box className="program-header">
                                <Typography color="primary" className="program-header-text">
                                    {`Programs`}
                                </Typography>
                            </Box>

                            {favoriteProgramLoading && (
                                <Box className="program-wrapper" sx={{ height: 200 }}>
                                    <TaoSlider ref={programSliderRef} gap={10} cardHeight={90}>
                                        {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <Box
                                                    className="program-card-loader-wrapper"
                                                    key={index}>
                                                    <Box className="program-card-loader">
                                                        <BoxLoader
                                                            customStyle={{
                                                                borderRadius: '16px 8px'
                                                            }}
                                                            width={210}
                                                            height={120}
                                                        />
                                                    </Box>

                                                    <Box className="program-card-title-loader-wrapper">
                                                        <TitleLoader
                                                            width={210}
                                                            height={15}
                                                            fillWidth={210}
                                                            fillHeight={15}
                                                        />
                                                        <TitleLoader
                                                            width={210}
                                                            height={15}
                                                            fillWidth={210}
                                                            fillHeight={15}
                                                        />
                                                    </Box>
                                                </Box>
                                            </React.Fragment>
                                        ))}
                                    </TaoSlider>
                                </Box>
                            )}
                            {!favoriteProgramLoading &&
                                favoriteProgramContent?.list?.length > 0 && (
                                    <Box className="program-wrapper" sx={{ height: 200 }}>
                                        <TaoSlider ref={programSliderRef} gap={10} cardHeight={90}>
                                            {favoriteProgramContent?.list?.map((value, index) => (
                                                <Box
                                                    className="card-with-count-container"
                                                    key={index}>
                                                    <TaoCard
                                                        cardRootClass="tao-card"
                                                        mediaTextType="primary"
                                                        key={value?.id}
                                                        backgroundImage={value?.thumbnailImage}
                                                        type={value.type}
                                                        favoriteIconVisible={true}
                                                        isFav={value?.isFavorite}
                                                        isTextColorDark={true}
                                                        ratingCount={value?.ratingCount}
                                                        badge={value?.subscription?.badge}
                                                        isAccessible={
                                                            value?.subscription
                                                                ? value?.subscription?.isAccessible
                                                                : true
                                                        }
                                                        isPaidContent={value?.isPaidContent}
                                                        isPurchased={value?.isPurchased}
                                                        onFavClick={() => {
                                                            handleFavoriteProgram(value, index);
                                                        }}
                                                        onClick={() => {
                                                            handleContentRedirection(
                                                                value,
                                                                navigateTo
                                                            );
                                                        }}
                                                    />
                                                    <ContentCountDetails
                                                        data={value}
                                                        descriptionVisible={false}
                                                        titleClass={'slide-title'}
                                                    />
                                                </Box>
                                            ))}
                                        </TaoSlider>
                                    </Box>
                                )}
                            {!favoriteProgramLoading &&
                                favoriteProgramContent?.list?.length === 0 && (
                                    <NoContentWrapper>
                                        <Typography className="no-content-text">
                                            You haven’t added any favorite programs yet.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            className="recommendations-btn"
                                            onClick={() => {
                                                navigateTo(URL_PROGRAMS, {}, true);
                                            }}>
                                            browse programs
                                        </Button>
                                    </NoContentWrapper>
                                )}
                        </Box>
                    </Box>
                </Box>
            </FavoritesWrapper>
            <Footer topBorderColor="gray" isBottomZero position="sticky" />
        </>
    );
};

const ContentCountDetails = (props) => {
    const { data, descriptionVisible, titleClass, isBrowse } = props;

    return (
        <ContentCountDetailsWrapper>
            <Box className={isBrowse ? 'slider-record-browse' : 'slider-record'}>
                <Typography className={titleClass}>{data.title}</Typography>

                {descriptionVisible && (
                    <Typography className="slide-description">
                        Tao Calligraphy Field | Portland
                    </Typography>
                )}
                <Box className="flex f-v-center">
                    {[1, 2, 3, 4, 5].map((record, index) =>
                        data?.averageRatingCount >= record ? (
                            <FilledRating key={index} />
                        ) : (
                            <UnFilledRating key={index} />
                        )
                    )}
                    <span className="rating-count">{data?.ratingCount}</span>
                    <Box className="flex f-v-center" style={{ marginLeft: '20px' }}>
                        <User />
                        <span className="user-count">{data?.joinedUserCount}</span>
                    </Box>
                </Box>
            </Box>
        </ContentCountDetailsWrapper>
    );
};

export default Favorites;
