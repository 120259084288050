// CORE
import React, { useEffect, useState } from 'react';

import { Topbar } from 'Components/common';
import Routes from 'Routes/Route';

import { ContentWrapper, WebsiteWrapper } from './Website.style.js';
import Sidebar from 'Components/common/Sidebar/Sidebar.jsx';
import EventManager from './EventManager/EventManager.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { connectSocket, NotificationSocket } from 'socket';

import { useSnackbar } from 'notistack';
import PushNotification from './common/PushNotification/PushNotification.js';
import { updateUserInfo } from 'Redux/Auth/Actions.js';
import { useMemo } from 'react';
import { NOTIFICATION_TYPE_FOR_API } from 'Helpers/Constants.js';
import MeditationTimerIcon from 'Assets/images/Header/UserMenu/meditation-timer-icon.svg';
import SubScriptionIcon from 'Assets/images/Header/UserMenu/subscription.svg';
import LogoForDesktopNOtification from 'Assets/images/Header/heart.jpg';
import TaoLogo from 'Assets/Logo/heart-in-circle.svg';
import { getWindowDimensions } from 'Helpers/Utils.js';
import Subscription from './common/Modal/Subscription/Subscription.jsx';
import { URL_HOME_PAGE, URL_MEDITATION_TIMER, URL_PROGRAMS_DETAILS } from 'Helpers/Paths.js';

function Website() {
    const showSideBarState = useSelector((state) => state.Sidebar.showSideBar);
    const showTopBarState = useSelector((state) => state.App.showTopBar);
    const tourEnabled = useSelector((state) => state.App.tourEnabled);
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const userDetails = useMemo(() => JSON.parse(localStorage.getItem('userInfo')) || {}, []);

    useEffect(() => {
        if (!isLoggedIn) return;
        connectSocket();

        if (!NotificationSocket) {
            return;
        }

        NotificationSocket.open();
        NotificationSocket.on('connect', () => {
            console.log('Socket Connected');
        });

        NotificationSocket.on('web/notification', (data) => {
            if (data) {
                let image = null;
                let childURL = '';
                if (data?.data?.notificationType === NOTIFICATION_TYPE_FOR_API.DAILY_WISDOM) {
                    childURL = `${URL_HOME_PAGE}?id=daily-wisdom`;
                    image = TaoLogo;
                }
                if (data?.data?.notificationType === NOTIFICATION_TYPE_FOR_API.MEDITATION) {
                    image = MeditationTimerIcon;
                    childURL = `${URL_MEDITATION_TIMER}?meditationId=${data?.data?.redirectionId}`;
                }
                if (data?.data?.notificationType === NOTIFICATION_TYPE_FOR_API.SUBSCRIPTION)
                    image = SubScriptionIcon;
                if (data?.data?.notificationType === NOTIFICATION_TYPE_FOR_API.PROGRAMS) {
                    image = data?.data?.image;
                    childURL = `${URL_PROGRAMS_DETAILS}?programId=${data?.data?.redirectionId}`;
                }

                dispatch(
                    updateUserInfo({
                        ...userDetails,
                        totalUnReadNotificationCount:
                            data?.badge || userDetails?.totalUnReadNotificationCount
                    })
                );

                Notification.requestPermission(function (permission) {
                    if (permission === 'granted') {
                        let notificationDesktop = new Notification(data?.title, {
                            body: data?.body,
                            icon: LogoForDesktopNOtification
                        });

                        notificationDesktop.onclick = () =>
                            window.open(
                                `${process.env.REACT_APP_DESKTOP_NOTIFICATION_URL}${childURL}`
                            );
                    }
                });

                enqueueSnackbar(
                    PushNotification(
                        data,
                        closeSnackbar,
                        image,
                        `${process.env.REACT_APP_DESKTOP_NOTIFICATION_URL}${childURL}`
                    ),
                    {
                        autoHideDuration: 3000
                    }
                );
            }
        });

        NotificationSocket.on('error', (error) => {
            console.log('🚀 ~ file: App.js ~ line 35 ~ useEffect ~ error', error);
        });

        return () => {
            NotificationSocket && NotificationSocket.close();
            closeSnackbar();
        };
    }, [isLoggedIn, enqueueSnackbar, closeSnackbar, dispatch, userDetails]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <WebsiteWrapper $tourEnabled={tourEnabled}>
                <Subscription />
                {showTopBarState && <Topbar />}
                {showSideBarState && isLoggedIn && <Sidebar />}
                <ContentWrapper
                    $ShowSideBar={showSideBarState && isLoggedIn}
                    $mobileHeight={windowDimensions.height}>
                    <Routes />
                </ContentWrapper>
                <EventManager />
            </WebsiteWrapper>
        </React.Fragment>
    );
}

export default Website;
