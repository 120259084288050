import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import Flower from 'Assets/images/Signup/sales-page-flower-bg.svg';

export const BeginExperienceWrapper = styled(Box)`
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    .white-circle-wrapper {
        position: fixed;
        z-index: 1000;
        top: 60px;
        right: 20px;
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 24px;

        ${responsive.PHABLET`
            top: 5px;
            right: ${(props) => (props?.$isLoggedIn ? '80px' : '20px')};
        `}
    }

    .full-width-section {
        height: 100%;
        padding: 0 0 190px 0;
        position: relative;
        /* z-index: 200; */

        &.remove-padding {
            padding: 0;
        }

        .container {
            width: 50%;
            margin: 0 auto;
            padding: 0 0 20px 0;

            ${responsive.DESKTOP`
                width: 60%;
            `}

            ${responsive.TABLET`
                width: 80%;
            `}
            
            ${responsive.PHABLET`
                width: 90%;
            `}

            .description {
                color: ${COLORS.TEXT_DARK};
                font-size: 18px;

                .sub-text {
                    font-family: ${FONTS.PRIMARY_BOLD};
                }
            }

            .description-loader {
                width: 50%;
                height: 60px;

                ${responsive.TABLET`
                    width: 60%;
                `}

                ${responsive.PHABLET`
                    width: 90%;
                `}
            }
        }
    }
    .footer-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 200px;
        z-index: 200;
        /* background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%); */
        background-color: ${COLORS.WHITE};

        .shadow {
            height: 20px;
            position: absolute;
            width: 50%;
            top: 5px;
            right: 50%;
            transform: translate(50%, -50%);
            background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);

            ${responsive.DESKTOP`
                width: 60%;
            `}

            ${responsive.TABLET`
                width: 80%;
            `}
            
            ${responsive.PHABLET`
                width: 90%;
            `}
        }
    }
    .redirection-links {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 70px;
        transform: translate(-50%, -0%);
        left: 50%;
        z-index: 210;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.MOBILE`
            width: 90%;
        `}

        .redirection-btn {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            height: 60px;
            width: 370px;
            color: ${COLORS.PRIMARY_WARM};
            font-size: 16px;
            text-transform: uppercase;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};

            ${responsive.PHABLET`
                width: 100%;
            `}
        }

        .footer-text {
            color: ${COLORS.WHITE};
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            margin: 10px 0 0;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }

            &.divider {
                padding: 0 5px;
            }
        }
    }
    .flower-image {
        background-image: url(${Flower});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: fixed;
        bottom: 0;
        right: 0;
        height: 250px;
        width: 450px;
        z-index: 201;

        ${responsive.TABLET`
            height: 220px;
            width: 350px;
        `}
        ${responsive.PHABLET`
            height: 180px;
            width: 300px;
        `}
    }
    .bottom-gradient {
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        height: 35%;
        position: fixed;
        width: 100%;
        z-index: 200;
        bottom: 0;

        ${responsive.LAPTOP`
            height: 40%;
        `}
    }
`;
